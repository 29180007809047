
.CompanyWizard {
  width: 100%;
  display: flex;

  flex-direction: column;
  min-height: 100vh;
  
  .sticky-header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    color: white;
    text-align: center;
    padding: 10px 0;
    z-index: 1000;
    

    .link-area {
      padding: 0;
      .text-link {
        color: #1d1d1d;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Source Sans Pro', sans-serif;
        text-decoration: none;
      }
    }
  }
  .content {
    flex: 1;
    padding: 10px;
    width: 100%;
  }

  .sticky-footer {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    border: #E2E2E2 2px;
    border-style: solid;
    border-bottom: none;
    border-left: none;
    border-right: none;
    background-color: #ffffff;
    text-align: center;
    padding: 10px 0;
    z-index: 1000; 
  }
}
