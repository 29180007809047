.OperationExcellent {
    height: auto;

    .main-intro {
        height: auto;
        padding-top: 8%;
        padding-bottom: 4%;
        background: transparent linear-gradient(to right, #e0edf5, #ffffff, #e4f2f2);

        .text-intro {
            padding-left: 6%;
            padding-top: 5%;

            b {
                font-size: 2.2rem;
            }

            p {
                font-size: 16px;
                font-family: 'Microsoft JhengHei', sans-serif;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .btn-secondary {
                margin-top: 5%;
                width: 120px;
                height: 44px;
            }
        }
    }

    .func-text {
        font-size: 14px;
        font-weight: bold;
        color: #0067b0;
    }

    .resource-asset-configuration {
        padding: 7% 2% 7% 5%;

        .text-resource {
            margin-top: 3%;
            padding-right: 5%;

            .text-title {
                font-size: 32px;
                font-weight: bold;
            }

            .text-desc {
                font-size: 16px;
                font-family: Microsoft JhengHei;
                color: #323232;
            }


            .btn-allservice {
                font-size: 20px;
                font-weight: bold;
                text-decoration: none;
            }
        }
    }

    .resource-dashboard {
        padding: 7% 5% 7% 5%;
        background-color: #f5f9fc;
        .img {
            align-self: center;
        }
        .text-resource {
            padding-right: 5%;

            .text-title {
                font-size: 32px;
                font-weight: bold;
            }

            .text-desc {
                font-size: 16px;
                font-family: Microsoft JhengHei;
                color: #323232;
            }


            .btn-allservice {
                font-size: 20px;
                font-weight: bold;
                text-decoration: none;
            }
        }
    }

    .service-health-check {
        padding: 6% 5% 10% 5%;
        .tab_content {
            margin-top: 6%;
            align-items: center;
        }
        .tab {
            align-items: left !important;
            padding-top: 3%;
            .func-text {
                padding-left: 7%;
                font-size: 14px;
                font-weight: bold;
                color: #0067b0;
            }
            .text-title {
                padding-left: 7%;
                font-size: 32px;
                font-weight: bold;
            }
            .accordion {
                .accordion-item {
                    border-top: 0px !important;
                    border-right: 0px !important;
                    border-left: 0px !important;
                    border-bottom: 100% solid #dddddd !important;
                }
                .nav-item {
                    border: 0px !important;
                    width: 100%;
                }
                .accordion-header {
                    .accordion-button[aria-expanded='true'] {
                        border-bottom: 0px !important;
                        border-radius: 0% !important;
                        border-left: 10px solid #7fbe25 !important;
                    }
                    .accordion-button {
                        font-size: 18px !important;
                        font-weight: bold !important;
                        border-bottom: white !important;
                    }
                }
                .accordion-body {
                    font-size: 16px;
                    font-family: Microsoft JhengHei;
                    border-left: 10px solid #7fbe25 !important;
                }
            }
            .btn-allservice {
                font-size: 20px;
                font-weight: bold;
                text-decoration: none;
            }
        }
    }

    .subscr-plan {
        background: #f5f9fc;
        padding-bottom: 10%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;

        .text-title {
            margin-top: 3%;
            margin-bottom: 3%;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
        }
    }
}

