

@media screen and (min-width:$min-width){

    .addlink-slot{
        width: 150px;
        height: 180px;
        background-color: #FFFFFF;
        border: 2px dashed #E2E2E2;
        border-radius: 2px; 

        &:hover {
            .tile-title{
                color:#1D1D1D;
            }
            ~ .add-icon {
                position: relative;
                width: 40px;
                height: 40px;
                background-image: url('../../public/assets/images/ic_add_h.svg');
            }
        }
        &:active {
            border: 2px dashed #7FBE25 ;
            border-radius: 2px;

            .tile-title{
                color:#7FBE25;
            }

            .add-icon{
                position:relative;
                width: 40px;
                height: 40px;
                background-image: url('../../public/assets/images/ic_add_p.svg');
            }
        }

        .tile-title{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 150px;
            height: 70px;
            color:#7fbe25;
            font-size: 18px;
            font-weight: bold;
            opacity: 1;
        }

        .tile-icon{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 150px;
            height: 110px;
            opacity: 1;

            .add-icon{
                position:relative;
                width: 40px;
                height: 40px;
                background-image: url('../../public/assets/images/ic_add_n.svg');
            }
        }

    }

}

@media screen and (max-width:$max-width){
    .addlink-slot{
        width: 167px;
        height: 70px;
        display: flex;
        justify-content: space-between;
        background-color: #FFFFFF;
        margin-bottom: 16px;
        border: 1px dashed #E2E2E2;
        border-radius: 2px; 

        &:hover {
            .tile-title{
                color:#1D1D1D;
            }
            ~ .add-icon {
                position: relative;
                width: 40px;
                height: 40px;
                background-image: url('../../public/assets/images/ic_add_h.svg');
            }
        }
        &:active {
            border: 1px dashed #7FBE25 ;
            border-radius: 2px;

            .tile-title{
                color:#7FBE25;
            }

            .add-icon{
                position:relative;
                width: 40px;
                height: 40px;
                background-image: url('../../public/assets/images/ic_add_p.svg');
            }
        }

        .tile-icon{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 167px;
            height: 70px;
            opacity: 1;

            .add-icon{
                position:relative;
                width: 20px;
                height: 20px;
                background-image: url('../../public/assets/images/ic_add_n.svg');
            }
        }

        .tile-title{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 150px;
            height: 70px;
            color:#7fbe25;
            font-size: 18px;
            font-weight: bold;
            opacity: 1;
        }

    }
}