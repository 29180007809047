@charset "UTF-8";
/*!
 * Bootstrap  v5.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&family=Source+Sans+3:wght@400;700;800&display=swap");
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #000000;
  --bs-secondary: #323232;
  --bs-success: #7FBE25;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: rgba(255, 255, 255, 0.9490196078);
  --bs-dark: #212529;
  --bs-primary-rgb: 0, 0, 0;
  --bs-secondary-rgb: 50, 50, 50;
  --bs-success-rgb: 127, 190, 37;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 255, 255, 255;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: black;
  --bs-secondary-text-emphasis: #141414;
  --bs-success-text-emphasis: #334c0f;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cccccc;
  --bs-secondary-bg-subtle: #d6d6d6;
  --bs-success-bg-subtle: #e5f2d3;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #999999;
  --bs-secondary-border-subtle: #adadad;
  --bs-success-border-subtle: #cce5a8;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Noto Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #000000;
  --bs-link-color-rgb: 0, 0, 0;
  --bs-link-decoration: underline;
  --bs-link-hover-color: black;
  --bs-link-hover-color-rgb: 0, 0, 0;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(0, 0, 0, 0.25);
  --bs-form-valid-color: #7FBE25;
  --bs-form-valid-border-color: #7FBE25;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #666666;
  --bs-secondary-text-emphasis: #848484;
  --bs-success-text-emphasis: #b2d87c;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: black;
  --bs-secondary-bg-subtle: #0a0a0a;
  --bs-success-bg-subtle: #192607;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: black;
  --bs-secondary-border-subtle: #1e1e1e;
  --bs-success-border-subtle: #4c7216;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #666666;
  --bs-link-hover-color: #858585;
  --bs-link-color-rgb: 102, 102, 102;
  --bs-link-hover-color-rgb: 133, 133, 133;
  --bs-code-color: #e685b5;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 0 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cccccc;
  --bs-table-border-color: #b8b8b8;
  --bs-table-striped-bg: #c2c2c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8b8b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdbdbd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #d6d6d6;
  --bs-table-border-color: #c1c1c1;
  --bs-table-striped-bg: #cbcbcb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c1c1c1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6c6c6;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #e5f2d3;
  --bs-table-border-color: #cedabe;
  --bs-table-striped-bg: #dae6c8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cedabe;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d4e0c3;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: rgba(255, 255, 255, 0.9490196078);
  --bs-table-border-color: rgba(227, 227, 227, 0.9541176471);
  --bs-table-striped-bg: rgba(241, 241, 241, 0.9515686275);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(227, 227, 227, 0.9541176471);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(234, 234, 234, 0.9528431373);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: gray;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::-moz-placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: gray;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: gray;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
}
.form-check-input:checked {
  background-color: #000000;
  border-color: #000000;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #000000;
  border-color: #000000;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='gray'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .CompanyOrgManagement .btn-group .btn-check[disabled] + .btn-add-member, .btn-check:disabled + .btn, .CompanyOrgManagement .btn-group .btn-check:disabled + .btn-add-member {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  -webkit-appearance: none;
          appearance: none;
  background-color: #000000;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b3b3b3;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  -moz-appearance: none;
       appearance: none;
  background-color: #000000;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b3b3b3;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::-moz-placeholder, .form-floating > .form-control-plaintext::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown), .form-floating > .form-control-plaintext:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn, .input-group .CompanyOrgManagement .btn-group .btn-add-member, .CompanyOrgManagement .btn-group .input-group .btn-add-member {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus, .input-group .CompanyOrgManagement .btn-group .btn-add-member:focus, .CompanyOrgManagement .btn-group .input-group .btn-add-member:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.CompanyOrgManagement .btn-group .input-group-lg > .btn-add-member {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.CompanyOrgManagement .btn-group .input-group-sm > .btn-add-member {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%237FBE25' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%237FBE25' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn, .CompanyOrgManagement .btn-group .btn-add-member {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn, .CompanyOrgManagement .btn-group .btn-add-member {
    transition: none;
  }
}
.btn:hover, .CompanyOrgManagement .btn-group .btn-add-member:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover, .CompanyOrgManagement .btn-group .btn-check + .btn-add-member:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible, .CompanyOrgManagement .btn-group .btn-add-member:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn, .CompanyOrgManagement .btn-group .btn-check:focus-visible + .btn-add-member {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, .CompanyOrgManagement .btn-group .btn-check:checked + .btn-add-member, :not(.btn-check) + .btn:active, .CompanyOrgManagement .btn-group :not(.btn-check) + .btn-add-member:active, .btn:first-child:active, .CompanyOrgManagement .btn-group .btn-add-member:first-child:active, .btn.active, .CompanyOrgManagement .btn-group .active.btn-add-member, .btn.show, .CompanyOrgManagement .btn-group .show.btn-add-member {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, .CompanyOrgManagement .btn-group .btn-check:checked + .btn-add-member:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .CompanyOrgManagement .btn-group :not(.btn-check) + .btn-add-member:active:focus-visible, .btn:first-child:active:focus-visible, .CompanyOrgManagement .btn-group .btn-add-member:first-child:active:focus-visible, .btn.active:focus-visible, .CompanyOrgManagement .btn-group .active.btn-add-member:focus-visible, .btn.show:focus-visible, .CompanyOrgManagement .btn-group .show.btn-add-member:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .CompanyOrgManagement .btn-group .btn-add-member:disabled, .btn.disabled, .CompanyOrgManagement .btn-group .disabled.btn-add-member, fieldset:disabled .btn, fieldset:disabled .CompanyOrgManagement .btn-group .btn-add-member, .CompanyOrgManagement .btn-group fieldset:disabled .btn-add-member {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 38, 38, 38;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #000000;
  --bs-btn-disabled-border-color: #000000;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #323232;
  --bs-btn-border-color: #323232;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2b2b2b;
  --bs-btn-hover-border-color: #282828;
  --bs-btn-focus-shadow-rgb: 81, 81, 81;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #282828;
  --bs-btn-active-border-color: #262626;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #323232;
  --bs-btn-disabled-border-color: #323232;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #7FBE25;
  --bs-btn-border-color: #7FBE25;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #92c846;
  --bs-btn-hover-border-color: #8cc53b;
  --bs-btn-focus-shadow-rgb: 108, 162, 31;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #99cb51;
  --bs-btn-active-border-color: #8cc53b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #7FBE25;
  --bs-btn-disabled-border-color: #7FBE25;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: rgba(255, 255, 255, 0.9490196078);
  --bs-btn-border-color: rgba(255, 255, 255, 0.9490196078);
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgba(213, 213, 213, 0.9566666667);
  --bs-btn-hover-border-color: rgba(200, 200, 200, 0.9592156863);
  --bs-btn-focus-shadow-rgb: 213, 213, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgba(200, 200, 200, 0.9592156863);
  --bs-btn-active-border-color: rgba(186, 186, 186, 0.9617647059);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: rgba(255, 255, 255, 0.9490196078);
  --bs-btn-disabled-border-color: rgba(255, 255, 255, 0.9490196078);
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #000000;
  --bs-btn-hover-border-color: #000000;
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #000000;
  --bs-btn-active-border-color: #000000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #000000;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #323232;
  --bs-btn-border-color: #323232;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #323232;
  --bs-btn-hover-border-color: #323232;
  --bs-btn-focus-shadow-rgb: 50, 50, 50;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #323232;
  --bs-btn-active-border-color: #323232;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #323232;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #323232;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #7FBE25;
  --bs-btn-border-color: #7FBE25;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #7FBE25;
  --bs-btn-hover-border-color: #7FBE25;
  --bs-btn-focus-shadow-rgb: 127, 190, 37;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #7FBE25;
  --bs-btn-active-border-color: #7FBE25;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #7FBE25;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #7FBE25;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: rgba(255, 255, 255, 0.9490196078);
  --bs-btn-border-color: rgba(255, 255, 255, 0.9490196078);
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgba(255, 255, 255, 0.9490196078);
  --bs-btn-hover-border-color: rgba(255, 255, 255, 0.9490196078);
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgba(255, 255, 255, 0.9490196078);
  --bs-btn-active-border-color: rgba(255, 255, 255, 0.9490196078);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: rgba(255, 255, 255, 0.9490196078);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: rgba(255, 255, 255, 0.9490196078);
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 38, 38, 38;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn, .CompanyOrgManagement .btn-group .btn-group-lg > .btn-add-member {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn, .CompanyOrgManagement .btn-group .btn-group-sm > .btn-add-member {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #000000;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #000000;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn, .CompanyOrgManagement .btn-group > .btn-add-member,
.btn-group-vertical > .btn,
.CompanyOrgManagement .btn-group .btn-group-vertical > .btn-add-member {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn, .CompanyOrgManagement .btn-group > .btn-check:checked + .btn-add-member,
.btn-group > .btn-check:focus + .btn,
.CompanyOrgManagement .btn-group > .btn-check:focus + .btn-add-member,
.btn-group > .btn:hover,
.CompanyOrgManagement .btn-group > .btn-add-member:hover,
.btn-group > .btn:focus,
.CompanyOrgManagement .btn-group > .btn-add-member:focus,
.btn-group > .btn:active,
.CompanyOrgManagement .btn-group > .btn-add-member:active,
.btn-group > .btn.active,
.CompanyOrgManagement .btn-group > .active.btn-add-member,
.btn-group-vertical > .btn-check:checked + .btn,
.CompanyOrgManagement .btn-group .btn-group-vertical > .btn-check:checked + .btn-add-member,
.btn-group-vertical > .btn-check:focus + .btn,
.CompanyOrgManagement .btn-group .btn-group-vertical > .btn-check:focus + .btn-add-member,
.btn-group-vertical > .btn:hover,
.CompanyOrgManagement .btn-group .btn-group-vertical > .btn-add-member:hover,
.btn-group-vertical > .btn:focus,
.CompanyOrgManagement .btn-group .btn-group-vertical > .btn-add-member:focus,
.btn-group-vertical > .btn:active,
.CompanyOrgManagement .btn-group .btn-group-vertical > .btn-add-member:active,
.btn-group-vertical > .btn.active,
.CompanyOrgManagement .btn-group .btn-group-vertical > .active.btn-add-member {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn, .CompanyOrgManagement .btn-group > :not(.btn-check:first-child) + .btn-add-member,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .CompanyOrgManagement .btn-group > .btn-add-member:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.CompanyOrgManagement .btn-group > .dropdown-toggle-split.btn-add-member:first-child,
.btn-group > .btn-group:not(:last-child) > .btn,
.CompanyOrgManagement .btn-group > .btn-group:not(:last-child) > .btn-add-member {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3), .CompanyOrgManagement .btn-group > .btn-add-member:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.CompanyOrgManagement .btn-group > :not(.btn-check) + .btn-add-member,
.btn-group > .btn-group:not(:first-child) > .btn,
.CompanyOrgManagement .btn-group > .btn-group:not(:first-child) > .btn-add-member {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .CompanyOrgManagement .btn-group .btn-group-sm > .btn-add-member + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .CompanyOrgManagement .btn-group .btn-group-lg > .btn-add-member + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn, .CompanyOrgManagement .btn-group .btn-group-vertical > .btn-add-member,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child), .CompanyOrgManagement .btn-group .btn-group-vertical > .btn-add-member:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .CompanyOrgManagement .btn-group .btn-group-vertical > .btn-add-member:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.CompanyOrgManagement .btn-group-vertical > .btn-group:not(:last-child) > .btn-add-member {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn, .CompanyOrgManagement .btn-group .btn-group-vertical > .btn-add-member ~ .btn, .CompanyOrgManagement .btn-group .btn-group-vertical > .btn ~ .btn-add-member, .CompanyOrgManagement .btn-group .btn-group-vertical > .btn-add-member ~ .btn-add-member,
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.CompanyOrgManagement .btn-group-vertical > .btn-group:not(:first-child) > .btn-add-member {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #000000;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='black'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: gray;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: none;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23666666'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23666666'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #000000;
  --bs-pagination-active-border-color: #000000;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge, .CompanyOrgManagement .btn-group .btn-add-member .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #000000;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #000000;
  --bs-list-group-active-border-color: #000000;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #757474;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23757474'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before, .CompanyOrgManagement .btn-group .placeholder.btn-add-member::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
          mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
          mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
            mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(40, 40, 40, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(40, 40, 40, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(153, 203, 81, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(153, 203, 81, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.object-fit-cover {
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.object-fit-fill {
  -o-object-fit: fill !important;
     object-fit: fill !important;
}

.object-fit-scale {
  -o-object-fit: scale-down !important;
     object-fit: scale-down !important;
}

.object-fit-none {
  -o-object-fit: none !important;
     object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  -moz-column-gap: 0 !important;
       column-gap: 0 !important;
}

.column-gap-1 {
  -moz-column-gap: 0.25rem !important;
       column-gap: 0.25rem !important;
}

.column-gap-2 {
  -moz-column-gap: 0.5rem !important;
       column-gap: 0.5rem !important;
}

.column-gap-3 {
  -moz-column-gap: 1rem !important;
       column-gap: 1rem !important;
}

.column-gap-4 {
  -moz-column-gap: 1.5rem !important;
       column-gap: 1.5rem !important;
}

.column-gap-5 {
  -moz-column-gap: 3rem !important;
       column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
          user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-sm-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-sm-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-sm-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-md-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-md-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-md-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-md-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-md-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-lg-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-lg-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-lg-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-xl-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-xl-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-xl-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
@media screen and (min-width: 900px) {
  .AppHeader .nav-area {
    display: flex;
    align-items: center;
    height: 70px;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
  }
}
.AppLoginHeader {
  background: #323232;
  height: 50px;
  min-height: 10px;
}
.AppLoginHeader .nav-area {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 50px;
}
.AppLoginHeader .setting-area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 20vw;
  height: 50px;
}
.AppLoginHeader .setting-area .icon-setting:hover {
  color: #7FBE25;
  cursor: pointer;
}
.AppLoginHeader .setting-area .icon-setting:active {
  color: #6AA318;
}
.AppLoginHeader .setting-area .icon-acct:hover {
  cursor: pointer;
}

@media screen and (min-width: 900px) {
  .AppFooter {
    background: #323232;
    color: #FFFFFF;
    font-size: 20px;
    height: 546px;
    padding: 86px 163px 227px 96px;
    z-index: 5;
    position: relative;
    display: flex;
    justify-content: center;
    text-align: left;
    align-items: center;
  }
  .AppFooter .footer {
    font-size: 20px;
    font-family: Microsoft JhengHei;
    margin-top: 90.25px;
  }
  .AppFooter .footer p {
    white-space: nowrap;
    margin-bottom: 48px;
  }
  .AppFooter .footer .horizontal-list {
    display: flex;
    width: 1021px;
    height: 36px;
    flex-direction: row;
    list-style-type: none;
    margin-bottom: 87.75px;
    padding-left: 0px;
  }
  .AppFooter .footer .horizontal-list span {
    margin-left: 17px;
    margin-right: 17px;
  }
  .AppFooter .nav-link {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 94.75px;
  }
  .AppFooter.login {
    display: none;
  }
}
@media screen and (max-width: 390px) {
  .AppFooter {
    background: #323232;
    color: #FFFFFF;
    font-size: 20px;
    height: 500px;
    padding: 37px 0 56px 0;
    z-index: 5;
    position: relative;
  }
  .AppFooter .footer {
    font-size: 12px;
    font-family: Microsoft JhengHei;
  }
  .AppFooter .footer p {
    white-space: normal;
    margin-bottom: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center; /* 水平居中 */
  }
  .AppFooter .footer .horizontal-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
    padding-left: 0px;
  }
  .AppFooter .footer .horizontal-list li {
    height: 36px;
    display: flex;
    align-items: center;
  }
  .AppFooter .footer .horizontal-list span {
    display: none;
  }
  .AppFooter .nav-link {
    text-decoration: none;
  }
}
@media screen and (min-width: 900px) {
  .AppMenu {
    width: 100%;
    opacity: 1;
  }
  .AppMenu .desktop-nav {
    display: flex;
    justify-content: space-between;
  }
  .AppMenu .desktop-nav .menus {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    padding: 1px 2px;
  }
  .AppMenu .desktop-nav .menu-items {
    position: relative;
    font-size: 16px;
  }
  .AppMenu .desktop-nav .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }
  .AppMenu .desktop-nav .menu-items button {
    display: flex;
    text-align: left;
    padding: 32px 10px 15px 12px;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }
  .AppMenu .desktop-nav .menu-items button:hover {
    background-color: rgba(225, 225, 225, 0.2);
  }
  .AppMenu .desktop-nav .menu-items button span {
    margin-left: 3px;
  }
  .AppMenu .desktop-nav .menu-items .underline {
    font-weight: bold;
    border: solid #7fbe25 3px;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 35px 10px 15px 12px;
  }
  .AppMenu .desktop-nav .arrow::after {
    content: "";
    display: inline-block;
  }
  .AppMenu .menu-dropdown {
    position: absolute;
    top: 70px;
    left: 0px;
    width: 100%;
    height: auto;
    padding-left: 13%;
    padding-top: 3%;
    padding-bottom: 1%;
    padding-right: 8%;
    background: #f9f9f9 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1607843137);
    z-index: 99;
    opacity: 1;
  }
  .AppMenu .menu-dropdown .menuitem-dropdown .menuitem-text {
    width: 91px;
    height: 19px;
    font-size: 16px;
    text-decoration: none;
    color: #323232;
  }
  .AppMenu .menu-dropdown .menuitem-dropdown .menuitem-text p {
    margin-bottom: 16px;
  }
  .AppMenu .menu-dropdown .vertical-flex {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 900px) and (max-width: 899px) {
  .AppMenu .menu-dropdown .vertical-flex {
    flex-wrap: nowrap;
    padding-top: 7%;
    padding-bottom: 10%;
    height: auto;
  }
}
@media screen and (min-width: 900px) {
  .AppMenu .mobile-nav {
    display: none;
  }
}
@media screen and (max-width: 899px) {
  .AppMenu {
    display: none;
  }
}
.AppLoginMenu {
  width: 100%;
  height: 50px;
  opacity: 1;
}
.AppLoginMenu .desktop-nav {
  display: flex;
  justify-content: space-between;
  background: #323232;
  height: 50px;
}
.AppLoginMenu .desktop-nav .menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 1px 2px;
}
.AppLoginMenu .desktop-nav .menu-items {
  position: relative;
  font-size: 15px;
  color: #FFFFFF;
}
.AppLoginMenu .desktop-nav .menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}
.AppLoginMenu .desktop-nav .menu-items button {
  display: flex;
  text-align: left;
  padding: 14px 8px 12px 8px;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  margin-left: 2%;
}
.AppLoginMenu .desktop-nav .menu-items button:hover {
  padding: 14px 8px 12px 8px;
  background-color: rgba(225, 225, 225, 0.2);
  border-bottom: none;
}
.AppLoginMenu .desktop-nav .menu-items button span {
  margin-left: 3px;
}
.AppLoginMenu .desktop-nav .menu-items .underline {
  font-weight: bold;
  border: solid #7FBE25 3px;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 15px 8px 9px 8px;
}
.AppLoginMenu .desktop-nav .arrow::after {
  content: "";
  display: inline-block;
}
.AppLoginMenu .menu-dropdown {
  position: absolute;
  top: 50px;
  left: 0px;
  width: 100%;
  padding: 0% 4%;
  display: flex;
  align-items: center;
  background: #424242 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1607843137);
  z-index: 100;
  opacity: 1;
}
.AppLoginMenu .menu-dropdown .menuitem-dropdown {
  padding-left: 1.5%;
  padding-top: 1.2%;
  z-index: 100;
}
.AppLoginMenu .menu-dropdown .menuitem-dropdown .menuitem {
  height: 40px;
}
.AppLoginMenu .menu-dropdown .menuitem-dropdown .menuitem .btn-menuitem {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  text-decoration: none;
  color: #7FBE25;
  border: solid #7fbe25 1px;
  border-radius: 0%;
  border-top: none;
  border-left: none;
  border-right: none;
  padding-left: 0px;
  margin-bottom: 16px;
}
.AppLoginMenu .menu-dropdown .menuitem-dropdown .menuitem .btn-submenuitem {
  width: 100%;
  font-size: 13px;
  font-weight: normal;
  text-align: left;
  text-decoration: none;
}
.AppLoginMenu .menu-dropdown .menuitem-dropdown .menuitem .btn-submenuitem:hover {
  color: #7FBE25;
}
.AppLoginMenu .menu-dropdown .menuitem-dropdown .menuitem .btn-submenuitem .selected {
  color: #7FBE25;
}
.AppLoginMenu .menu-dropdown .menuitem-dropdown .menuitem .text-submenuitem {
  width: 100%;
  font-size: 13px;
  font-weight: normal;
  text-align: left;
  text-decoration: none;
  color: #F2F2F2;
}
.AppLoginMenu .menu-dropdown .menuitem-dropdown .menuitem .text-submenuitem:hover {
  color: #7FBE25;
}
.AppLoginMenu .menu-dropdown .menuitem-dropdown .menuitem .btn-menuitem1 {
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  text-decoration: none;
  color: #FFFFFF;
}
.AppLoginMenu .menu-dropdown .menuitem-dropdown .menuitem .btn-menuitem1 .selected {
  color: #7FBE25;
}
.AppLoginMenu .menu-dropdown .menuitem-dropdown .menuitem .text-menuitem1 {
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  text-decoration: none;
  color: #FFFFFF;
}
.AppLoginMenu .menu-dropdown .menuitem-dropdown .menuitem .text-menuitem1:hover {
  color: #7FBE25;
}
.AppLoginMenu .menu-dropdown .menuitem-dropdown .menuitem .text-menuitem1 .selected {
  color: #7FBE25;
}
.AppLoginMenu .menu-dropdown .vertical-flex {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 200px;
}
@media (max-width: 899px) {
  .AppLoginMenu .menu-dropdown .vertical-flex {
    flex-wrap: nowrap;
    padding-top: 7%;
    padding-bottom: 10%;
    height: auto;
  }
}

.AppLoginSidebar .Header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.AppLoginSidebar .Header .title {
  font-size: 20px;
  font-weight: bold;
  font-family: "Noto Sans";
  color: #323232;
}
.AppLoginSidebar .text-header {
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  color: #323232;
  margin-top: 15px;
  margin-bottom: 0px;
}
.AppLoginSidebar .menu-item {
  width: 200px;
  padding: 0px;
  margin-left: 17px;
  white-space: nowrap;
}
.AppLoginSidebar .menu-item .text-btn {
  justify-content: left;
  align-content: left;
  font-size: 14px;
  font-family: "Microsoft JhengHei", sans-serif;
  color: #323232;
}
.AppLoginSidebar .menu-item.selected {
  font-weight: bold;
  width: 200px;
  padding: 0px;
  margin-left: 13px;
  background: rgba(226, 226, 226, 0.1490196078);
  border: solid #7FBE25 4px;
  border-top: none;
  border-bottom: none;
  border-right: none;
}
.AppLoginSidebar .menu-item.selected .menu-link {
  text-decoration: none;
}
.AppLoginSidebar .menu-item.selected .menu-link .icon {
  fill: #7FBE25;
}
.AppLoginSidebar .text-btn {
  font-size: 14px;
  font-family: "Microsoft JhengHei", sans-serif;
  color: #323232;
}
.AppLoginSidebar .text-btn:hover {
  color: #757474;
}
.AppLoginSidebar .text-btn:hover .material-symbols-rounded {
  color: #7FBE25;
}
.AppLoginSidebar .material-symbols-rounded {
  color: #323232;
}
.AppLoginSidebar .material-symbols-rounded.selected {
  color: #6AA318;
}
.AppLoginSidebar .menu-classification {
  width: 300px;
  padding: 0px;
  padding-left: 23px;
  padding-bottom: 5px;
  margin-bottom: 15px;
  font-weight: bold;
  border-bottom: 1px solid #dee2e6;
}
.AppLoginSidebar .menu-classification .menu-link {
  text-decoration: none;
  padding-left: 0px;
}
.AppLoginSidebar .menu-classification .menu-link .text-btn {
  font-size: 14px;
  font-family: "Noto Sans";
  color: #323232;
}

.AzurePerformance .holder {
  width: 100%;
  height: 100%;
  position: relative;
}
.AzurePerformance .holder .grafana {
  width: 97%;
  height: 700px;
  border: none;
  padding: 0;
  margin-top: 0;
  padding-left: 30px;
  transform: scale(1.1);
  background-color: white;
}
.AzurePerformance .holder .bar {
  margin-top: -50px;
  margin-left: -50px;
  position: absolute;
  top: 0;
  width: 105%;
  height: 100px;
  /* Adjust height as needed */
  background-color: white;
}
.AzurePerformance .holder .selectgroup {
  padding-top: 2%;
  width: 30%;
  position: absolute;
  top: 0;
  margin-top: -1.5%;
  margin-left: 70.5%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 5s ease-in-out, visibility 0s 5s;
}
.AzurePerformance .holder .selectgroup .col {
  margin-right: 10px;
}

.SecondLayerDrawer {
  z-index: 2051 !important;
}
.SecondLayerDrawer .text-title {
  font-size: 16px;
  font-weight: bold;
  font-family: "Source Sans 3", sans-serif;
  color: #1d1d1d;
}
.SecondLayerDrawer .offcanvas-body {
  padding: 24px;
}
.SecondLayerDrawer .offcanvas-body .text-description {
  font-size: 13px;
  color: #5f5f5f;
  padding: 20px 0px;
}
.SecondLayerDrawer .button-area {
  display: flex;
  align-items: end;
  justify-content: end;
  padding: 18px;
}
.SecondLayerDrawer .button-area .btn-download {
  font-size: 15px;
  font-weight: bold;
}

.ThirdLayerDrawer {
  z-index: 2053 !important;
}
.ThirdLayerDrawer .text-title {
  font-size: 16px;
  font-weight: bold;
  font-family: "Source Sans 3", sans-serif;
  color: #1d1d1d;
}
.ThirdLayerDrawer .offcanvas-body {
  padding: 24px 24px 15% 24px;
  height: auto;
}
.ThirdLayerDrawer .offcanvas-body .text-description {
  font-size: 13px;
  color: #5f5f5f;
  padding: 20px 0px;
}
.ThirdLayerDrawer .button-area {
  display: flex;
  align-items: end;
  justify-content: end;
  padding: 18px;
}
.ThirdLayerDrawer .button-area .btn-download {
  font-size: 15px;
  font-weight: bold;
}

.Portal .main-portal {
  width: 100vw;
  height: 898px;
  opacity: 1;
}
.Portal .main-portal .main-title {
  font-size: 56px;
  padding-top: 12vw;
  text-align: center;
  margin-bottom: 27px;
  font-family: Noto Sans;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.Portal .main-portal .main-desc {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-left: 15vw;
  padding-right: 15vw;
  letter-spacing: 0px;
  color: #f2f2f2;
  opacity: 0.8;
  font-style: normal;
  font-weight: normal; /* equivalent to font-weight: 400; */
  font-variant: normal;
  font-size: 20px;
  line-height: 32px;
  font-family: Microsoft JhengHei, sans-serif;
}
.Portal .main-portal .btn-layout {
  margin-top: 83px;
  opacity: 1;
}
.Portal .main-portal .btn-layout .bold {
  font-weight: bold;
}
.Portal .main-portal .layout-btn-free {
  display: flex;
  justify-content: right;
  text-align: right;
  opacity: 1;
}
.Portal .main-portal .layout-btn-knowmore {
  display: flex;
  justify-content: left;
  text-align: left;
  opacity: 1;
}
.Portal .main-portal .layout-img-main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 84px;
}
.Portal .main-portal .img-main {
  width: 1080px;
  height: 600px;
}
.Portal .text-subtitle {
  text-align: left;
  font-size: 38px;
  font-weight: bold;
  margin-top: 70px;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}
.Portal .text-subtitle-noto {
  text-align: left;
  margin-top: 70px;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
  font-size: 38px;
  line-height: 52px;
  font-family: "Noto Sans", sans-serif;
}
.Portal .text-subtext {
  text-align: left;
  letter-spacing: 0px;
  color: #5f5f5f;
  line-height: 32px;
  opacity: 1;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  font-family: "Microsoft JhengHei";
}
.Portal .title.padding {
  padding-top: 260px;
  padding-bottom: 62px;
}
.Portal .title.padding2 {
  padding-top: 115px;
  padding-bottom: 62px;
}
.Portal .pros {
  background: #fcfafa 0% 0% no-repeat padding-box;
  border: 1px solid #fcfafa;
  margin-bottom: 87px;
  opacity: 1;
}
.Portal .pros .list {
  height: 320px;
  display: flex;
  justify-content: center; /*水平置中*/
  align-items: center; /*垂直置中*/
}
.Portal .pros .list-pros {
  width: 218px;
  height: 156px;
  margin-bottom: 7.3px;
  display: flex;
  justify-content: center; /*水平置中*/
  align-items: center; /*垂直置中*/
}
.Portal .pros .text-pros {
  width: 218px;
  height: 37px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  font-family: Noto Sans;
  letter-spacing: 0px;
  color: #757474;
  opacity: 1;
}
.Portal .pros .img-pros1 {
  width: 90px;
  height: 81px;
}
.Portal .pros .img-pros2 {
  width: 96px;
  height: 81px;
}
.Portal .pros .img-pros3 {
  width: 93px;
  height: 81px;
}
.Portal .pros .img-pros4 {
  width: 100px;
  height: 81px;
}
.Portal .pros .img-pros5 {
  width: 93px;
  height: 81px;
}
.Portal .adv {
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: center; /*垂直置中*/
}
.Portal .adv .layout-adv {
  height: 620px;
  margin-left: 20px;
  margin-right: 20px;
}
.Portal .adv .layout-adv2 {
  height: 620px;
  margin-left: 20px;
  margin-right: 20px;
}
.Portal .adv .img-adv1 {
  width: 574px;
  height: 431px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.Portal .adv .img-adv2 {
  width: 610px;
  height: 455px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.Portal .adv .img-adv3 {
  width: 610px;
  height: 562px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.Portal .end-portal-container {
  width: 100vw;
  height: 332px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.Portal .end-portal-container .end-portal-title {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-style: normal;
  font-weight: medium; /* or font-weight: 500; */
  font-size: 60px;
  line-height: 82px;
  font-family: "Noto Sans", sans-serif;
  letter-spacing: 0px;
  color: #428fc7;
  opacity: 1;
}
.Portal .end-portal-container .end-portal-subtext {
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0px;
  color: #f8f8f8;
  opacity: 1;
  font-style: normal;
  font-weight: normal; /* or font-weight: 400; */
  font-variant: normal;
  font-size: 16px;
  line-height: 20px;
  font-family: "Microsoft JhengHei", sans-serif;
}
.Portal .qa .btn-qa {
  margin-right: 10px;
  height: 40px;
  background: #e9e9e9 0% 0% no-repeat padding-box;
  border: 4px solid #e9e9e9;
  border-radius: 52px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #5f5f5f;
  opacity: 1;
}
.Portal .qa .btn-qa:hover {
  background: #def1c3 0% 0% no-repeat padding-box;
  border: 4px solid #def1c3;
  border-radius: 52px;
  color: #5f5f5f;
  opacity: 1;
}
.Portal .qa .btn-qa.activate {
  background: #7fbe25 0% 0% no-repeat padding-box;
  border: 4px solid #7fbe25;
  border-radius: 52px;
  font-weight: bold;
  color: #fcfafa;
  opacity: 1;
}
.Portal .question-and-answer .accordion .accordion-item {
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-bottom: 100% solid #dddddd !important;
}
.Portal .question-and-answer .accordion .nav-item {
  border: 0px !important;
  width: 100%;
}
.Portal .question-and-answer .accordion .accordion-header .accordion-button[aria-expanded=true] {
  border-bottom: 0px !important;
  border-radius: 0% !important;
  border-left: 10px solid #7fbe25 !important;
}
.Portal .question-and-answer .accordion .accordion-header .accordion-button {
  font-size: 18px !important;
  font-weight: bold !important;
  border-bottom: white !important;
}
.Portal .question-and-answer .accordion .accordion-body {
  font-size: 16px;
  font-family: Microsoft JhengHei;
  border-left: 10px solid #7fbe25 !important;
}
.Portal .QA {
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  font-family: "Noto Sans", sans-serif;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}
.Portal .btn-backtop {
  width: 72px;
  height: 72px;
  position: fixed;
  bottom: 80px;
  right: 14px;
  display: none;
  border: none;
  background: #f0f7e7 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  font-size: 45px;
  font-weight: bolder;
  color: #7fbe25;
  z-index: 5;
}
.Portal .btn-backtop:hover {
  background: #7fbe25 0% 0% no-repeat padding-box;
  font-size: 50px;
  font-weight: bolder;
  color: #ffffff;
}
.Portal .btn-backtop:active {
  background: #6aa318 0% 0% no-repeat padding-box;
  font-size: 50px;
  font-weight: bolder;
  color: #ffffff;
}
.Portal .btn-backtop:disabled {
  background: #def1c3 0% 0% no-repeat padding-box;
  font-size: 50px;
  font-weight: bolder;
  color: #ffffff;
}
.Portal .btn-logo {
  width: 72px;
  height: 72px;
  position: fixed;
  bottom: 80px;
  left: 46px;
  display: block;
  border: none;
  background: #7fbe25 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  font-size: 11px;
  font-family: Arial;
  white-space: nowrap;
  color: #f3f3f3;
  z-index: 5;
}

/*zoom hover effect for spotlight*/
.background-image-col::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../public/assets/images/spotlight_bg/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 1s ease-in-out;
  z-index: -1;
  overflow: "hidden";
}

.zoom-effect::after {
  transform: scale(1.3);
}

/* responsive 1*/
@media (max-width: 1117px) {
  .firstRow-1 {
    flex-direction: column-reverse !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .firstRow-1 .left-column {
    padding: 0px !important;
    width: 100vw !important;
  }
  .firstRow-1 .left-column .spotlight-info-grid {
    padding-top: 0px !important;
  }
  .firstRow-1 .text-subtitle {
    padding-top: 0px !important;
    margin-top: 0px !important;
    font-size: 25px !important;
  }
  .firstRow-1 .text-subtext {
    margin-top: 0px !important;
    font-size: 14px !important;
  }
  .firstRow-1 .firstRow-1 .background-image-col {
    width: 100vw !important;
  }
  .left-column-1 {
    padding-left: 0px !important;
    width: 100% !important;
  }
  .left-column-1 .spotlight-info-grid {
    padding-top: 0px !important;
  }
}
@media (max-width: 600px) {
  .firstRow-1 {
    flex-direction: column-reverse !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .firstRow-1 .text-subtitle {
    padding-top: 0px !important;
    margin-top: 0px !important;
    font-size: 15px !important;
  }
  .firstRow-1 .text-subtext {
    margin-top: 0px !important;
    font-size: 10px !important;
  }
  .firstRow-1 .firstRow-1 .background-image-col {
    width: 100vw !important;
  }
  .left-column-1 {
    padding-left: 0px !important;
    width: 100% !important;
  }
}
.Login {
  height: auto;
  background: #FAFAFA;
  border: 1px solid #E2E2E2;
  padding: 3% 0% 10% 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Noto Sans", sans-serif;
  opacity: 1;
}
.Login .Card {
  width: 440px;
  padding: 0 2%;
  border-radius: 0%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.Login .Card .text-tab {
  font-size: 18px;
  width: 10vw;
}
.Login .Card .text-tab.active {
  font-weight: bold;
  color: #323232;
}
.Login .Card .btn, .Login .Card .CompanyOrgManagement .btn-group .btn-add-member, .CompanyOrgManagement .btn-group .Login .Card .btn-add-member {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  margin-top: 7vh;
}
.Login .Card .input-field {
  font-size: 14px;
}
.Login .Card .input-field ::-moz-placeholder {
  color: #323232;
  font-weight: bold;
}
.Login .Card .input-field ::placeholder {
  color: #323232;
  font-weight: bold;
}
.Login .Card .checkbox,
.Login .Card .forget-password {
  font-size: 12px;
  color: #707070;
}
.Login .Card .spin {
  width: 100%;
}
.Login .Card .form-login {
  background-color: #FFFFFF;
  z-index: 55;
  margin-top: 36px;
  margin-bottom: 10vh;
  width: 100%;
  height: auto;
}
.Login .Card .form-login .other-login {
  border: 2px solid #5178E7;
  color: #5178E7;
  background: none;
  margin-top: 2vh;
}
.Login .Card .form-register {
  background-color: #FFFFFF;
  z-index: 55;
  margin-top: 36px;
  margin-bottom: 10vh;
  width: 100%;
  height: auto;
}

.ForgetPwd {
  height: auto;
  background: #FAFAFA;
  border: 1px solid #E2E2E2;
  padding: 3% 0% 10% 0%;
  display: flex;
  justify-content: center;
}
.ForgetPwd .btn, .ForgetPwd .CompanyOrgManagement .btn-group .btn-add-member, .CompanyOrgManagement .btn-group .ForgetPwd .btn-add-member {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  margin-top: 5vh;
}
.ForgetPwd .Card {
  width: 440px;
  height: auto;
  padding: 1% 3% 4% 4%;
  display: flex;
  justify-content: center;
  border-radius: 0%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.ForgetPwd .Card .text-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0px;
  background: none;
  color: #323232;
}
.ForgetPwd .Card .text-desc {
  font-size: 14px;
  text-align: left;
  letter-spacing: 0px;
  color: #5F5F5F;
}

.PriceCompare .price-search .img-main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.PriceCompare .price-search .search-main {
  display: flex;
  justify-content: start;
  align-items: start;
  text-align: left;
  padding: 2% 8% 5% 1%;
  color: #323232;
}
.PriceCompare .price-search .search-main .col-2 {
  margin-top: 30px;
}
.PriceCompare .price-search .search-main .col-3 {
  margin-top: 10px;
}
.PriceCompare .text-subtitle {
  margin-bottom: 6px;
  font-size: 15px;
  font-weight: bold;
}

.PriceCompareResult {
  background: #ffffff;
  min-height: 70vh;
  height: auto;
}
.PriceCompareResult .price-search-result {
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled-link {
  pointer-events: none;
  color: #ccc;
}

.tooltip-container {
  background-color: #555555;
  border-radius: 3px;
  border: 1px solid silver;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  display: flex;
  flex-direction: column;
  margin: 0.4rem;
  padding: 0.4rem;
  transition: opacity 0.3s;
  z-index: 2147483647;
  color: #ffffff;
}

.tooltip-arrow {
  height: 1rem;
  position: absolute;
  width: 1rem;
}

.tooltip-arrow::before {
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  margin: auto;
  width: 0;
}

.tooltip-arrow::after {
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  margin: auto;
  position: absolute;
  width: 0;
}

.tooltip-arrow[data-placement*=bottom] {
  height: 1rem;
  left: 0;
  margin-top: -0.4rem;
  top: 0;
  width: 1rem;
}

.tooltip-arrow[data-placement*=bottom]::before {
  border-color: transparent transparent silver transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
  position: absolute;
  top: -1px;
}

.tooltip-arrow[data-placement*=bottom]::after {
  border-color: transparent transparent #555555 transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
}

.tooltip-arrow[data-placement*=top] {
  bottom: 0;
  height: 1rem;
  left: 0;
  margin-bottom: -1rem;
  width: 1rem;
}

.tooltip-arrow[data-placement*=top]::before {
  border-color: silver transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
  position: absolute;
  top: 1px;
}

.tooltip-arrow[data-placement*=top]::after {
  border-color: #555555 transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}

.tooltip-arrow[data-placement*=right] {
  height: 1rem;
  left: 0;
  margin-left: -0.7rem;
  width: 1rem;
}

.tooltip-arrow[data-placement*=right]::before {
  border-color: transparent silver transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
}

.tooltip-arrow[data-placement*=right]::after {
  border-color: transparent #555555 transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
  left: 6px;
  top: 0;
}

.tooltip-arrow[data-placement*=left] {
  height: 1rem;
  margin-right: -0.7rem;
  right: 0;
  width: 1rem;
}

.tooltip-arrow[data-placement*=left]::before {
  border-color: transparent transparent transparent silver;
  border-width: 0.5rem 0 0.5rem 0.4em;
}

.tooltip-arrow[data-placement*=left]::after {
  border-color: transparent transparent transparent #555555;
  border-width: 0.5rem 0 0.5rem 0.4em;
  left: 3px;
  top: 0;
}

.lm-legacy-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #84ba3f;
  border-color: #84ba3f;
}

.lm-legacy-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.lm-legacy-checkbox .ant-checkbox:hover .ant-checkbox-inner,
.lm-legacy-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #84ba3f;
}

.tooltip {
  color: #ff6e00 !important;
  background-color: orange !important;
}

#LatencyTesting {
  min-height: calc(100vh - 87px - 64px - 145px);
}

/*-------
  BlockMap
-------*/
#LatencyTesting #BlockMap .bubbles circle {
  cursor: pointer;
  stroke-width: 3 !important;
}

/*-------
  BlockFloatPing
-------*/
#LatencyTesting #BlockFloatPing {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

#LatencyTesting #BlockFloatPing:before {
  content: " ";
  display: block;
  border-bottom: 10px solid #555;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  margin-top: -10px;
  position: absolute;
  top: -10px;
  bottom: auto;
}

/*-------
 ******RWD*****
  For Desktop
 **************
-------*/
@media (min-width: 870px) {
  #LatencyTesting #BlockPingTable {
    display: none;
  }
}
/*-------
 ******RWD*****
  For Mobile
 **************
-------*/
@media (max-width: 870px) {
  #LatencyTesting #AreaDesktopMap {
    display: none;
  }
  #LatencyTesting #BlockPingTable table {
    min-width: 870px;
  }
}
.ResourceAssetConfiguration .filter {
  padding-bottom: 3%;
}
.ResourceAssetConfiguration .filter .input-group {
  display: flex;
  align-items: center;
  width: 90%;
}
.ResourceAssetConfiguration .filter .input-group .input-filter {
  border: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  margin-left: 2% !important;
}
.ResourceAssetConfiguration .filter .input-group .input-filter .form-control {
  border: 0px;
}
.ResourceAssetConfiguration .filter .input-group .input-filter .button {
  padding-right: 3px;
}
.ResourceAssetConfiguration .filter .side_btn {
  display: flex;
  justify-content: end;
}
.ResourceAssetConfiguration .filter .side_btn .btn-group {
  width: 30vh;
  align-items: center;
}
.ResourceAssetConfiguration .filter .side_btn .btn-group .btn, .ResourceAssetConfiguration .filter .side_btn .CompanyOrgManagement .btn-group .btn-add-member, .CompanyOrgManagement .ResourceAssetConfiguration .filter .side_btn .btn-group .btn-add-member {
  display: flex;
  align-items: center;
  height: 60%;
  margin-left: 3%;
  border: 1px solid #BDBDBD;
  color: #323232;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  padding: 0%;
}
.ResourceAssetConfiguration .filter .side_btn .btn-group .btn:hover, .ResourceAssetConfiguration .filter .side_btn .CompanyOrgManagement .btn-group .btn-add-member:hover, .CompanyOrgManagement .ResourceAssetConfiguration .filter .side_btn .btn-group .btn-add-member:hover {
  color: black;
  background-color: rgba(222, 226, 230, 0);
}

.AllService {
  font-family: Noto Sans;
  min-height: 2000px;
  margin-bottom: 10%;
  height: auto;
}
.AllService .banner {
  width: 100%;
  height: 260px;
  opacity: 90%;
  border: 1px solid #f0f0f0;
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0px;
  display: flex;
  align-items: center; /*垂直置中*/
  padding: 10% 2% 9% 7%;
  opacity: 1;
}
.AllService .sidebar {
  width: 198px;
  height: 488px;
  margin-top: 30px;
  margin-left: 30px;
  padding-top: 24px;
  background: #f0f0f0 0% 0% no-repeat padding-box;
  z-index: 0;
  opacity: 1;
}
.AllService .sidebar .title-sidebar {
  display: none;
}
.AllService .sidebar .text-sidebar:hover {
  color: #7fbe25;
}
.AllService .text-sidebar {
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}
.AllService .text-sidebar:hover {
  color: #7fbe25;
}
.AllService .layout-sidebar {
  width: 198px;
  height: 50px;
  padding-left: 27px;
  padding-top: 13px;
  text-align: left;
  opacity: 1;
}
.AllService .text-title {
  height: 94px;
  display: flex;
  align-items: center; /*垂直置中*/
  text-align: left;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #1d1d1d;
  opacity: 1;
}
.AllService .desc-layout {
  height: auto;
  opacity: 1;
}
.AllService .text-subtitle {
  text-decoration: underline;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #7fbe25;
  opacity: 1;
}
.AllService .col-item {
  height: 136px;
  padding-top: 39px;
}
.AllService .text-desc {
  font-size: 14px;
  letter-spacing: 0px;
  margin-top: 12px;
  color: #5f5f5f;
  opacity: 1;
}

.AllService.login {
  font-family: Noto Sans;
  height: 2200px;
}
.AllService.login .banner {
  display: none;
}
.AllService.login .sidebar {
  height: 100%;
  margin-top: 8px;
  margin-left: 0px;
  border: 2px solid #d2d2d2;
  border-bottom: none;
  border-left: none;
  border-top: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding-top: 0px;
  z-index: 0;
  opacity: 1;
}
.AllService.login .sidebar .title-sidebar {
  display: flex;
  width: 100%;
  height: 66px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}
.AllService.login .sidebar .text-sidebar {
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}
.AllService.login .sidebar .layout-sidebar {
  width: 224px;
  height: 40px;
  padding-left: 27px;
  padding-top: 13px;
  text-align: left;
  opacity: 1;
}
.AllService.login .text-title {
  height: 94px;
  display: flex;
  align-items: center; /*垂直置中*/
  text-align: left;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #1d1d1d;
  opacity: 1;
}
.AllService.login .desc-layout {
  height: auto;
  opacity: 1;
}
.AllService.login .text-subtitle {
  text-decoration: underline;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #7fbe25;
  opacity: 1;
}
.AllService.login .col-item {
  height: 136px;
  padding-top: 39px;
}
.AllService.login .text-desc {
  font-size: 14px;
  letter-spacing: 0px;
  margin-top: 12px;
  color: #5f5f5f;
  opacity: 1;
}

.AutoProcess {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.AutoProcess .content {
  flex: 1;
  padding: 1% 1% 3% 1%;
  width: 100%;
}
.AutoProcess .content .link-area {
  padding: 0 0 20px 0;
}
.AutoProcess .content .link-area .text-link {
  color: #1d1d1d;
  font-size: 20px;
  font-weight: bold;
  font-family: "Source Sans Pro", sans-serif;
  text-decoration: none;
}
.AutoProcess .sticky-footer {
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: end;
  border: #E2E2E2;
  border-style: solid;
  border-bottom: none;
  border-left: none;
  border-right: none;
  background-color: #ffffff;
  text-align: center;
  padding: 1.5%;
  z-index: 1000;
}

@media screen and (min-width: 900px) {
  .console {
    padding: 1% 4%;
    height: auto;
    background-color: #FDFDFD;
  }
  .console .layout-title {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #323232;
    margin-bottom: 12px;
    opacity: 1;
  }
  .console .text-desc {
    font-size: 16px;
    font-family: Noto Sans;
  }
  .console .row {
    margin-bottom: 30px;
  }
  .console .list-group-item {
    display: flex;
    align-items: center;
    border-top: none;
    border-left: none;
    border-right: none;
    height: 60px;
  }
  .console .list-group-item:active .text-desc {
    color: #7FBE25;
  }
  .console .list-group-item .link_icon {
    width: 50px;
    height: 50px;
  }
  .console .link-service {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    text-decoration: none;
    color: #2875E8;
    opacity: 1;
  }
}
@media screen and (max-width: 899px) {
  .console {
    height: 950px;
    background-color: #FDFDFD;
  }
  .console .layout-title {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #323232;
    margin-bottom: 12px;
    opacity: 1;
  }
  .console .text-desc {
    font-size: 16px;
    font-family: Noto Sans;
  }
  .console .row {
    margin-bottom: 30px;
  }
  .console .list-group-item {
    display: flex;
    align-items: center;
    border-top: none;
    border-left: none;
    border-right: none;
    height: 60px;
  }
  .console .list-group-item:active .text-desc {
    color: #7FBE25;
  }
  .console .list-group-item .link_icon {
    width: 24px;
    height: 21px;
  }
  .console .link-service {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    text-decoration: none;
    color: #2875E8;
    opacity: 1;
  }
}
.EmployeeSchedule .buttons .btn, .EmployeeSchedule .buttons .CompanyOrgManagement .btn-group .btn-add-member, .CompanyOrgManagement .btn-group .EmployeeSchedule .buttons .btn-add-member {
  background-color: #FFFFFF !important;
  border: 1px solid #BDBDBD;
  font-size: 12px;
  height: 30px;
  border-radius: 0%;
}
.EmployeeSchedule .TimeLine .react-calendar-timeline .rct-header-root {
  background: #7fbe25 !important;
  border: 2px #E2E2E2 solid;
  border-bottom: 0px;
}
.EmployeeSchedule .TimeLine .react-calendar-timeline .rct-outer {
  border: 2px #E2E2E2 solid;
  border-top: 0px;
}

.DailyCost .input .pick_date .react-datepicker-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
}
.DailyCost .input .pick_date .react-datepicker__input-container {
  position: relative;
}
.DailyCost .input .pick_date .react-datepicker__input-container input {
  width: 100%;
  /* Adjust the width to accommodate the button */
  height: 37px;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  border-radius: 0.375rem;
  border: 1.5px solid #dee2e6;
  font-size: 1rem;
  /* Adjust font size if needed */
  line-height: 1.5;
  /* Adjust line height if needed */
  color: #495057;
  /* Adjust text color if needed */
  background-color: #fff;
  /* Adjust background color if needed */
  background-clip: padding-box;
  /* Bootstrap default transition */
}
.DailyCost .input .form-select {
  width: 100%;
  /* Adjust the width to accommodate the button */
  height: 37px;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  border-radius: 0.375rem;
  border: 1.5px solid #dee2e6;
  font-size: 1rem;
  /* Adjust font size if needed */
  line-height: 1.5;
  /* Adjust line height if needed */
  color: #495057;
  /* Adjust text color if needed */
  background-color: #fff;
  /* Adjust background color if needed */
  background-clip: padding-box;
}

.Classification .selectList .row > * {
  padding-right: 0% !important;
}
.Classification .selectList .row > * .list-group {
  margin-bottom: 4%;
}
.Classification .selectList .row > * .list-group .list-group-item {
  padding: 7% 3% 7% 3%;
  font-size: 14px;
}
.Classification .selectList .row > * .list-group .list-group-item.active {
  background-color: inherit;
  color: inherit;
  border: inherit;
}

@media screen and (min-width: 900px) {
  .intro-temp {
    width: 100%;
    padding-top: 128px;
    padding-bottom: 240px;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .intro-temp .intro-layout {
    height: 221px;
    padding-top: 90px;
    padding-left: 96px;
  }
  .intro-temp .intro-layout .intro-subject {
    text-align: left;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0px;
    margin-bottom: 18px;
    color: #323232;
    opacity: 1;
  }
  .intro-temp .intro-layout .intro-content {
    text-align: left;
    font-size: 14px;
    font-family: Microsoft JhengHei;
    letter-spacing: 0px;
    color: #323232;
    opacity: 1;
  }
  .intro-temp .intro-img-layout {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 899px) {
  .intro-temp {
    margin-bottom: 240px;
  }
  .intro-temp .intro-layout {
    padding: 0px 40px;
  }
  .intro-temp .intro-layout .intro-subject {
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    letter-spacing: 0px;
    color: #323232;
    opacity: 1;
  }
  .intro-temp .intro-layout .intro-content {
    text-align: left;
    font-size: 14px;
    font-family: Microsoft JhengHei;
    letter-spacing: 0px;
    color: #323232;
    opacity: 1;
  }
  .intro-temp .intro-img-layout {
    padding: 50px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .intro-temp .intro-img-layout .img-intro {
    width: 100%;
    height: 100%;
  }
}
.Flowza {
  height: auto;
}
.Flowza .banner-sec {
  width: 100vw;
  height: 260px;
  opacity: 90%;
  display: flex;
  align-items: center; /*垂直置中*/
  opacity: 1;
}
.Flowza .banner-sec .banner-row-sec {
  padding-left: 100px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
}
.Flowza .banner-sec .banner-row-sec .banner-subtitle-sec {
  display: flex;
  color: #95e8ff;
}
.Flowza .banner-sec .banner-row-sec .banner-title-sec {
  display: flex;
  font-size: 30px;
  text-align: left;
  font-weight: bold;
  padding-bottom: 108px !important;
  letter-spacing: 0px;
  white-space: nowrap;
}
.Flowza .main-intro {
  height: auto;
  padding-top: 8%;
  padding-bottom: 4%;
}
.Flowza .main-intro .text-intro {
  padding-left: 6%;
  padding-top: 1%;
}
.Flowza .main-intro .text-intro b {
  font-size: 2.2rem;
}
.Flowza .main-intro .text-intro p {
  font-size: 15px;
  font-family: "Microsoft JhengHei", sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Flowza .main-intro .text-intro li {
  list-style-type: none;
}
.Flowza .main-intro .text-intro li p {
  font-size: 15px;
  font-family: Microsoft JhengHei;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Flowza .main-intro .text-intro .btn-secondary {
  width: 120px;
  height: 44px;
}
.Flowza .sys-intro {
  margin-bottom: 2%;
  padding: 0% 2%;
}
.Flowza .sys-intro .text-title {
  margin-top: 10vh;
  margin-bottom: 6vh;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.Flowza .sys-intro .text-tab {
  font-size: 18px;
  font-family: Microsoft JhengHei;
  letter-spacing: 0px;
  color: #323232;
}
.Flowza .sys-intro .text-tab:hover {
  font-weight: bold;
}
.Flowza .sys-intro .active {
  font-weight: bold;
}
.Flowza .sys-intro .tab {
  padding: 3% 5%;
}
.Flowza .sys-intro .tab ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Flowza .sys-intro .tab ul li {
  list-style-type: none;
  margin-top: 16px;
}
.Flowza .sys-intro .tab ul li b {
  font-size: 15px;
  font-family: Microsoft JhengHei;
}
.Flowza .subscr-plan {
  background: #f5f9fc;
  padding-bottom: 10%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.Flowza .subscr-plan .text-title {
  margin-top: 3%;
  margin-bottom: 3%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.Cloudgoda {
  height: auto;
}
.Cloudgoda .main-intro {
  height: 260px;
}
.Cloudgoda .main-intro .text-intro {
  padding-left: 5%;
  padding-top: 10%;
}
.Cloudgoda .main-intro .text-intro b {
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
  font-family: "Noto Sans", sans-serif;
}
.Cloudgoda .main-intro .text-intro p {
  font-size: 15px;
  font-family: "Microsoft JhengHei", sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Cloudgoda .main-intro .text-intro .btn-secondary {
  width: 120px;
  height: 44px;
}
.Cloudgoda .cloudgoda-service {
  margin-bottom: 2%;
  padding-top: 4%;
}
.Cloudgoda .cloudgoda-service .text-service {
  padding-left: 7%;
  padding-top: 3%;
}
.Cloudgoda .cloudgoda-service .text-service .text-title {
  margin-top: 8vh;
  margin-bottom: 3vh;
}
.Cloudgoda .cloudgoda-service .text-service .text-desc {
  font-size: 19px;
  font-family: Microsoft JhengHei;
  color: #5f5f5f;
}
.Cloudgoda .cloudgoda-service .text-service li {
  list-style-type: none;
}
.Cloudgoda .cloudgoda-service .text-service li p {
  font-size: 16px;
  font-family: Microsoft JhengHei;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Cloudgoda .cloudgoda-service .text-service .btn-allservice {
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}
.Cloudgoda .cloudgoda-cloud {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 6% 4%;
}
.Cloudgoda .cloudgoda-cloud .text-title {
  margin-top: 5vh;
  margin-bottom: 5vh;
}
.Cloudgoda .cloudgoda-benefit {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 8vh;
}
.Cloudgoda .cloudgoda-benefit .col-swiper {
  background: url("../../public/assets/images/img_sky.png") no-repeat left center #012446;
  background-position: "left center";
  background-size: auto 100%;
  padding: 5% 7%;
}
.Cloudgoda .cloudgoda-benefit .col-swiper .swiper {
  height: 350px;
}
.Cloudgoda .cloudgoda-benefit .col-swiper .swiper::part(bullet) {
  width: 20px;
  height: 5px;
  border-radius: 0%;
  background-color: #e9e9e9;
}
.Cloudgoda .cloudgoda-benefit .col-swiper .swiper::part(bullet-active) {
  width: 20px;
  height: 5px;
  border-radius: 0%;
  background-color: #7fbe25;
  padding-bottom: 0%;
}
.Cloudgoda .cloudgoda-benefit .text-title {
  margin-top: 5vh;
  margin-bottom: 5vh;
}
.Cloudgoda .subscr-plan {
  background: #f5f9fc;
  padding-bottom: 10%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.Cloudgoda .subscr-plan .text-title {
  margin-top: 3%;
  margin-bottom: 3%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.Cloudgoda .login {
  letter-spacing: 0px;
  color: #323232;
  background: none;
  padding: 4% 3% 6% 5%;
}
.Cloudgoda .login .text-title {
  font-size: 22px;
  font-weight: bold;
  font-family: "Noto Sans";
}
.Cloudgoda .login .text-desc {
  font-size: 14px;
  font-family: "Microsoft JhengHei";
}
.Cloudgoda .login .text-desc ul {
  margin: 30px 0px;
}

.banner {
  width: 100vw;
  height: 260px;
  opacity: 90%;
  display: flex;
  align-items: center; /*垂直置中*/
  opacity: 1;
}
.banner .banner-subtitle {
  display: flex;
  color: #95e8ff;
  padding: 10% 2% 0% 7%;
}
.banner .banner-title {
  display: flex;
  font-size: 30px !important;
  text-align: left;
  font-weight: bold;
  padding-left: 92px !important;
  padding-top: 108px !important;
  padding-bottom: 108px !important;
  letter-spacing: 0px;
  white-space: nowrap;
}

.banner-1 {
  width: 100vw;
  height: 360px;
  opacity: 90%;
  display: flex;
  flex-direction: column;
  align-items: left; /*垂直置中*/
  opacity: 1;
}
.banner-1 .banner-content-container {
  padding-left: 92px !important;
  padding-top: 105px !important;
}
.banner-1 .banner-content-container .banner-path-1 {
  margin-bottom: 24px;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  font-family: Microsoft JhengHei;
  letter-spacing: 0px;
  color: #f2f2f2;
  opacity: 1;
}
.banner-1 .banner-content-container .banner-path-1 .breadcrumb-separator {
  margin: 0 4px; /*adjust the 8px to increase or decrease the spacing */
}
.banner-1 .banner-content-container .banner-title-1 {
  display: flex;
  font-size: 30px !important;
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  white-space: nowrap;
}
.banner-1 .banner-content-container .banner-description-1 {
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  font-family: Microsoft JhengHei;
  letter-spacing: 0px;
  color: #f2f2f2;
  opacity: 1;
}

.Smartmanagement {
  height: auto;
}
.Smartmanagement .main-intro {
  height: auto;
  padding-top: 8%;
  padding-bottom: 4%;
  background: transparent linear-gradient(to right, #e0edf5, #ffffff, #e4f2f2);
}
.Smartmanagement .main-intro .text-intro {
  padding-left: 6%;
  padding-top: 1%;
}
.Smartmanagement .main-intro .text-intro b {
  font-size: 2.2rem;
}
.Smartmanagement .main-intro .text-intro p {
  font-size: 15px;
  font-family: "Microsoft JhengHei", sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Smartmanagement .main-intro .text-intro .btn-secondary {
  width: 120px;
  height: 44px;
}
.Smartmanagement .func-text {
  font-size: 13px;
  font-weight: bold;
  color: #003999;
}
.Smartmanagement .cost-analysis {
  padding: 4% 2% 6% 2%;
}
.Smartmanagement .cost-analysis .text-cost {
  margin-top: 5%;
}
.Smartmanagement .cost-analysis .text-cost .text-title {
  font-size: 24px;
  font-weight: bold;
}
.Smartmanagement .cost-analysis .text-cost .text-desc {
  font-size: 16px;
  font-family: Microsoft JhengHei;
  color: #5f5f5f;
}
.Smartmanagement .cost-analysis .text-cost li {
  list-style-type: none;
}
.Smartmanagement .cost-analysis .text-cost li p {
  font-size: 16px;
  font-family: Microsoft JhengHei;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Smartmanagement .smart-recommendation {
  padding: 4% 5% 8% 5%;
}
.Smartmanagement .smart-recommendation .text-cost {
  margin-top: 2%;
}
.Smartmanagement .smart-recommendation .text-cost .text-title {
  font-size: 24px;
  font-weight: bold;
}
.Smartmanagement .smart-recommendation .text-cost .text-desc {
  margin-top: 15px;
  font-size: 16px;
  font-family: Microsoft JhengHei;
  color: #5f5f5f;
  padding-right: 6%;
}
.Smartmanagement .subscr-plan {
  background: #f5f9fc;
  padding-bottom: 10%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.Smartmanagement .subscr-plan .text-title {
  margin-top: 3%;
  margin-bottom: 3%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.Cloudbackup {
  height: auto;
}
.Cloudbackup .main-intro {
  height: auto;
  padding-top: 8%;
  padding-bottom: 4%;
  background: transparent linear-gradient(to right, #e0edf5, #ffffff, #e4f2f2);
}
.Cloudbackup .main-intro .text-intro {
  padding-left: 6%;
  padding-top: 1%;
}
.Cloudbackup .main-intro .text-intro b {
  font-size: 2.2rem;
}
.Cloudbackup .main-intro .text-intro p {
  font-size: 15px;
  font-family: "Microsoft JhengHei", sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Cloudbackup .main-intro .text-intro .btn-secondary {
  width: 120px;
  height: 44px;
}
.Cloudbackup .backup-service {
  padding: 8% 5%;
}
.Cloudbackup .backup-service .text-service {
  padding-left: 7%;
}
.Cloudbackup .backup-service .text-service .text-desc {
  font-size: 18px;
  font-family: Microsoft JhengHei;
  color: #5f5f5f;
}
.Cloudbackup .backup-service .text-service li {
  list-style-type: none;
}
.Cloudbackup .backup-service .text-service li p {
  font-size: 15px;
  font-family: Microsoft JhengHei;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Cloudbackup .backup-service .text-service .btn-allservice {
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}
.Cloudbackup .security-posture {
  padding: 2% 6% 3% 6%;
  margin: 3% 0%;
  text-align: center;
  align-items: center;
}
.Cloudbackup .security-posture .img-security-posture {
  padding: 5% 0% 4% 0%;
}
.Cloudbackup .security-posture .text-security-posture {
  padding-left: 4%;
}
.Cloudbackup .security-posture .text-security-posture .text-title {
  margin-left: 5%;
  font-size: 17px;
  font-weight: bold;
  text-align: left;
}
.Cloudbackup .security-posture .text-security-posture .text-desc {
  margin-left: 5%;
  font-size: 17px;
  text-align: left;
  font-family: "Microsoft JhengHei", sans-serif;
  color: #323232;
}
.Cloudbackup .security-posture .text-security-posture li {
  list-style-type: none;
  margin-left: 5%;
}
.Cloudbackup .security-posture .text-security-posture li p {
  font-weight: bold;
  text-align: left;
  font-size: 16px;
  font-family: "Microsoft JhengHei", sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Cloudbackup .backup-support {
  padding: 5% 5%;
}
.Cloudbackup .backup-support .text-support {
  text-align: center;
}
.Cloudbackup .backup-support .text-support b {
  display: inline-block;
  text-align: center;
}
.Cloudbackup .backup-support .card-title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 12px;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: #213308;
  opacity: 1;
}
.Cloudbackup .backup-support .card-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  font-family: "Microsoft JhengHei", sans-serif;
  letter-spacing: 0px;
  color: #5f5f5f;
  opacity: 1;
}
.Cloudbackup .backup-support .text-service {
  padding-left: 7%;
}
.Cloudbackup .backup-support .text-service .text-title {
  margin-bottom: 10px;
}
.Cloudbackup .backup-support .text-service .text-desc {
  font-size: 18px;
  font-family: Microsoft JhengHei;
  color: #323232;
}
.Cloudbackup .backup-support .text-service li {
  list-style-type: none;
}
.Cloudbackup .backup-support .text-service li p {
  font-size: 15px;
  font-family: Microsoft JhengHei;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Cloudbackup .backup-support .text-service .btn-allservice {
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}
.Cloudbackup .subscr-plan {
  background: #f5f9fc;
  padding-bottom: 10%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.Cloudbackup .subscr-plan .text-title {
  margin-top: 3%;
  margin-bottom: 3%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.errorPage {
  background: transparent linear-gradient(to right, #E0EDF5, #FFFFFF, #E4F2F2);
  padding: 10% 5% 15% 5%;
  font-family: "Noto Sans";
  color: "#323232";
}

.CloudSecurity {
  height: auto;
}
.CloudSecurity .banner-sec {
  width: 100vw;
  height: 260px;
  opacity: 90%;
  display: flex;
  align-items: center; /*垂直置中*/
  opacity: 1;
}
.CloudSecurity .banner-sec .banner-row-sec {
  padding-left: 100px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
}
.CloudSecurity .banner-sec .banner-row-sec .banner-subtitle-sec {
  display: flex;
  color: #95e8ff;
}
.CloudSecurity .banner-sec .banner-row-sec .banner-title-sec {
  display: flex;
  font-size: 30px;
  text-align: left;
  font-weight: bold;
  padding-bottom: 108px !important;
  letter-spacing: 0px;
  white-space: nowrap;
}
.CloudSecurity .main-intro {
  height: auto;
  padding-top: 8%;
  padding-bottom: 3%;
  background: transparent linear-gradient(to right, #e0edf5, #ffffff, #e4f2f2);
  font-family: "Microsoft JhengHei", sans-serif;
}
.CloudSecurity .main-intro .text-intro {
  padding-left: 6%;
  padding-top: 1%;
}
.CloudSecurity .main-intro .text-intro b {
  font-size: 2.2rem;
}
.CloudSecurity .main-intro .text-intro p {
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.CloudSecurity .main-intro .text-intro .btn-secondary {
  width: 120px;
  height: 44px;
}
.CloudSecurity .security-posture {
  padding: 2% 6% 3% 6%;
  margin: 3% 0%;
  text-align: center;
  align-items: center;
}
.CloudSecurity .security-posture .img-security-posture {
  padding: 5% 0% 4% 0%;
}
.CloudSecurity .security-posture .text-security-posture {
  padding-left: 4%;
}
.CloudSecurity .security-posture .text-security-posture .text-title {
  margin-left: 5%;
  font-size: 17px;
  font-weight: bold;
  text-align: left;
}
.CloudSecurity .security-posture .text-security-posture .text-desc {
  margin-left: 5%;
  font-size: 16px;
  text-align: left;
  font-family: "Microsoft JhengHei", sans-serif;
  color: #323232;
}
.CloudSecurity .security-posture .text-security-posture li {
  list-style-type: none;
  margin-left: 5%;
}
.CloudSecurity .security-posture .text-security-posture li p {
  font-weight: bold;
  text-align: left;
  font-size: 16px;
  font-family: "Microsoft JhengHei", sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
}
.CloudSecurity .cspm_main_function {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
  margin-bottom: 4vh;
}
.CloudSecurity .cspm_main_function .text-title {
  text-align: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
}
.CloudSecurity .cspm_main_function .col-swiper {
  padding: 1% 7%;
}
.CloudSecurity .cspm_main_function .col-swiper .swiper-container {
  padding-bottom: 3%;
}
.CloudSecurity .cspm_main_function .col-swiper .swiper-container ::part(pagination) {
  display: None !important;
}
.CloudSecurity .cspm_main_function .nav-pag {
  display: flex;
  justify-content: center;
}
.CloudSecurity .cspm_main_function .nav-pag .btn-primary {
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0) !important;
}
.CloudSecurity .cspm_main_function .nav-pag .btn-primary:hover {
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0) !important;
}
.CloudSecurity .cspm_main_function .nav-pag .button_next {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.CloudSecurity .cspm_main_function .nav-pag .button_next .button-next {
  background-image: url("../../public/assets/images/btn_next_g_carousels_n.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 30px;
  height: 40px;
}
.CloudSecurity .cspm_main_function .nav-pag .button_next .button-next:disabled {
  background-image: url("../../public/assets/images/btn_next_w_carousels_n.svg");
}
.CloudSecurity .cspm_main_function .nav-pag .button_prev {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.CloudSecurity .cspm_main_function .nav-pag .button_prev .button-prev {
  background-image: url("../../public/assets/images/btn_back_g_carousels_n.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 30px;
  height: 40px;
}
.CloudSecurity .cspm_main_function .nav-pag .button_prev .button-prev:disabled {
  background-image: url("../../public/assets/images/btn_back_w_carousels_n.svg");
}
.CloudSecurity .cspm_main_function .nav-pag .pags {
  padding-top: 1%;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.CloudSecurity .cspm_main_function .nav-pag .pags .pagination {
  width: -moz-fit-content !important;
  width: fit-content !important;
  height: 5px !important;
}
.CloudSecurity .cspm_main_function .nav-pag .pags .pagination .page-item {
  width: 20px !important;
  height: 5px !important;
}
.CloudSecurity .cspm_main_function .nav-pag .pags .pagination .page-link {
  width: 20px !important;
  height: 5px !important;
  border-radius: 0% !important;
  border: None !important;
  padding-bottom: 0% !important;
  background-color: #e9e9e9 !important;
}
.CloudSecurity .cspm_main_function .nav-pag .pags .pagination .page-link.active,
.CloudSecurity .cspm_main_function .nav-pag .pags .pagination .active > .page-link {
  background-color: #7fbe25 !important;
  /* Your styles for the active page-link inside an element with the class 'active' */
}
.CloudSecurity .regulatory-compliance {
  text-align: center;
  padding: 2% 6% 3% 6%;
  align-items: center;
}
.CloudSecurity .regulatory-compliance .text-regulatory-compliance .text-desc {
  margin-right: 0%;
  width: 486px;
  font-size: 16px;
  text-align: left;
  font-family: Microsoft JhengHei;
  color: #323232;
}
.CloudSecurity .regulatory-compliance .text-regulatory-compliance li {
  list-style-type: none;
  margin-right: 0%;
}
.CloudSecurity .regulatory-compliance .text-regulatory-compliance li p {
  text-align: left;
  font-size: 14px;
  font-family: "Microsoft JhengHei", sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
}
.CloudSecurity .regulatory-compliance .text-regulatory-compliance .btn-allservice {
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}
.CloudSecurity .security-recommendation {
  padding: 2% 6% 5% 6%;
  background-color: #f5f9fc;
  text-align: center;
  align-items: center;
}
.CloudSecurity .security-recommendation .text-security-recommendation .text-title {
  margin-left: 5%;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}
.CloudSecurity .security-recommendation .text-security-recommendation .text-desc {
  margin-left: 5%;
  font-size: 16px;
  text-align: left;
  font-family: Microsoft JhengHei;
  color: #323232;
}
.CloudSecurity .cloud-soc-rule {
  padding: 2% 6% 3% 6%;
  text-align: center;
  align-items: center;
  margin: 2% 0%;
}
.CloudSecurity .cloud-soc-rule .text-cloud-soc-rule .text-desc {
  margin-left: 5%;
  font-size: 16px;
  text-align: left;
  font-family: Microsoft JhengHei;
  color: #323232;
}
.CloudSecurity .cloud-soc-rule .text-cloud-soc-rule .btn-allservice {
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}
.CloudSecurity .subscr-plan {
  background: #f5f9fc;
  padding-bottom: 10%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.CloudSecurity .subscr-plan .text-title {
  margin-top: 3%;
  margin-bottom: 3%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.GodaMall {
  height: auto;
}
.GodaMall .main-intro {
  height: auto;
  padding-top: 8%;
  padding-bottom: 4%;
  background: transparent linear-gradient(to right, #e0edf5, #ffffff, #e4f2f2);
}
.GodaMall .main-intro .text-intro {
  padding-left: 7%;
  align-self: center;
}
.GodaMall .main-intro .text-intro b {
  font-size: 2.2rem;
}
.GodaMall .main-intro .text-intro p {
  font-size: 15px;
  font-family: "Microsoft JhengHei", sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
}
.GodaMall .main-intro .text-intro .btn-secondary {
  width: 120px;
  height: 44px;
}
.GodaMall .partner-title-container {
  margin-top: 100px;
  justify-content: center;
  align-items: center;
}
.GodaMall .partner-title-container .partner-title {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-style: normal;
  font-size: 32px;
  line-height: 42px;
  font-family: "Noto Sans", sans-serif;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}
.GodaMall .goda-mall {
  padding: 6% 6% 2% 6%;
}
.GodaMall .goda-mall .img {
  align-self: left;
}
.GodaMall .goda-mall .text-mall {
  margin-top: 5%;
  padding-left: 5%;
}
.GodaMall .goda-mall .text-mall .text-title {
  font-size: 24px;
  font-weight: bold;
}
.GodaMall .goda-mall .text-mall .text-desc {
  font-size: 16px;
  font-family: Microsoft JhengHei;
  color: #5f5f5f;
  margin-bottom: 30px;
}
.GodaMall .goda-mall .text-mall li {
  list-style-type: none;
}
.GodaMall .goda-mall .text-mall li p {
  font-size: 16px;
  font-family: Microsoft JhengHei;
  margin-top: 10px;
  margin-bottom: 10px;
}
.GodaMall .goda-mall .text-mall .btn-allservice {
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}

.Subscription .subscription-title-container {
  margin-top: 100px;
  justify-content: center;
  align-items: center;
}
.Subscription .subscription-title-container .subscription-title {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-style: normal;
  font-size: 32px;
  line-height: 42px;
  font-family: "Noto Sans", sans-serif;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}

.skycloud .intro {
  padding: 70px 90px 70px 90px;
}
.skycloud .intro .text {
  padding-right: 50px;
}
.skycloud .intro .text .text-title {
  font-size: 30px;
  font-weight: bolder;
  text-align: left;
}
.skycloud .intro .text .text-desc {
  font-size: 15px;
  text-align: left;
}
.skycloud .service .content {
  padding: 20px 70px 130px 90px;
}
.skycloud .service .content .content-box {
  width: 30%;
  margin-right: 20px;
  border-top: 8px solid #2B80FF;
}
.skycloud .service .content .content-box p {
  font-size: 15px;
  margin-left: -10px;
}
.skycloud .img-slider {
  /* Custom styles for hover state */
}
.skycloud .img-slider .carousel-control-prev {
  top: 45%;
  height: 50px;
  width: 50px;
  left: 5%;
}
.skycloud .img-slider .carousel-control-next {
  top: 45%;
  height: 50px;
  width: 50px;
  right: 5%;
}
.skycloud .img-slider .carousel-control-prev-icon {
  background-image: url("../../public/assets/images/btn_back_w_carousels_n.svg");
}
.skycloud .img-slider .carousel-control-next-icon {
  background-image: url("../../public/assets/images/btn_next_w_carousels_n.svg");
}
.skycloud .img-slider .carousel-control-prev-icon:hover {
  background-image: url("../../public/assets/images/btn_back_g_carousels_n.svg");
}
.skycloud .img-slider .carousel-control-next-icon:hover {
  background-image: url("../../public/assets/images/btn_next_g_carousels_n.svg");
}
.skycloud .pros {
  padding: 90px 90px 90px 90px;
}
.skycloud .pros .content {
  padding-top: 50px;
}
.skycloud .pros .content p {
  padding-top: 10px;
  font-size: 15px;
}
.skycloud .pros .content b {
  font-size: 18px;
  color: #045FD8;
}
.skycloud .profit {
  background-color: #000000;
  padding: 70px 200px 70px 200px;
}
.skycloud .profit b {
  color: #FFFFFF;
}
.skycloud .scene {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1%;
  margin-bottom: 4vh;
}
.skycloud .scene .text-title {
  text-align: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
}
.skycloud .scene .col-swiper {
  padding: 1% 7%;
}
.skycloud .scene .col-swiper .swiper {
  padding-bottom: 1%;
  width: 100%;
}
.skycloud .scene .col-swiper .swiper .swiper-pagination {
  display: none;
}
.skycloud .scene .col-swiper ::part(pagination) {
  display: none !important;
}
.skycloud .scene .nav-pag {
  display: flex;
  justify-content: center;
}
.skycloud .scene .nav-pag .btn-primary {
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0) !important;
}
.skycloud .scene .nav-pag .btn-primary:hover {
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0) !important;
}
.skycloud .scene .nav-pag .button_next,
.skycloud .scene .nav-pag .button_prev {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.skycloud .scene .nav-pag .button_next .button-next,
.skycloud .scene .nav-pag .button_next .button-prev,
.skycloud .scene .nav-pag .button_prev .button-next,
.skycloud .scene .nav-pag .button_prev .button-prev {
  background-size: 100%;
  background-repeat: no-repeat;
  width: 30px;
  height: 40px;
}
.skycloud .scene .nav-pag .button_next .button-next,
.skycloud .scene .nav-pag .button_prev .button-next {
  background-image: url("../../public/assets/images/btn_next_g_carousels_n.svg");
}
.skycloud .scene .nav-pag .button_next .button-next:disabled,
.skycloud .scene .nav-pag .button_prev .button-next:disabled {
  background-image: url("../../public/assets/images/btn_next_w_carousels_n.svg");
}
.skycloud .scene .nav-pag .button_next .button-prev,
.skycloud .scene .nav-pag .button_prev .button-prev {
  background-image: url("../../public/assets/images/btn_back_g_carousels_n.svg");
}
.skycloud .scene .nav-pag .button_next .button-prev:disabled,
.skycloud .scene .nav-pag .button_prev .button-prev:disabled {
  background-image: url("../../public/assets/images/btn_back_w_carousels_n.svg");
}
.skycloud .scene .nav-pag .pags {
  padding-top: 1%;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.skycloud .scene .nav-pag .pags .pagination {
  width: -moz-fit-content !important;
  width: fit-content !important;
  height: 5px !important;
}
.skycloud .scene .nav-pag .pags .pagination .page-item {
  margin-right: 5px;
  margin-left: 5px;
  width: 20px !important;
  height: 5px !important;
}
.skycloud .scene .nav-pag .pags .pagination .page-link {
  width: 20px !important;
  height: 5px !important;
  border-radius: 0% !important;
  border: none !important;
  padding-bottom: 0% !important;
  background-color: #e9e9e9 !important;
}
.skycloud .scene .nav-pag .pags .pagination .page-link.active,
.skycloud .scene .nav-pag .pags .pagination .active > .page-link {
  background-color: #7fbe25 !important;
}
.skycloud .promotion .firstrow {
  padding: 30px 90px 50px 90px;
  background-image: linear-gradient(#F5F9FC, #F5F9FC);
  background-size: 37% 100%;
  background-repeat: no-repeat;
}
.skycloud .promotion .firstrow .title {
  font-size: 30px;
  padding-bottom: 50px;
}
.skycloud .promotion .secrow {
  padding: 40px 90px 100px 180px;
  background-image: linear-gradient(#F5F9FC, #F5F9FC);
  background-size: 10px 100%;
  background-size: 23% 60%;
  background-position: right top;
  background-repeat: no-repeat;
}
.skycloud .promotion .secrow .title {
  font-size: 30px;
  padding-bottom: 50px;
}

.pentiumnetwork .intro {
  padding: 130px 90px 130px 90px;
  background-color: #012446;
}
.pentiumnetwork .intro .text {
  padding-right: 50px;
}
.pentiumnetwork .intro .text .text-title {
  color: #FFFFFF;
  font-size: 30px;
  font-weight: bolder;
  text-align: left;
}
.pentiumnetwork .intro .text .text-desc {
  padding-top: 30px;
  font-size: 15px;
  text-align: left;
}
.pentiumnetwork .intro .text .text-desc br {
  margin-bottom: 20px;
  display: block;
  content: "";
}
.pentiumnetwork .sec-row {
  padding: 50px 90px 70px 90px;
}
.pentiumnetwork .sec-row .title {
  font-size: 30px;
  font-weight: bold;
}
.pentiumnetwork .sec-row .text {
  padding-top: 30px;
  font-weight: 400;
  color: #5F5F5F;
  font-size: 18px;
  padding-bottom: 30px;
}
.pentiumnetwork .sec-row .text br {
  margin-bottom: 20px;
  display: block;
  content: "";
}
.pentiumnetwork .first-right-row {
  padding: 30px 90px 70px 90px;
}
.pentiumnetwork .first-right-row .text {
  padding-right: 50px;
}
.pentiumnetwork .first-right-row .text .text-title {
  font-size: 30px;
  font-weight: bolder;
  text-align: left;
}
.pentiumnetwork .first-right-row .text .text-desc {
  color: #5F5F5F;
  font-size: 15px;
  text-align: left;
}
.pentiumnetwork .first-right-row .text .text-desc br {
  margin-bottom: 20px;
  display: block;
  content: "";
}
.pentiumnetwork .right-row {
  padding: 70px 90px 70px 90px;
}
.pentiumnetwork .right-row .text {
  padding-right: 50px;
}
.pentiumnetwork .right-row .text .text-title {
  font-size: 30px;
  font-weight: bolder;
  text-align: left;
}
.pentiumnetwork .right-row .text .text-desc {
  color: #5F5F5F;
  font-size: 15px;
  text-align: left;
}
.pentiumnetwork .right-row .text .text-desc br {
  margin-bottom: 20px;
  display: block;
  content: "";
}
.pentiumnetwork .left-row {
  padding: 70px 90px 70px 90px;
}
.pentiumnetwork .left-row .text {
  padding-left: 50px;
}
.pentiumnetwork .left-row .text .text-title {
  font-size: 30px;
  font-weight: bolder;
  text-align: left;
}
.pentiumnetwork .left-row .text .text-desc {
  color: #5F5F5F;
  font-size: 15px;
  text-align: left;
}
.pentiumnetwork .left-row .text .text-desc br {
  margin-bottom: 20px;
  display: block;
  content: "";
}
.pentiumnetwork .service-health-check {
  padding: 70px 90px 70px 90px;
}
.pentiumnetwork .service-health-check .tab_content {
  margin-top: 6%;
  align-items: center;
}
.pentiumnetwork .service-health-check .tab {
  align-items: left !important;
  padding-top: 75px;
}
.pentiumnetwork .service-health-check .tab .func-text {
  padding-left: 7%;
  font-size: 14px;
  font-weight: bold;
  color: #0067b0;
}
.pentiumnetwork .service-health-check .tab .text-title {
  padding-left: 7%;
  font-size: 32px;
  font-weight: bold;
}
.pentiumnetwork .service-health-check .tab .accordion .accordion-item {
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-bottom: 100% solid #dddddd !important;
}
.pentiumnetwork .service-health-check .tab .accordion .nav-item {
  border: 0px !important;
  width: 100%;
}
.pentiumnetwork .service-health-check .tab .accordion .accordion-header .accordion-button[aria-expanded=true] {
  border-bottom: 0px !important;
  border-radius: 0% !important;
  border-left: 10px solid #7fbe25 !important;
}
.pentiumnetwork .service-health-check .tab .accordion .accordion-header .accordion-button {
  font-size: 18px !important;
  font-weight: bold !important;
  border-bottom: white !important;
}
.pentiumnetwork .service-health-check .tab .accordion .accordion-header .accordion-button:not(.collapsed) {
  border: 0px;
  background-color: #ffffff;
}
.pentiumnetwork .service-health-check .tab .accordion .accordion-body {
  font-size: 16px;
  font-family: Microsoft JhengHei;
  border-left: 10px solid #7fbe25 !important;
}
.pentiumnetwork .service-health-check .tab .btn-allservice {
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}
.pentiumnetwork .advantage {
  padding: 30px 90px 30px 90px;
  background-color: #F5F9FC;
}
.pentiumnetwork .advantage .cards .card-body {
  padding: 40px 50px 50px 50px;
  height: 60vh;
}
.pentiumnetwork .advantage .cards .card-body .card-title {
  color: #045FD8;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 20px;
  display: flex;
}
.pentiumnetwork .advantage .cards .card-body .card-text {
  padding-bottom: 5px;
}

.OperationExcellent {
  height: auto;
}
.OperationExcellent .main-intro {
  height: auto;
  padding-top: 8%;
  padding-bottom: 4%;
  background: transparent linear-gradient(to right, #e0edf5, #ffffff, #e4f2f2);
}
.OperationExcellent .main-intro .text-intro {
  padding-left: 6%;
  padding-top: 5%;
}
.OperationExcellent .main-intro .text-intro b {
  font-size: 2.2rem;
}
.OperationExcellent .main-intro .text-intro p {
  font-size: 16px;
  font-family: "Microsoft JhengHei", sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
}
.OperationExcellent .main-intro .text-intro .btn-secondary {
  margin-top: 5%;
  width: 120px;
  height: 44px;
}
.OperationExcellent .func-text {
  font-size: 14px;
  font-weight: bold;
  color: #0067b0;
}
.OperationExcellent .resource-asset-configuration {
  padding: 7% 2% 7% 5%;
}
.OperationExcellent .resource-asset-configuration .text-resource {
  margin-top: 3%;
  padding-right: 5%;
}
.OperationExcellent .resource-asset-configuration .text-resource .text-title {
  font-size: 32px;
  font-weight: bold;
}
.OperationExcellent .resource-asset-configuration .text-resource .text-desc {
  font-size: 16px;
  font-family: Microsoft JhengHei;
  color: #323232;
}
.OperationExcellent .resource-asset-configuration .text-resource .btn-allservice {
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}
.OperationExcellent .resource-dashboard {
  padding: 7% 5% 7% 5%;
  background-color: #f5f9fc;
}
.OperationExcellent .resource-dashboard .img {
  align-self: center;
}
.OperationExcellent .resource-dashboard .text-resource {
  padding-right: 5%;
}
.OperationExcellent .resource-dashboard .text-resource .text-title {
  font-size: 32px;
  font-weight: bold;
}
.OperationExcellent .resource-dashboard .text-resource .text-desc {
  font-size: 16px;
  font-family: Microsoft JhengHei;
  color: #323232;
}
.OperationExcellent .resource-dashboard .text-resource .btn-allservice {
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}
.OperationExcellent .service-health-check {
  padding: 6% 5% 10% 5%;
}
.OperationExcellent .service-health-check .tab_content {
  margin-top: 6%;
  align-items: center;
}
.OperationExcellent .service-health-check .tab {
  align-items: left !important;
  padding-top: 3%;
}
.OperationExcellent .service-health-check .tab .func-text {
  padding-left: 7%;
  font-size: 14px;
  font-weight: bold;
  color: #0067b0;
}
.OperationExcellent .service-health-check .tab .text-title {
  padding-left: 7%;
  font-size: 32px;
  font-weight: bold;
}
.OperationExcellent .service-health-check .tab .accordion .accordion-item {
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-bottom: 100% solid #dddddd !important;
}
.OperationExcellent .service-health-check .tab .accordion .nav-item {
  border: 0px !important;
  width: 100%;
}
.OperationExcellent .service-health-check .tab .accordion .accordion-header .accordion-button[aria-expanded=true] {
  border-bottom: 0px !important;
  border-radius: 0% !important;
  border-left: 10px solid #7fbe25 !important;
}
.OperationExcellent .service-health-check .tab .accordion .accordion-header .accordion-button {
  font-size: 18px !important;
  font-weight: bold !important;
  border-bottom: white !important;
}
.OperationExcellent .service-health-check .tab .accordion .accordion-body {
  font-size: 16px;
  font-family: Microsoft JhengHei;
  border-left: 10px solid #7fbe25 !important;
}
.OperationExcellent .service-health-check .tab .btn-allservice {
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}
.OperationExcellent .subscr-plan {
  background: #f5f9fc;
  padding-bottom: 10%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.OperationExcellent .subscr-plan .text-title {
  margin-top: 3%;
  margin-bottom: 3%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.SecurityPosture .py-2 {
  height: 160px;
}
.SecurityPosture .py-2 .text-title {
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  color: #5F5F5F;
  margin-bottom: 15px;
}

.SecurityScore .py-2 {
  height: 160px;
}
.SecurityScore .py-2 .text-title {
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  color: #5F5F5F;
  margin-bottom: 15px;
}

.DesignAndDeploy .main-intro {
  font-family: Noto Sans;
  height: auto;
}
.DesignAndDeploy .main-intro .banner {
  margin: 0px;
  color: #F0F0F0;
  width: 100%;
  height: 200px;
  background-image: url("../../public/assets/images/bg_design_and_deploy.png");
  background-size: cover;
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  padding: 10% 2% 9% 7%;
}
.DesignAndDeploy .content {
  padding: 10% 3% 8% 7%;
}
.DesignAndDeploy .content .content-text {
  padding-left: 3%;
}
.DesignAndDeploy .content .content-text .text-title {
  padding-bottom: 3%;
}
.DesignAndDeploy .content .content-text li {
  width: 85%;
  box-sizing: border-box;
  height: calc(40% + 10px);
  display: flex;
  align-items: flex-start;
  list-style: none;
}
.DesignAndDeploy .content .content-text li .li-text b {
  margin-bottom: 20%;
}

/*---------------
 * BlockCanvas
 ----------------*/
#DesignAndDeploy #BlockCanvas .component {
  display: inline-block;
  cursor: pointer;
  padding: 10px;
  padding-bottom: 13px;
  max-width: 75px;
  text-align: center;
}

#DesignAndDeploy #BlockCanvas .component.in-canvas {
  position: absolute;
}

#DesignAndDeploy #BlockCanvas .component.in-canvas img {
  width: 55px;
}

/*---------------
 * BlockNodeDetail
 ----------------*/
#DesignAndDeploy #BlockNodeDetail .contentBlock {
  background: #323232;
  color: #ffffff;
  border-radius: 4px;
}

#DesignAndDeploy #BlockNodeDetail .contentBlock * {
  font-size: 14px;
}

.text-subtitle-detail {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0 !important;
}

.RecommendationsDetailDrawer .text-title {
  font-size: 18px;
  font-weight: bold;
  font-family: "Source Sans 3", sans-serif;
  color: #1D1D1D;
}
.RecommendationsDetailDrawer .offcanvas-body {
  padding: 24px;
}
.RecommendationsDetailDrawer .offcanvas-body .statics-content {
  width: 100%;
  min-height: 106px;
  height: auto;
  display: flex;
  margin-bottom: 26px;
  flex-direction: column;
  justify-content: center;
}
.RecommendationsDetailDrawer .offcanvas-body .statics-content .row {
  display: flex;
  text-align: center;
  align-items: center;
}
.RecommendationsDetailDrawer .offcanvas-body .statics-content .text-statics {
  font-size: 13px;
  font-weight: 500;
  color: #5F5F5F;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.RecommendationsDetailDrawer .offcanvas-body .statics-content .text-statics-number {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #323232;
}
.RecommendationsDetailDrawer .offcanvas-body .text-description {
  font-size: 13px;
  color: #5F5F5F;
  padding: 20px 0px;
}
.RecommendationsDetailDrawer .offcanvas-body .download {
  padding: 0 18px;
}
.RecommendationsDetailDrawer .offcanvas-body .download .text-desc {
  font-size: 16px;
  color: #323232;
}
.RecommendationsDetailDrawer .offcanvas-body .download .text-title {
  font-size: 14px;
  font-weight: bold;
  color: #323232;
  margin-top: 20px;
  margin-bottom: 5px;
}
.RecommendationsDetailDrawer .button-area {
  display: flex;
  align-items: end;
  justify-content: end;
  padding: 18px;
}
.RecommendationsDetailDrawer .button-area .btn-download {
  font-size: 15px;
  font-weight: bold;
}

.RegulatoryCompliance {
  font-family: "Noto Sans";
}
.RegulatoryCompliance .card {
  min-height: 254px;
  height: auto;
  display: flex;
  justify-content: center;
  text-align: center;
}
.RegulatoryCompliance .card .text-main-title {
  font-size: 14px;
  font-weight: bold;
  color: #5F5F5F;
  margin-bottom: 12px;
}
.RegulatoryCompliance .card .text-title {
  font-size: 11px;
  color: #5F5F5F;
}
.RegulatoryCompliance .card .icon {
  width: 59px;
  height: 76px;
  margin-bottom: 15px;
}
.RegulatoryCompliance .card .btn-primary {
  width: 190px;
  height: 36px;
  font-size: 14px;
  font-weight: bold;
  color: "#323232";
}

.ReportSubscribe {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.ReportSubscribe .link-area {
  padding: 0 0 20px 0;
}
.ReportSubscribe .link-area .text-link {
  color: #1d1d1d;
  font-size: 20px;
  font-weight: bold;
  font-family: "Source Sans Pro", sans-serif;
  text-decoration: none;
}
.ReportSubscribe .content {
  flex: 1;
  padding: 10px;
  width: 100%;
}
.ReportSubscribe .add-area {
  display: flex;
  align-items: center;
  background-color: #e9e9f0;
  padding: 6px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.ReportSubscribe .add-area .btn-add {
  display: inline-block;
  width: 68px;
  height: 31px;
  border: 1px solid #bdbdbd;
  background-color: #ffffff;
  border-radius: 4px;
  font-size: 12px;
  opacity: 1;
  color: #323232;
}
.ReportSubscribe .sticky-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  border: #E2E2E2;
  border-style: solid;
  border-bottom: none;
  border-left: none;
  border-right: none;
  background-color: #ffffff;
  text-align: center;
  padding: 10px 0;
  z-index: 1000;
}

.ActiveServiceIssue .text-title {
  font-size: 18px;
  font-weight: 600;
  font-family: "Source Sans 3", sans-serif;
  color: #6AA318;
  margin-top: 30px;
}

.HealthAlert {
  font-family: "Noto Sans", sans-serif;
}
.HealthAlert .card {
  margin-top: 10px;
  padding: 20px;
  color: #323232;
}
.HealthAlert .card .text-title {
  font-size: 14px;
  font-weight: bold;
  margin-right: 20px;
}
.HealthAlert .card .text-desc {
  font-size: 13px;
}

.CloudGodaQA {
  font-family: Noto Sans;
  min-height: 2000px;
  margin-bottom: 10%;
  height: auto;
}
.CloudGodaQA .banner {
  width: 100%;
  height: 260px;
  opacity: 90%;
  border: 1px solid #f0f0f0;
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0px;
  display: flex;
  align-items: center; /*垂直置中*/
  padding: 10% 2% 9% 7%;
  opacity: 1;
}
.CloudGodaQA .sidebar {
  width: 198px;
  height: 488px;
  margin-top: 30px;
  margin-left: 30px;
  padding-top: 24px;
  background: #f0f0f0 0% 0% no-repeat padding-box;
  z-index: 0;
  opacity: 1;
}
.CloudGodaQA .sidebar .title-sidebar {
  display: none;
}
.CloudGodaQA .sidebar .text-sidebar:hover {
  color: #7fbe25;
}
.CloudGodaQA .text-sidebar {
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}
.CloudGodaQA .text-sidebar:hover {
  color: #7fbe25;
}
.CloudGodaQA .layout-sidebar {
  width: 198px;
  height: 50px;
  padding-left: 27px;
  padding-top: 13px;
  text-align: left;
  opacity: 1;
}
.CloudGodaQA .text-title {
  height: 94px;
  display: flex;
  align-items: center; /*垂直置中*/
  text-align: left;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #1d1d1d;
  opacity: 1;
}
.CloudGodaQA .text-title-qa {
  height: 94px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  font-family: "Microsoft JhengHei UI", sans-serif;
  letter-spacing: 0px;
  color: #7fbe25;
  opacity: 1;
}
.CloudGodaQA .desc-layout {
  height: auto;
  opacity: 1;
}
.CloudGodaQA .accordion .accordion-item {
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-bottom: 100% solid #dddddd !important;
}
.CloudGodaQA .accordion .nav-item {
  border: 0px !important;
  width: 100%;
}
.CloudGodaQA .accordion .accordion-header .accordion-button[aria-expanded=true] {
  border-bottom: 0px !important;
  border-radius: 0% !important;
  border-left: 10px solid #7fbe25 !important;
  font-weight: bold !important;
}
.CloudGodaQA .accordion .accordion-header .accordion-button {
  font-size: 18px !important;
  border-bottom: white !important;
}
.CloudGodaQA .accordion .accordion-body {
  font-size: 16px;
  font-family: Microsoft JhengHei;
  border-left: 10px solid #7fbe25 !important;
}
.CloudGodaQA .text-subtitle {
  text-decoration: underline;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #7fbe25;
  opacity: 1;
}
.CloudGodaQA .col-item {
  height: 136px;
  padding-top: 39px;
}
.CloudGodaQA .text-desc {
  font-size: 14px;
  letter-spacing: 0px;
  margin-top: 12px;
  color: #5f5f5f;
  opacity: 1;
}

.AllService.login {
  font-family: Noto Sans;
  height: 2200px;
}
.AllService.login .banner {
  display: none;
}
.AllService.login .sidebar {
  height: 100%;
  margin-top: 8px;
  margin-left: 0px;
  border: 2px solid #d2d2d2;
  border-bottom: none;
  border-left: none;
  border-top: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding-top: 0px;
  z-index: 0;
  opacity: 1;
}
.AllService.login .sidebar .title-sidebar {
  display: flex;
  width: 100%;
  height: 66px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}
.AllService.login .sidebar .text-sidebar {
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}
.AllService.login .sidebar .layout-sidebar {
  width: 224px;
  height: 40px;
  padding-left: 27px;
  padding-top: 13px;
  text-align: left;
  opacity: 1;
}
.AllService.login .text-title {
  height: 94px;
  display: flex;
  align-items: center; /*垂直置中*/
  text-align: left;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #1d1d1d;
  opacity: 1;
}
.AllService.login .desc-layout {
  height: auto;
  opacity: 1;
}
.AllService.login .text-subtitle {
  text-decoration: underline;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #7fbe25;
  opacity: 1;
}
.AllService.login .col-item {
  height: 136px;
  padding-top: 39px;
}
.AllService.login .text-desc {
  font-size: 14px;
  letter-spacing: 0px;
  margin-top: 12px;
  color: #5f5f5f;
  opacity: 1;
}

.TermsOfService {
  font-family: Noto Sans;
  margin-bottom: 300px;
}
.TermsOfService .banner {
  width: 100%;
  height: 260px;
  opacity: 90%;
  border: 1px solid #f0f0f0;
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0px;
  display: flex;
  align-items: center; /*垂直置中*/
  padding: 10% 2% 9% 7%;
  opacity: 1;
}
.TermsOfService .text-title-qa {
  height: 94px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  font-family: "Microsoft JhengHei UI", sans-serif;
  letter-spacing: 0px;
  color: #7fbe25;
  opacity: 1;
}
.TermsOfService .text-qa {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  font-family: "Microsoft JhengHei", sans-serif;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}

.ContactUs {
  font-family: Noto Sans;
  margin-bottom: 100px;
}
.ContactUs .banner {
  width: 100%;
  height: 260px;
  opacity: 90%;
  border: 1px solid #f0f0f0;
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0px;
  display: flex;
  align-items: center; /*垂直置中*/
  padding: 10% 2% 9% 7%;
  opacity: 1;
}
.ContactUs .contact-us-form {
  background-color: #f5f9fc;
  padding-left: 150px;
  padding-right: 150px;
  padding-top: 116px;
  padding-bottom: 138px;
}
.ContactUs .contact-us-form .form-row {
  margin-bottom: 48px;
}
.ContactUs .contact-us-form .form-group {
  margin-bottom: 15px;
}
.ContactUs .contact-us-form .form-group .required-asterisk {
  color: red;
}
.ContactUs .contact-us-form .form-group .form-label {
  font-weight: bold;
  color: #333;
}
.ContactUs .contact-us-form .form-group .form-control {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  padding: 10px;
}
.ContactUs .contact-us-form .form-group .form-control:focus {
  border-color: #7fbe25;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); /* Change RGBA to match your green color */
}
.ContactUs .contact-us-form .form-group .form-check {
  margin-top: 10px;
}
.ContactUs .contact-us-form .form-group .form-check .form-check-label {
  font-size: 14px;
}
.ContactUs .contact-us-form .form-group .form-check .form-check-input {
  cursor: pointer;
}
.ContactUs .contact-us-form .form-group .form-check .form-check-input:checked {
  border: #7fbe25;
  background-color: #7fbe25;
}
.ContactUs .contact-us-form .form-group .form-check .form-check-input:hover {
  border-width: 2px;
  border-style: solid;
  border-color: #7fbe25;
}
.ContactUs .contact-us-form .issue-type-selector select {
  padding: 5px 10px;
  opacity: 1;
  width: 232px;
  cursor: pointer;
  background: #ffffff no-repeat padding-box;
  border-radius: 4px;
  letter-spacing: 0px;
  color: #323232;
}
.ContactUs .contact-us-form .issue-type-selector select:hover {
  border-color: #7fbe25;
}
.ContactUs .contact-us-form .issue-type-selector select:focus {
  box-shadow: #7fbe25;
  border-color: #7fbe25;
}
.ContactUs .contact-us-form .issue-type-selector select:active {
  box-shadow: #7fbe25;
  border-color: #7fbe25;
}
.ContactUs .contact-us-form .issue-type-selector select option {
  height: 100px;
  padding: 5px 10px;
}
.ContactUs .contact-us-form .issue-type-selector select option:hover {
  background-color: #7fbe25;
}
.ContactUs .contact-us-form .issue-type-selector select option:hover {
  background-color: #7fbe25;
}
.ContactUs .contact-us-form textarea.form-control {
  height: auto;
  padding: 15px;
}
.ContactUs .contact-us-form .submit-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #007bff;
}
.ContactUs .contact-us-form .submit-button:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.CompanyBaseInfo.SelectCompanyMemberModal {
  max-width: 50%;
}
.CompanyBaseInfo.SelectCompanyMemberModal .header {
  padding: 15px 10px 5px 15px;
}
.CompanyBaseInfo.SelectCompanyMemberModal .header .text-title {
  font-size: 14px;
  font-weight: bold;
}
.CompanyBaseInfo.SelectCompanyMemberModal .body {
  max-height: 70vh;
}
.CompanyBaseInfo.SelectCompanyMemberModal .body .row {
  padding: 0px;
}

.CompanyMemberManagement {
  width: 100%;
}
.CompanyMemberManagement .style-row {
  display: flex;
  padding: 30px 22px;
  justify-content: flex-end; /* 靠右對齊 */
  align-items: center; /* 垂直居中 */
}
.CompanyMemberManagement .btn-group {
  width: 160px;
  border: 1px solid #BDBDBD;
  border-radius: 4px;
}
.CompanyMemberManagement .btn-group .btn-add-member .btn-invite {
  width: 80px;
  height: 28px;
  font-size: 12px;
  color: #323232;
}
.CompanyMemberManagement .link-area {
  padding: 0 0 20px 0;
}
.CompanyMemberManagement .link-area .text-link {
  color: #1D1D1D;
  font-size: 20px;
  font-weight: bold;
  font-family: "Source Sans Pro", sans-serif;
  text-decoration: none;
}

.CompanyMemberManagement.InviteModal .modal-header .modal-title {
  font-size: 14px;
  color: #323232;
  font-weight: bold;
}
.CompanyMemberManagement.InviteModal .form {
  padding: 0 0 0 0;
}
.CompanyMemberManagement.InviteModal .form .spin .modal-body {
  padding: 0px 24px 160px 24px;
}
.CompanyMemberManagement.InviteModal .form .spin .modal-body .form-title {
  font-size: 12px;
  color: #323232;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 6px;
}
.CompanyMemberManagement.InviteModal .form .spin .modal-body .input-field {
  height: 80px;
  font-weight: normal !important;
  font-size: 11px;
}
.CompanyMemberManagement.InviteModal .form .spin .modal-body .text-danger.input-field {
  font-size: 12px !important;
  padding: 0;
  margin: 0;
}
.CompanyMemberManagement.InviteModal .form .spin .modal-body .add-area {
  display: flex;
  align-items: center;
  background-color: #e9e9f0;
  padding: 2% 3%;
  margin-top: 5px;
  margin-bottom: 10px;
}
.CompanyMemberManagement.InviteModal .form .spin .modal-body .add-area .btn-add {
  display: inline-block;
  width: 20%;
  height: 100%;
  border: 1px solid #bdbdbd;
  background-color: #ffffff;
  border-radius: 4px;
  font-size: 12px;
  opacity: 1;
  color: #323232;
}
.CompanyMemberManagement.InviteModal .form .modal-footer .btn-primary {
  width: 94px;
  height: 36px;
  font-size: 14px;
}

.CompanyMemberManagement.EditModal {
  max-width: 45%;
}
.CompanyMemberManagement.EditModal .modal-header .modal-title {
  font-size: 14px;
  color: #323232;
  font-weight: bold;
}
.CompanyMemberManagement.EditModal .modal-body {
  padding: 0 0 0 0;
  margin-bottom: 10%;
}
.CompanyMemberManagement.EditModal .modal-body .form {
  padding: 0 20px 20px 20px;
}
.CompanyMemberManagement.EditModal .modal-body .form .form-title {
  font-size: 12px;
  color: #323232;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 0px;
}
.CompanyMemberManagement.EditModal .modal-body .form .input-field {
  font-weight: normal !important;
  font-size: 11px;
}
.CompanyMemberManagement.EditModal .modal-body .form .text-danger.input-field {
  font-size: 12px !important;
  padding: 0;
  margin: 0;
}
.CompanyMemberManagement.EditModal .modal-body .form .form-select {
  width: 50%;
  font-size: 10px;
  color: #323232;
  border-radius: 10%;
}
.CompanyMemberManagement.EditModal .modal-body .list-area {
  padding: 20px 25px;
  margin-bottom: 10%;
}
.CompanyMemberManagement.EditModal .modal-body .list-area .list-item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  cursor: default;
}
.CompanyMemberManagement.EditModal .modal-body .list-area .list-item .hover-image {
  cursor: pointer;
  display: none;
  z-index: 1;
}
.CompanyMemberManagement.EditModal .modal-body .list-area .list-item:hover {
  background-color: #E2E2E2;
}
.CompanyMemberManagement.EditModal .modal-body .list-area .list-item:hover .hover-image {
  display: block;
}
.CompanyMemberManagement.EditModal .modal-body .add-area {
  background-color: #e9e9f0;
  padding: 2% 1% 2% 3%;
  margin-top: 10px;
  margin-bottom: 20px;
}
.CompanyMemberManagement.EditModal .modal-body .add-area .btn-add {
  width: 100%;
  height: 100%;
  border: 1px solid #bdbdbd;
  background-color: #ffffff;
  border-radius: 4px;
  font-size: 12px;
  opacity: 1;
  color: #323232;
}
.CompanyMemberManagement.EditModal .modal-footer {
  padding: 2% 4% 3% 4%;
}
.CompanyMemberManagement.EditModal .modal-footer .btn, .CompanyMemberManagement.EditModal .modal-footer .CompanyOrgManagement .btn-group .btn-add-member, .CompanyOrgManagement .btn-group .CompanyMemberManagement.EditModal .modal-footer .btn-add-member {
  width: 90px !important;
  height: 36px !important;
  text-align: center !important;
  font-size: 13px;
}
.CompanyMemberManagement.EditModal .modal-footer .btn-secondary {
  margin-right: 10px;
}

.CompanyMemberManagement.AddModal .modal-header .modal-title {
  font-size: 14px;
  color: #323232;
  font-weight: bold;
}
.CompanyMemberManagement.AddModal .form {
  padding: 0 0 0 0;
}
.CompanyMemberManagement.AddModal .form .spin .modal-body {
  padding: 0 20px 20px 20px;
}
.CompanyMemberManagement.AddModal .form .spin .modal-body .form-title {
  font-size: 12px;
  color: #323232;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 0px;
}
.CompanyMemberManagement.AddModal .form .spin .modal-body .input-field {
  font-weight: normal !important;
  font-size: 11px;
}
.CompanyMemberManagement.AddModal .form .spin .modal-body .text-danger.input-field {
  font-size: 12px !important;
  padding: 0;
  margin: 0;
}
.CompanyMemberManagement.AddModal .form .spin .modal-body .form-select {
  width: 50%;
  font-size: 10px;
  color: #323232;
  border-radius: 10%;
}
.CompanyMemberManagement.AddModal .form .modal-footer {
  padding: 2% 4% 3% 4%;
}
.CompanyMemberManagement.AddModal .form .modal-footer .btn, .CompanyMemberManagement.AddModal .form .modal-footer .CompanyOrgManagement .btn-group .btn-add-member, .CompanyOrgManagement .btn-group .CompanyMemberManagement.AddModal .form .modal-footer .btn-add-member {
  width: 90px !important;
  height: 36px !important;
  text-align: center !important;
  font-size: 13px;
}
.CompanyMemberManagement.AddModal .form .modal-footer .btn-secondary {
  margin-right: 10px;
}

.CompanyMemberManagement.DeleteModal .modal-header .modal-title {
  font-size: 14px;
  color: #323232;
  font-weight: bold;
}
.CompanyMemberManagement.DeleteModal .modal-body {
  padding: 0px 24px 30px 24px;
  font-size: 13px;
}
.CompanyMemberManagement.DeleteModal .modal-footer {
  padding: 2% 4% 3% 4%;
}
.CompanyMemberManagement.DeleteModal .modal-footer .btn, .CompanyMemberManagement.DeleteModal .modal-footer .CompanyOrgManagement .btn-group .btn-add-member, .CompanyOrgManagement .btn-group .CompanyMemberManagement.DeleteModal .modal-footer .btn-add-member {
  width: 90px !important;
  height: 36px !important;
  text-align: center !important;
  font-size: 13px;
}
.CompanyMemberManagement.DeleteModal .modal-footer .btn-secondary {
  margin-right: 10px;
}

.CompanyOrgManagement {
  width: 100%;
}
.CompanyOrgManagement .style-row {
  display: flex;
  padding: 30px 22px;
  justify-content: flex-end;
  align-items: center;
}
.CompanyOrgManagement .btn-group {
  width: 160px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
}
.CompanyOrgManagement .btn-group .btn, .CompanyOrgManagement .btn-group .btn-add-member {
  width: 80px;
  height: 28px;
  font-size: 12px;
  color: #323232;
}
.CompanyOrgManagement .link-area {
  padding: 0 0 20px 0;
}
.CompanyOrgManagement .link-area .text-link {
  color: #1d1d1d;
  font-size: 20px;
  font-weight: bold;
  font-family: "Source Sans Pro", sans-serif;
  text-decoration: none;
}

.CompanyOrgManagement.AddModal .modal-header .modal-title {
  font-size: 14px;
  color: #323232;
  font-weight: bold;
}
.CompanyOrgManagement.AddModal .form {
  padding: 0 0 0 0;
}
.CompanyOrgManagement.AddModal .form .spin .modal-body {
  padding: 0 20px 20px 20px;
  margin-bottom: 30%;
}
.CompanyOrgManagement.AddModal .form .spin .modal-body .form-control.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'%3E%3Cpath fill='%236c757d' d='M5 6L0 0h10L5 6z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 0.65rem 0.65rem;
  padding-right: 1.5rem;
}
.CompanyOrgManagement.AddModal .form .spin .modal-body .text-danger.input-field {
  font-size: 12px !important;
  padding: 0;
  margin: 0;
}
.CompanyOrgManagement.AddModal .form .spin .modal-body .form-select {
  width: 50%;
  font-size: 10px;
  color: #323232;
  border-radius: 10%;
}
.CompanyOrgManagement.AddModal .form .modal-footer {
  padding: 2% 4% 3% 4%;
}
.CompanyOrgManagement.AddModal .form .modal-footer .btn, .CompanyOrgManagement.AddModal .form .modal-footer .btn-group .btn-add-member, .CompanyOrgManagement.AddModal .btn-group .form .modal-footer .btn-add-member {
  width: 90px !important;
  height: 36px !important;
  text-align: center !important;
  font-size: 13px;
}
.CompanyOrgManagement.AddModal .form .modal-footer .btn-secondary {
  margin-right: 10px;
}

.CompanyOrgManagement.UpdateOrgDrawer .offcanvas-header {
  padding: 10px 20px;
}
.CompanyOrgManagement.UpdateOrgDrawer .offcanvas-header .text-title {
  font-size: 16px;
  font-weight: bold;
}
.CompanyOrgManagement.UpdateOrgDrawer .offcanvas-body {
  padding: 0 0 0 0;
}
.CompanyOrgManagement.UpdateOrgDrawer .offcanvas-body .tab-1 {
  padding: 20px 10px 20px 15px;
  display: flex;
  align-items: center;
}
.CompanyOrgManagement.UpdateOrgDrawer .offcanvas-body .tab-1 .btn-org-member {
  width: 105px;
  height: 30px;
  font-size: 13px;
  color: #323232;
  background: none;
  border: 1.5px solid #bdbdbd;
  border-radius: 4px;
  text-align: center;
  opacity: 1;
}
.CompanyOrgManagement.UpdateOrgDrawer .offcanvas-body .tab-1 .btn-org-member:hover {
  background: #5f5f5f 0% 0% no-repeat padding-box;
  border: 1.5px solid #ffffff;
  border-radius: 4px;
  color: #ffffff;
}
.CompanyOrgManagement.UpdateOrgDrawer .offcanvas-body .tab-1 .btn-org-member:active {
  background: none;
  border: 1.5px solid #323232;
  border-radius: 4px;
  color: #5f5f5f;
}
.CompanyOrgManagement.UpdateOrgDrawer .offcanvas-body .tab-2 {
  padding: 0px 24px;
  display: flex;
  align-items: center;
}
.CompanyOrgManagement.UpdateOrgDrawer .offcanvas-body .tab-2 .form {
  height: 100%;
}
.CompanyOrgManagement.UpdateOrgDrawer .offcanvas-body .tab-2 .form .form-control.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'%3E%3Cpath fill='%236c757d' d='M5 6L0 0h10L5 6z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 0.65rem 0.65rem;
  padding-right: 1.5rem;
}
.CompanyOrgManagement.UpdateOrgDrawer .offcanvas-body .tab-2 .form .text-danger.input-field {
  font-size: 12px !important;
  padding: 0;
  margin: 0;
}
.CompanyOrgManagement.UpdateOrgDrawer .offcanvas-body .tab-2 .form .form-select {
  width: 50%;
  font-size: 10px;
  color: #323232;
  border-radius: 10%;
}

.CompanyOrgManagement.AddMemberToOrgModal {
  max-width: 70%;
}
.CompanyOrgManagement.AddMemberToOrgModal .header {
  padding: 15px 10px 5px 15px;
}
.CompanyOrgManagement.AddMemberToOrgModal .header .text-title {
  font-size: 14px;
  font-weight: bold;
}
.CompanyOrgManagement.AddMemberToOrgModal .body {
  max-height: 70vh;
}
.CompanyOrgManagement.AddMemberToOrgModal .body .row {
  padding: 0px;
}

.ProcessManagement {
  width: 100%;
}
.ProcessManagement .style-row {
  display: flex;
  padding: 25px 15px;
  justify-content: flex-end; /* 靠右對齊 */
  align-items: center; /* 垂直居中 */
}
.ProcessManagement .link-area {
  padding: 0 0 20px 0;
}
.ProcessManagement .link-area .text-link {
  color: #1d1d1d;
  font-size: 20px;
  font-weight: bold;
  font-family: "Source Sans Pro", sans-serif;
  text-decoration: none;
}

.ProcessManagement.Modal .modal-header .modal-title {
  font-size: 14px;
  color: #323232;
  font-weight: bold;
}
.ProcessManagement.Modal .form {
  padding: 0 0 0 0;
}
.ProcessManagement.Modal .form .spin .modal-body {
  padding: 0 20px 20px 20px;
  margin-bottom: 5%;
}
.ProcessManagement.Modal .form .spin .modal-body .form-control.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'%3E%3Cpath fill='%236c757d' d='M5 6L0 0h10L5 6z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 0.65rem 0.65rem;
  padding-right: 1.5rem;
}
.ProcessManagement.Modal .form .spin .modal-body .text-danger.input-field {
  font-size: 12px !important;
  padding: 0;
  margin: 0;
}
.ProcessManagement.Modal .form .spin .modal-body .form-select {
  width: 50%;
  font-size: 10px;
  color: #323232;
  border-radius: 10%;
}
.ProcessManagement.Modal .form .spin .modal-body .form-control {
  font-size: 13px;
}
.ProcessManagement.Modal .form .spin .modal-body .text-danger {
  font-size: 13px;
}
.ProcessManagement.Modal .form .modal-footer {
  padding: 2% 4% 3% 4%;
}
.ProcessManagement.Modal .form .modal-footer .btn, .ProcessManagement.Modal .form .modal-footer .CompanyOrgManagement .btn-group .btn-add-member, .CompanyOrgManagement .btn-group .ProcessManagement.Modal .form .modal-footer .btn-add-member {
  width: 90px !important;
  height: 36px !important;
  text-align: center !important;
  font-size: 13px;
}
.ProcessManagement.Modal .form .modal-footer .btn-secondary {
  margin-right: 10px;
}

.TeamsAppKey {
  width: 100%;
}
.TeamsAppKey .style-row {
  display: flex;
  padding: 6px 5px 22px 5px;
  justify-content: flex-end; /* 靠右對齊 */
  align-items: center; /* 垂直居中 */
}
.TeamsAppKey .style-row .btn-auth {
  width: 110px;
  height: 29px;
  font-size: 12px;
  font-weight: bold;
  color: #323232;
}
.TeamsAppKey .style-row .btn-token {
  width: 100px;
  height: 29px;
  font-size: 12px;
  font-weight: bold;
  color: #323232;
}
.TeamsAppKey .link-area {
  padding: 0 0 20px 0;
}
.TeamsAppKey .link-area .text-link {
  color: #1D1D1D;
  font-size: 20px;
  font-weight: bold;
  font-family: "Source Sans Pro", sans-serif;
  text-decoration: none;
}

.CompanyWorkingDay.AddModal .modal-header .modal-title {
  font-size: 14px;
  color: #323232;
  font-weight: bold;
}
.CompanyWorkingDay.AddModal .modal-body {
  padding: 0px 24px 10% 24px;
  font-size: 13px;
}
.CompanyWorkingDay.AddModal .modal-body .form-control {
  font-size: 13px;
}
.CompanyWorkingDay.AddModal .modal-body .MuiFormControlLabel-label {
  font-size: 13px;
}
.CompanyWorkingDay.AddModal .modal-footer {
  padding: 2% 4% 3% 4%;
}
.CompanyWorkingDay.AddModal .modal-footer .btn, .CompanyWorkingDay.AddModal .modal-footer .CompanyOrgManagement .btn-group .btn-add-member, .CompanyOrgManagement .btn-group .CompanyWorkingDay.AddModal .modal-footer .btn-add-member {
  width: 90px !important;
  height: 36px !important;
  text-align: center !important;
  font-size: 13px;
}
.CompanyWorkingDay.AddModal .modal-footer .btn-secondary {
  margin-right: 10px;
}

.custom-responsive-row .custom-col {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 768px) {
  .custom-responsive-row .custom-col-left {
    padding-right: 16px;
    padding-left: 0;
  }
  .custom-responsive-row .custom-col-right {
    padding-right: 0;
  }
}
@media (max-width: 768px) {
  .custom-responsive-row .custom-col-left {
    padding-left: 0;
  }
  .custom-responsive-row .custom-col-right {
    margin-top: 16px;
    padding-right: 0;
  }
}

.CompanyManagement {
  width: 100%;
}
.CompanyManagement .card {
  width: 100%;
  height: 58px;
  margin-bottom: 10px;
}
.CompanyManagement .card:hover {
  border-color: #7fbe25;
}
.CompanyManagement .card .link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  padding: 10px 0px 10px 20px;
  text-decoration: none;
  color: #323232;
}
.CompanyManagement .card .link:hover {
  color: #7fbe25;
}
.CompanyManagement .card .link:hover .material-symbols-rounded {
  color: #7fbe25;
}

.CompanyWizard {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.CompanyWizard .sticky-header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  color: white;
  text-align: center;
  padding: 10px 0;
  z-index: 1000;
}
.CompanyWizard .sticky-header .link-area {
  padding: 0;
}
.CompanyWizard .sticky-header .link-area .text-link {
  color: #1d1d1d;
  font-size: 20px;
  font-weight: bold;
  font-family: "Source Sans Pro", sans-serif;
  text-decoration: none;
}
.CompanyWizard .content {
  flex: 1;
  padding: 10px;
  width: 100%;
}
.CompanyWizard .sticky-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  border: #E2E2E2 2px;
  border-style: solid;
  border-bottom: none;
  border-left: none;
  border-right: none;
  background-color: #ffffff;
  text-align: center;
  padding: 10px 0;
  z-index: 1000;
}

.LinkSelect {
  padding-top: 3% !important;
}
.LinkSelect .modal-content {
  width: 110% !important;
  height: 80% !important;
  padding-left: -5%;
}
.LinkSelect .modal-content .modal-header .modal-title {
  font-size: 20px;
  color: #323232;
  font-weight: bold;
}
.LinkSelect .modal-body {
  padding: 2% 4% 0% 4%;
}
.LinkSelect .modal-body .form {
  padding-left: 2.5%;
}
.LinkSelect .modal-body .form .form-title {
  padding-left: 0%;
  padding-bottom: 1%;
  font-size: 15px;
  color: #323232;
  font-weight: bold;
}
.LinkSelect .modal-body .form .form-select {
  width: 50%;
  font-size: 15px;
  color: #323232;
  border-radius: 10%;
}
.LinkSelect .modal-body .submenu {
  height: 300px;
  padding: 5% 3% 5% 1%;
}
.LinkSelect .modal-body .submenu .sub-title .list-group {
  padding-top: 7%;
  font-size: 12px;
  color: #323232;
  font-weight: bold;
  width: 108% !important;
}
.LinkSelect .modal-body .submenu .sub-title .list-group .list-group-item {
  border-radius: 0%;
  width: 100% !important;
  border: 0px !important;
}
.LinkSelect .modal-body .submenu .sub-title .list-group .list-group-item[aria-selected=true],
.LinkSelect .modal-body .submenu .sub-title .list-group .list-group-item:hover {
  background-color: #DEF0C4 !important;
  color: #323232 !important;
  font-weight: bold !important;
}
.LinkSelect .modal-body .submenu .sub-option {
  background: #F9F9F9;
  justify-content: left !important;
}
.LinkSelect .modal-body .submenu .sub-option .form-check {
  margin-top: 5%;
  margin-left: 5%;
  font-size: 12px;
  color: #323232;
  font-weight: bold;
}
.LinkSelect .modal-body .submenu .sub-option .form-check .form-check-input {
  font-size: 20px;
  border: #323232 10px;
}
.LinkSelect .modal-body .submenu .sub-option .form-check .form-check-label {
  padding-top: 10%;
}
.LinkSelect .modal-body .submenu .sub-option .form-check .form-check-input:checked[type=checkbox] {
  background-color: white;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%237FBE25%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e")!important;
}
.LinkSelect .modal-body .submenu .sub-option .form-check .form-check-input[type=checkbox] {
  border: 2px solid #E2E2E2;
  border-radius: 3px;
}
.LinkSelect .modal-body .option-selected .badge {
  font-size: 12px;
  color: #323232 !important;
  background: #E9E9E9 !important;
  font-size: 12px;
  border-radius: 100%;
  width: -moz-max-content;
  width: max-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 3%;
  margin-bottom: 2%;
  padding: 1% 2% 1% 2% !important;
  align-items: center !important;
}
.LinkSelect .modal-body .option-selected .badge .btn-close {
  margin-left: 5%;
}
.LinkSelect .modal-footer {
  padding: 3% 4% 5% 4%;
}
.LinkSelect .modal-footer .btn, .LinkSelect .modal-footer .CompanyOrgManagement .btn-group .btn-add-member, .CompanyOrgManagement .btn-group .LinkSelect .modal-footer .btn-add-member {
  width: 100px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 14px;
  border-radius: 10%;
}
.LinkSelect .modal-footer .btn-secondary {
  margin-right: 5%;
}

.HealthAlertModal {
  padding-top: 3% !important;
  width: 60%;
  min-height: 500px;
  height: auto;
}
.HealthAlertModal .modal-content {
  width: 110% !important;
  height: 80% !important;
  padding-left: -5%;
}
.HealthAlertModal .modal-content .modal-header .modal-title {
  font-size: 16px;
  color: #323232;
  font-weight: bold;
}
.HealthAlertModal .modal-body .form {
  padding: 0 2.5%;
}
.HealthAlertModal .modal-body .form .form-title {
  font-size: 14px;
  color: #323232;
  font-weight: bold;
  margin-top: 15px;
  margin-right: 10px;
}
.HealthAlertModal .modal-body .form .input-field {
  font-weight: normal !important;
}
.HealthAlertModal .modal-body .form .text-danger.input-field {
  font-size: 12px !important;
  padding: 0;
  margin: 0;
}
.HealthAlertModal .modal-body .form .form-select {
  width: 50%;
  font-size: 10px;
  color: #323232;
  border-radius: 10%;
}
.HealthAlertModal .modal-footer {
  padding: 3% 4% 5% 4%;
}
.HealthAlertModal .modal-footer .btn, .HealthAlertModal .modal-footer .CompanyOrgManagement .btn-group .btn-add-member, .CompanyOrgManagement .btn-group .HealthAlertModal .modal-footer .btn-add-member {
  width: 100px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 14px;
}
.HealthAlertModal .modal-footer .btn-secondary {
  margin-right: 5%;
}

.custom-select {
  border-radius: 4px !important;
  border-color: #e2e2e2 !important; /* Default border color */
}

.custom-select:hover {
  box-shadow: none !important;
  border-width: 1px !important; /* sets the width of the border */
  border-style: solid !important; /* sets the style of the border to solid */
  border-color: #7fbe25 !important; /* sets the color of the border using a hex code */
}

.custom-select:focus {
  box-shadow: none !important;
  border-width: 1px !important; /* sets the width of the border */
  border-style: solid !important; /* sets the style of the border to solid */
  border-color: #6aa318 !important; /* sets the color of the border using a hex code */
}

@media screen and (min-width: 900px) {
  .linkicon-slot {
    position: relative;
    width: 150px;
    height: 180px;
    background-color: #FFFFFF;
    border: 2px solid #E2E2E2;
    border-radius: 2px;
  }
  .linkicon-slot .delete-button {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
  }
  .linkicon-slot:hover {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  }
  .linkicon-slot:hover .delete-button {
    display: block;
  }
  .linkicon-slot:active {
    border: 2px solid #7FBE25;
    border-radius: 2px;
    box-shadow: none;
  }
  .linkicon-slot:active .delete-button {
    display: block;
  }
  .linkicon-slot .link-title {
    width: 150px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #323232;
    font-size: 16px;
    font-weight: bold;
    padding: 13px 10px;
    opacity: 1;
  }
  .linkicon-slot .link-title .text-title {
    width: -moz-fit-content;
    width: fit-content;
    height: 43px;
    align-content: center;
    text-align: center;
    padding-right: 3px;
  }
  .linkicon-slot .tile-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 110px;
    opacity: 1;
  }
  .linkicon-slot .tile-icon .img-link {
    width: 70px;
    height: 70px;
  }
}
@media screen and (max-width: 899px) {
  .linkicon-slot {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 167px;
    height: 70px;
    background-color: #FFFFFF;
    border: 1px solid #E2E2E2;
    border-radius: 3px;
  }
  .linkicon-slot .delete-button {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
  }
  .linkicon-slot:hover {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  }
  .linkicon-slot:hover .delete-button {
    display: block;
  }
  .linkicon-slot:active {
    border: 1px solid #7FBE25;
    border-radius: 3px;
    box-shadow: none;
  }
  .linkicon-slot:active .delete-button {
    display: block;
  }
  .linkicon-slot .tile-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 119px;
    height: 70px;
    opacity: 1;
  }
  .linkicon-slot .tile-icon .img-link {
    width: 24.3px;
    height: 24.3px;
  }
  .linkicon-slot .link-title {
    width: 150px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #323232;
    padding: 13px 10px;
    opacity: 1;
  }
  .linkicon-slot .link-title .text-title {
    width: 98px;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    align-content: center;
  }
}
@media screen and (min-width: 900px) {
  .addlink-slot {
    width: 150px;
    height: 180px;
    background-color: #FFFFFF;
    border: 2px dashed #E2E2E2;
    border-radius: 2px;
  }
  .addlink-slot:hover .tile-title {
    color: #1D1D1D;
  }
  .addlink-slot:hover ~ .add-icon {
    position: relative;
    width: 40px;
    height: 40px;
    background-image: url("../../public/assets/images/ic_add_h.svg");
  }
  .addlink-slot:active {
    border: 2px dashed #7FBE25;
    border-radius: 2px;
  }
  .addlink-slot:active .tile-title {
    color: #7FBE25;
  }
  .addlink-slot:active .add-icon {
    position: relative;
    width: 40px;
    height: 40px;
    background-image: url("../../public/assets/images/ic_add_p.svg");
  }
  .addlink-slot .tile-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 70px;
    color: #7fbe25;
    font-size: 18px;
    font-weight: bold;
    opacity: 1;
  }
  .addlink-slot .tile-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 110px;
    opacity: 1;
  }
  .addlink-slot .tile-icon .add-icon {
    position: relative;
    width: 40px;
    height: 40px;
    background-image: url("../../public/assets/images/ic_add_n.svg");
  }
}
@media screen and (max-width: 899px) {
  .addlink-slot {
    width: 167px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
    margin-bottom: 16px;
    border: 1px dashed #E2E2E2;
    border-radius: 2px;
  }
  .addlink-slot:hover .tile-title {
    color: #1D1D1D;
  }
  .addlink-slot:hover ~ .add-icon {
    position: relative;
    width: 40px;
    height: 40px;
    background-image: url("../../public/assets/images/ic_add_h.svg");
  }
  .addlink-slot:active {
    border: 1px dashed #7FBE25;
    border-radius: 2px;
  }
  .addlink-slot:active .tile-title {
    color: #7FBE25;
  }
  .addlink-slot:active .add-icon {
    position: relative;
    width: 40px;
    height: 40px;
    background-image: url("../../public/assets/images/ic_add_p.svg");
  }
  .addlink-slot .tile-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 167px;
    height: 70px;
    opacity: 1;
  }
  .addlink-slot .tile-icon .add-icon {
    position: relative;
    width: 20px;
    height: 20px;
    background-image: url("../../public/assets/images/ic_add_n.svg");
  }
  .addlink-slot .tile-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 70px;
    color: #7fbe25;
    font-size: 18px;
    font-weight: bold;
    opacity: 1;
  }
}
.SubscriptionPlan {
  width: 290px;
  height: 480px;
  border-color: #0FC0C0;
  border-radius: 10px;
  margin-bottom: 20px;
}
.SubscriptionPlan .card-header {
  color: #0FC0C0;
  background: none;
  font-size: 14px;
  font-family: "Source Sans 3", sans-serif;
  font-weight: bold;
  border: none;
  background-color: none;
  height: 60px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.SubscriptionPlan .card-body .card-title {
  height: 70px;
  font-size: 30px;
  font-weight: bold;
  font-family: "Source Sans 3", sans-serif;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #323232;
  opacity: 1;
}
.SubscriptionPlan .card-body .card-text {
  padding: 5% 7%;
  color: #5F5F5F;
  font-size: 14px;
  font-weight: bold;
  font-family: "Microsoft JhengHei";
  text-align: left;
}
.SubscriptionPlan .card-body .card-text .text-row {
  height: 28px;
}
.SubscriptionPlan .card-body .card-text .disable {
  color: #D2D2D2;
}
.SubscriptionPlan .card-body .card-button {
  width: 120px;
  height: 44px;
  margin-top: 10px;
}

.GodaBenefit {
  width: 20vw;
  min-width: 180px;
  height: 43vh;
  min-height: 280px;
  padding: 10% 1% 5% 1%;
  border-radius: 10px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-color: #D2D2D2;
  box-shadow: 2px 4px 10px #D2D2D2;
}
.GodaBenefit .card-img {
  width: 44px;
  height: 44px;
  margin-top: 6%;
  margin-bottom: 6%;
}
.GodaBenefit .card-body {
  width: 100%;
  padding: 0%;
}
.GodaBenefit .card-body .card-title {
  font-size: 15px;
  font-weight: bold;
  font-family: "Source Sans 3", sans-serif;
  padding: 1%;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}
.GodaBenefit .card-body .card-text {
  padding: 1%;
  color: #5F5F5F;
  font-size: 14px;
  font-weight: bold;
  font-family: "Microsoft JhengHei UI", sans-serif;
  display: flex;
  justify-content: center;
}
.GodaBenefit .card-body .card-text ul {
  padding: 1% 5%;
  width: 100%;
}
.GodaBenefit .card-body .card-text ul li {
  font-size: 13px;
  padding-bottom: 5px;
  display: "flex";
  text-align: left;
  list-style-type: none;
}
.GodaBenefit .card-body .card-button {
  width: 120px;
  height: 44px;
  margin-top: 30px;
}

.CspmMainFunction {
  background-color: #F9F9F9 !important;
  width: 25vw;
  min-width: 240px;
  height: 40vh;
  min-height: 340px;
  border-radius: 10px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-color: #F9F9F9 !important;
}
.CspmMainFunction .card-img {
  width: 185px;
  height: 161px;
  margin: 8% 0 5% 0;
}
.CspmMainFunction .card-body {
  width: 100%;
}
.CspmMainFunction .card-body .card-title {
  font-size: 20px;
  font-weight: bold;
  font-family: "Noto Sans", sans-serif;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}
.CspmMainFunction .card-body .card-text {
  padding-top: 5%;
  color: #5F5F5F;
  font-size: 12px;
  font-family: "Microsoft JhengHei", sans-serif;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.CspmMainFunction .card-body .card-button {
  width: 120px;
  height: 44px;
  margin-top: 30px;
}

.language-selector select {
  background: #323232;
  color: #f9f9f9;
  padding: 5px 10px;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  opacity: 1;
  width: 232px;
  cursor: pointer;
}
.language-selector select:hover {
  border-color: #7fbe25;
}
.language-selector select:focus {
  border-color: #7fbe25;
}
.language-selector select option {
  padding: 5px 10px;
}

.AppMenuDropdown .layout-title {
  margin-bottom: 7px;
  opacity: 1;
}
.AppMenuDropdown .text-title {
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  text-decoration: none;
  color: #323232;
  opacity: 1;
}
.AppMenuDropdown .text-desc {
  text-align: left;
  margin-bottom: 41px;
  letter-spacing: 0px;
  color: #5F5F5F;
  opacity: 1;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../public/assets/fonts/Noto-Sans-regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
body {
  background-color: #ffffff;
  font-family: "Noto Sans", sans-serif !important;
}
body .custom-datetime input {
  font-size: 14px;
}
body .custom-datetime .rdtPicker td {
  font-size: 14px;
}
body .btn-primary {
  background: #7fbe25 0% 0% no-repeat padding-box;
  border: 1px solid #7fbe25;
  text-align: center;
  font-weight: bold;
  color: #323232;
}
body .btn-primary:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #7fbe25;
  color: #323232;
}
body .btn-primary:active {
  background: #6aa318 0% 0% no-repeat padding-box !important;
  border: 1px solid #6aa318 !important;
  outline: 0 !important;
  opacity: 1 !important;
}
body .btn-primary:disabled {
  background: #def1c3 0% 0% no-repeat padding-box;
  border: 1px solid #def1c3;
  color: #d2d2d2;
  opacity: 1;
}
body .btn-secondary {
  background: none;
  border: 2px solid #5f5f5f;
  text-align: center;
  font-weight: bold;
  color: #323232;
  opacity: 1;
}
body .btn-secondary:hover {
  background: #5f5f5f 0% 0% no-repeat padding-box;
  border: 2px solid #ffffff;
  border-radius: 8px;
  color: #ffffff;
}
body .btn-secondary:active {
  background: none !important;
  border: 2px solid #323232 !important;
  border-radius: 8px !important;
  color: #5f5f5f !important;
}
body .btn-secondary:disabled {
  background: none;
  border: 2px solid #d2d2d2;
  border-radius: 8px;
  color: #d2d2d2;
}
body .btn-secondary-circle {
  background: none;
  border: 1px solid #5f5f5f;
  text-align: center;
  font-weight: bold;
  color: #323232;
  opacity: 1;
}
body .btn-secondary-circle:hover {
  background: #5f5f5f 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 13px;
  color: #ffffff;
}
body .btn-secondary-circle:active {
  background: none !important;
  border: 1px solid #323232 !important;
  border-radius: 13px !important;
  color: #5f5f5f !important;
}
body .btn-secondary-circle:disabled {
  background: none;
  border: 1px solid #d2d2d2;
  border-radius: 13px;
  color: #d2d2d2;
}
body .btn-other {
  background: none;
  border: 2px solid #2b80ff;
  text-align: center;
  font-weight: bold;
  color: #2b80ff;
  opacity: 1;
}
body .btn-other:hover {
  background: #2b80ff 0% 0% no-repeat padding-box;
  border: 2px solid #ffffff;
  border-radius: 8px;
  color: #ffffff;
}
body .btn-other:active {
  background: none !important;
  border: 2px solid #2b80ff !important;
  border-radius: 8px !important;
  color: #2b80ff !important;
}
body .btn-other:disabled {
  background: none;
  border: 2px solid #d2d2d2;
  border-radius: 8px;
  color: #d2d2d2;
}
body .btn-xs {
  width: 60px;
  height: 34px;
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 0px;
  opacity: 1;
}
body .btn-sm, body .btn-group-sm > .btn, body .CompanyOrgManagement .btn-group .btn-group-sm > .btn-add-member, .CompanyOrgManagement .btn-group body .btn-group-sm > .btn-add-member {
  /*for app*/
  width: 90px;
  height: 38px;
  border-radius: 3px;
  font-size: 14px;
  letter-spacing: 0px;
  opacity: 1;
}
body .btn-lg, body .btn-group-lg > .btn, body .CompanyOrgManagement .btn-group .btn-group-lg > .btn-add-member, .CompanyOrgManagement .btn-group body .btn-group-lg > .btn-add-member {
  /* Layout Properties */
  width: 150px;
  height: 60px;
  border-radius: 8px;
  /* UI Properties */
  font-size: 20px;
  letter-spacing: 0px;
  opacity: 1;
}
body .btn-component-sm {
  width: 90px;
  height: 35px;
  border-radius: 3px;
  font-size: 13px;
  border: 1px solid inherit;
  letter-spacing: 0px;
  opacity: 1;
}
body .list-item-header .item-header {
  height: 25px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Noto Sans";
  display: flex;
  align-items: center;
}
body .list-item {
  cursor: default;
}
body .list-item .item {
  height: 35px;
  font-size: 13px;
  font-family: "Noto Sans";
  display: flex;
  align-items: center;
}
body .list-item .hover-image {
  cursor: pointer;
  display: none;
  z-index: 1;
}
body .list-item:hover .hover-image {
  display: block;
}
body .list-item-with-bg {
  cursor: default;
}
body .list-item-with-bg .item {
  font-size: 13px;
  font-family: "Noto Sans";
  display: flex;
  align-items: center;
}
body .list-item-with-bg .hover-image {
  cursor: pointer;
  display: none;
  z-index: 1;
}
body .list-item-with-bg:hover .item {
  background-color: rgba(0, 0, 0, 0.1019607843);
}
body .list-item-with-bg:hover .hover-image {
  display: block;
}
body .list-item-with-green-bg {
  font-family: "Noto Sans", sans-serif;
  border: 1px solid #757474;
  font-size: 14px;
}
body .list-item-with-green-bg .item {
  display: flex;
  align-items: center;
  cursor: pointer;
}
body .list-item-with-green-bg .item:hover {
  font-weight: bold;
  background-color: #DEF0C4;
}
body .add-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e9e9f0;
  padding: 6px;
  margin-top: 5px;
  margin-bottom: 10px;
}
body .add-area .btn-add {
  display: inline-block;
  width: 15%;
  margin-left: 10px;
  border: 1px solid #bdbdbd;
  background-color: #ffffff;
  border-radius: 4px;
  font-size: 13px;
  opacity: 1;
  color: #323232;
}
body .modal-width-55 {
  max-width: 55%;
}
body .primary-checkBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  color: #323232;
}
body .primary-checkBox .form-check-input {
  align-items: center;
  width: 25px;
  height: 25px;
  border: 2px solid #e2e2e2;
  margin-right: 8px;
}
body .primary-checkBox .form-check-label {
  align-items: center;
}
body .primary-checkBox .form-check-input[type=checkbox] {
  box-shadow: none;
}
body .primary-checkBox .form-check-input:checked[type=checkbox] {
  box-shadow: none;
  background-color: white;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%237FBE25%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e") !important;
}
body .primary-checkBox .form-check-input[type=checkbox]:hover {
  border: 2px solid #7fbe25;
}
body .primary-checkBox .form-check-input:checked[type=checkbox]:hover {
  border: 2px solid #7fbe25;
}
body .form-control:focus {
  background-color: #ffffff !important;
  border-color: #7FBE25 !important;
  box-shadow: 0 0 0px rgba(0, 123, 255, 0.5) !important;
  outline: none;
}
body .MuiRadio-root .Mui-checked {
  color: #6aa318;
}
body .drawer-second-layer-backdrop {
  z-index: 2050 !important;
}
body .drawer-third-layer-backdrop {
  z-index: 2052 !important;
}
body .fix-button-area {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: end;
  border: #E2E2E2;
  border-style: solid;
  border-bottom: none;
  border-left: none;
  border-right: none;
  background-color: #ffffff;
  text-align: center;
  padding: 1.5%;
}
body .image-add {
  display: block;
  transition: opacity 0.3s ease;
}
body .image-add:hover {
  content: url("../../public/assets/images/btn_add_signoff_p.svg");
}

@media screen and (min-width: 900px) {
  .web-content {
    display: block;
  }
  .mobile-content {
    display: none;
  }
}
@media screen and (max-width: 899px) {
  .web-content {
    display: none;
  }
  .mobile-content {
    display: block;
  }
  .btn-primary {
    /* UI Properties */
    background: #7fbe25 0% 0% no-repeat padding-box;
    border: 1px solid #7fbe25;
    text-align: center;
    font-weight: 500;
    color: #323232;
  }
  .btn-primary:hover {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #7fbe25;
    color: #323232;
  }
  .btn-primary:active {
    background: #6aa318 0% 0% no-repeat padding-box;
    border: 1px solid #6aa318;
    outline: 0;
    opacity: 1;
  }
  .btn-primary:disabled {
    background: #def1c3 0% 0% no-repeat padding-box;
    border: 1px solid #def1c3;
    color: #d2d2d2;
    opacity: 1;
  }
  .btn-secondary {
    /* UI Properties */
    background: none;
    border: 1px solid #5f5f5f;
    border-radius: 4px;
    text-align: center;
    font-weight: 500;
    color: #5f5f5f;
    opacity: 1;
  }
  .btn-secondary:hover {
    background: #5f5f5f 0% 0% no-repeat padding-box;
    border: 2px solid #ffffff;
    border-radius: 6px;
    color: #ffffff;
  }
  .btn-secondary:active {
    background: none;
    border: 2px solid #323232;
    border-radius: 6px;
    color: #5f5f5f;
  }
  .btn-secondary:disabled {
    background: none;
    border: 2px solid #d2d2d2;
    border-radius: 6px;
    color: #d2d2d2;
  }
  .btn-other {
    background: none;
    border: 2px solid #2b80ff;
    text-align: center;
    font-weight: bold;
    color: #2b80ff;
    opacity: 1;
  }
  .btn-other:hover {
    background: #2b80ff 0% 0% no-repeat padding-box;
    border: 2px solid #ffffff;
    border-radius: 8px;
    color: #ffffff;
  }
  .btn-other:active {
    background: none !important;
    border: 2px solid #2b80ff !important;
    border-radius: 8px !important;
    color: #2b80ff !important;
  }
  .btn-other:disabled {
    background: none;
    border: 2px solid #d2d2d2;
    border-radius: 8px;
    color: #d2d2d2;
  }
  .btn-xs {
    width: 60px;
    height: 34px;
    border-radius: 4px;
    font-size: 15px;
    letter-spacing: 0px;
    opacity: 1;
  }
  .btn-sm, .btn-group-sm > .btn, .CompanyOrgManagement .btn-group .btn-group-sm > .btn-add-member {
    /*for app*/
    width: 80px;
    height: 36px;
    border-radius: 5px;
    font-size: 13px;
    letter-spacing: 0px;
    opacity: 1;
  }
  .btn-lg, .btn-group-lg > .btn, .CompanyOrgManagement .btn-group .btn-group-lg > .btn-add-member {
    border-radius: 4px;
    width: 90px;
    height: 38px;
    font-size: 14px;
    letter-spacing: 0px;
    opacity: 1;
  }
}/*# sourceMappingURL=all.css.map */