.btn-back-top {
    width: 72px;
    height: 72px;
    position: fixed;
    bottom: 80px;
    right: 14px;
    display: none;
    border: none;
    background: #F0F7E7 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    font-size: 45px;
    font-weight: bolder;
    color: #7FBE25;
    z-index: 5;

    &:hover {
        background: #7FBE25 0% 0% no-repeat padding-box;
        font-size: 50px;
        font-weight: bolder;
        color: #FFFFFF;
    }

    &:active {
        background: #6AA318 0% 0% no-repeat padding-box;
        font-size: 50px;
        font-weight: bolder;
        color: #FFFFFF;
    }

    &:disabled {
        background: #DEF1C3 0% 0% no-repeat padding-box;
        font-size: 50px;
        font-weight: bolder;
        color: #FFFFFF;
    }
}

.btn-edc-logo {
    width: 72px;
    height: 72px;
    position: fixed;
    bottom: 80px;
    left: 46px;
    display: block;
    border: none;
    background: #7FBE25 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    font-size: 11px;
    font-family: Arial;
    white-space: nowrap;
    color: #F3F3F3;
    z-index: 5;
}

/* mobile */
@media screen and (max-width: 960px) {
    .btn-back-top {
        width: 50px;
        height: 50px;
        position: fixed;
        top: 573px;
        right: 21px;
        display: none;
        border: none;
        background: #F0F7E7 0% 0% no-repeat padding-box;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        border-radius: 50px;
        font-size: 30px;
        font-weight: bolder;
        color: #7FBE25;
        z-index: 5;

        &:hover {
            background: #7FBE25 0% 0% no-repeat padding-box;
            font-size: 30px;
            font-weight: bolder;
            color: #FFFFFF;
        }

        &:active {
            background: #6AA318 0% 0% no-repeat padding-box;
            font-size: 30px;
            font-weight: bolder;
            color: #FFFFFF;
        }

        &:disabled {
            background: #DEF1C3 0% 0% no-repeat padding-box;
            font-size: 30px;
            font-weight: bolder;
            color: #FFFFFF;
        }
    }
    
    .btn-edc-logo {
        width: 50px;
        height: 50px;
        position: fixed;
        top: 574px;
        left: 24px;
        display: block;
        border: none;
        background: #7FBE25 0% 0% no-repeat padding-box;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        border-radius: 50px;
        font-size: 8px;
        font-family: Arial;
        white-space: nowrap;
        color: #F3F3F3;
        z-index: 5;
    }
}