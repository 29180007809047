$max-width: 899px;
$min-width: 900px;

.TermsOfService {
    font-family: Noto Sans;
    // min-height: 2000px;
    margin-bottom: 300px;

    .banner {
        width: 100%;
        height: 260px;
        opacity: 90%;
        border: 1px solid #f0f0f0;
        text-align: left;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0px;
        display: flex;
        align-items: center; /*垂直置中*/
        padding: 10% 2% 9% 7%;
        opacity: 1;
    }

    .text-title-qa {
        height: 94px;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 40px;
        font-family: 'Microsoft JhengHei UI', sans-serif;
        letter-spacing: 0px;
        color: #7fbe25;
        opacity: 1;
    }
    .text-qa {
        text-align: left;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        font-family: 'Microsoft JhengHei', sans-serif;
        letter-spacing: 0px;
        color: #323232;
        opacity: 1;
    }
}
