

.RegulatoryCompliance{
    font-family: 'Noto Sans';

    .card{
        min-height: 254px;
        height: auto;
        display: flex;
        justify-content: center;
        text-align: center;

        .text-main-title{
            font-size: 14px;
            font-weight: bold;
            color: #5F5F5F;
            margin-bottom: 12px;
        }

        
        .text-title{
            font-size: 11px;
            color: #5F5F5F;
        }

        .icon{
            width: 59px;
            height: 76px;
            margin-bottom: 15px;
        }

        .btn-primary{
            width: 190px;
            height: 36px;
            font-size: 14px;
            font-weight: bold;
            color: '#323232';
        }
    }
}