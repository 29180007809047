.CompanyMemberManagement{
  width: 100%;
  
  .style-row{
    display: flex;
    padding: 30px 22px;
    justify-content: flex-end; /* 靠右對齊 */
    align-items: center;    /* 垂直居中 */
    
  }

  .btn-group{
    width: 160px;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
    .btn-add-member .btn-invite{
      width: 80px;
      height: 28px;
      font-size: 12px;
      color: #323232;
    }
  }

  .link-area{
    padding: 0 0 20px 0;
    .text-link{
      color: #1D1D1D;
      font-size: 20px;
      font-weight: bold;
      font-family: "Source Sans Pro", sans-serif;
      text-decoration: none;
    }
  }
}

.CompanyMemberManagement.InviteModal{
  
  .modal-header {
    .modal-title {
      font-size: 14px;
      color: #323232;
      font-weight: bold;
    }
  }
  .form{
    padding: 0 0 0 0;
    .spin{
      .modal-body {
        padding: 0px 24px 160px 24px;

        .form-title {
          font-size: 12px;
          color: #323232;
          font-weight: bold;
          margin-top: 20px;
          margin-bottom: 6px;
        }
        .input-field {
          height: 80px;
          font-weight: normal !important;
          font-size: 11px;
        }
        .text-danger.input-field {
          font-size: 12px !important;
          padding: 0;
          margin: 0;
        }
        .add-area {
          display: flex;
          align-items: center;
          background-color: #e9e9f0;
          padding: 2% 3%;
          margin-top: 5px;
          margin-bottom: 10px;
      
          .btn-add {
            display: inline-block;
            width: 20%;
            height: 100%;
            border: 1px solid #bdbdbd;
            background-color: #ffffff;
            border-radius: 4px;
            font-size: 12px;
            opacity: 1;
            color: #323232;
          }
        }
      }
    } 
    .modal-footer {
      .btn-primary{
        width: 94px;
        height: 36px;
        font-size: 14px;
      }
    }
  }
}

.CompanyMemberManagement.EditModal{
  max-width: 45%;
    .modal-header {
      .modal-title {
        font-size: 14px;
        color: #323232;
        font-weight: bold;
      }
    }
    .modal-body {
      padding: 0 0 0 0;
      margin-bottom: 10%;
      .form {
        padding: 0 20px 20px 20px;
        // .spin{
        .form-title {
          font-size: 12px;
          color: #323232;
          font-weight: bold;
          margin-top: 20px;
          margin-bottom: 0px;
        }
        .input-field {
          font-weight: normal !important;
          font-size: 11px;
        }
        .text-danger.input-field {
          font-size: 12px !important;
          padding: 0;
          margin: 0;
        }
  
        .form-select {
          width: 50%;
          font-size: 10px;
          color: #323232;
          border-radius: 10%;
        }
        // }
      }
      .list-area {
        padding: 20px 25px;
        margin-bottom: 10%;
  
        .list-item {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          cursor: default;
          .hover-image {
            cursor: pointer;
            display: none;
            z-index: 1;
          }
          &:hover {
            background-color:  #E2E2E2;
          }
          &:hover .hover-image {
            display: block;
          }
        }
      }
      .add-area {
        background-color: #e9e9f0;
        padding: 2% 1% 2% 3%;
        margin-top: 10px;
        margin-bottom: 20px;
  
        .btn-add {
          width: 100%;
          height: 100%;
          border: 1px solid #bdbdbd;
          background-color: #ffffff;
          border-radius: 4px;
          font-size: 12px;
          opacity: 1;
          color: #323232;
        }
      }
    }
    .modal-footer {
      padding: 2% 4% 3% 4%;
      .btn {
        width: 90px !important;
        height: 36px !important;
        text-align: center !important;
        font-size: 13px;
      }
      .btn-secondary {
        margin-right: 10px;
      }
    }
  }

.CompanyMemberManagement.AddModal{
    .modal-header {
      .modal-title {
        font-size: 14px;
        color: #323232;
        font-weight: bold;
      }
    }
  
    .form {
      padding: 0 0 0 0;
      .spin {
        .modal-body {
          padding: 0 20px 20px 20px;
          .form-title {
            font-size: 12px;
            color: #323232;
            font-weight: bold;
            margin-top: 20px;
            margin-bottom: 0px;
          }
          .input-field {
            font-weight: normal !important;
            font-size: 11px;
          }
          .text-danger.input-field {
            font-size: 12px !important;
            padding: 0;
            margin: 0;
          }
  
          .form-select {
            width: 50%;
            font-size: 10px;
            color: #323232;
            border-radius: 10%;
          }
        }
      }
  
      .modal-footer {
        padding: 2% 4% 3% 4%;
        .btn {
          width: 90px !important;
          height: 36px !important;
          text-align: center !important;
          font-size: 13px;
        }
        .btn-secondary {
          margin-right: 10px;
        }
      }
    }
  }
  .CompanyMemberManagement.DeleteModal{
    .modal-header {
      .modal-title {
        font-size: 14px;
        color: #323232;
        font-weight: bold;
      }
    }
    .modal-body {
      padding: 0px 24px 30px 24px;
      font-size: 13px;
    }
    .modal-footer {
      padding: 2% 4% 3% 4%;
      .btn {
        width: 90px !important;
        height: 36px !important;
        text-align: center !important;
        font-size: 13px;
      }
      .btn-secondary {
        margin-right: 10px;
      }
    }
  }

