.skycloud{
    .intro{
        padding:70px 90px 70px 90px;
        .text{
            padding-right:50px;
            .text-title{
                font-size: 30px;
                font-weight: bolder;
                text-align: left;
            }
            .text-desc{
                font-size: 15px;
                text-align: left;
            }
        }
    }
    .service{
        .content{
            
            padding:20px 70px 130px 90px;
            .content-box{
                width:30%;
                margin-right: 20px;
                border-top:8px solid #2B80FF;
                p{
                    font-size: 15px;
                    margin-left: -10px;
                }
            }
        }
    }
    .img-slider{
        .carousel-control-prev{
            top: 45%;
            height: 50px;
            width: 50px;
            left: 5%;
        }
            .carousel-control-next{
                top: 45%;
                    height: 50px;
                    width: 50px;
                    right: 5%;
            }
        .carousel-control-prev-icon{
            background-image: url('../../public/assets/images/btn_back_w_carousels_n.svg');
        }
        .carousel-control-next-icon {
            background-image: url('../../public/assets/images/btn_next_w_carousels_n.svg');
        }
    
        /* Custom styles for hover state */
        .carousel-control-prev-icon:hover{
            background-image: url('../../public/assets/images/btn_back_g_carousels_n.svg');        }
        .carousel-control-next-icon:hover {
            background-image: url('../../public/assets/images/btn_next_g_carousels_n.svg');
        }
    }
    .pros{
        padding: 90px 90px 90px 90px;
        .content{
            padding-top:50px;
            p{
                padding-top: 10px;
                font-size: 15px;
            }
            b{
                font-size: 18px;
                color: #045FD8;
            }
        }
    }
    .profit{
        background-color:#000000;
        padding: 70px 200px 70px 200px;
        b{
            color: #FFFFFF;
        }
    }
.scene {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
    margin-bottom: 4vh;
    .text-title {
            text-align: center;
            margin-top: 5vh;
            margin-bottom: 5vh;
        }
    
        .col-swiper {
            padding: 1% 7%;
    
            .swiper{
                padding-bottom: 1%;
                width: 100%;
                .swiper-pagination{
                    display:none;
                }
            }
    
            ::part(pagination) {
                display: none !important;
            }
        }
    
        .nav-pag {
            display: flex;
            justify-content: center;
    
            .btn-primary {
                background-color: rgba(255, 255, 255, 0) !important;
                border-color: rgba(255, 255, 255, 0) !important;
            }
    
            .btn-primary:hover {
                background-color: rgba(255, 255, 255, 0) !important;
                border-color: rgba(255, 255, 255, 0) !important;
            }
    
            .button_next,
            .button_prev {
                width: fit-content;
                height: fit-content;
    
                .button-next,
                .button-prev {
                    background-size: 100%;
                    background-repeat: no-repeat;
                    width: 30px;
                    height: 40px;
                }
    
                .button-next {
                    background-image: url('../../public/assets/images/btn_next_g_carousels_n.svg');
                }
    
                .button-next:disabled {
                    background-image: url('../../public/assets/images/btn_next_w_carousels_n.svg');
                }
    
                .button-prev {
                    background-image: url('../../public/assets/images/btn_back_g_carousels_n.svg');
                }
    
                .button-prev:disabled {
                    background-image: url('../../public/assets/images/btn_back_w_carousels_n.svg');
                }
            }
    
            .pags {
                padding-top: 1%;
                width: fit-content;
                height: fit-content;
    
                .pagination {
                    width: fit-content !important;
                    height: 5px !important;
    
                    .page-item {
                        margin-right:5px;
                        margin-left: 5px;
                        width: 20px !important;
                        height: 5px !important;
                    }
    
                    .page-link {
                        width: 20px !important;
                        height: 5px !important;
                        border-radius: 0% !important;
                        border: none !important;
                        padding-bottom: 0% !important;
                        background-color: #e9e9e9 !important;
                    }
    
                    .page-link.active,
                    .active>.page-link {
                        background-color: #7fbe25 !important;
                    }
                }
            }
        }
}


    .promotion{
        .firstrow {
            padding: 30px 90px 50px 90px;
            background-image: linear-gradient(#F5F9FC, #F5F9FC);
            background-size: 37% 100%;
                background-repeat: no-repeat;
            .title {
                    font-size: 30px;
                    padding-bottom: 50px;
                }
        }
                .secrow {
                    padding: 40px 90px 100px 180px;
                    background-image: linear-gradient(#F5F9FC, #F5F9FC);
                    background-size: 10px 100%;
                    background-size: 23% 60%;
                        background-position: right top;
                        background-repeat: no-repeat;
                    .title {
                        font-size: 30px;
                        padding-bottom: 50px;
                    }
                }
    }
}