.Cloudbackup {
    height: auto;

    .main-intro {
        height: auto;
        padding-top: 8%;
        padding-bottom: 4%;
        background: transparent linear-gradient(to right, #e0edf5, #ffffff, #e4f2f2);

        .text-intro {
            padding-left: 6%;
            padding-top: 1%;
            b {
                font-size: 2.2rem;
            }

            p {
                font-size: 15px;
                font-family: 'Microsoft JhengHei', sans-serif;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .btn-secondary {
                width: 120px;
                height: 44px;
            }
        }
    }
    .backup-service {
        padding: 8% 5%;

        .text-service {
            padding-left: 7%;

            .text-desc {
                font-size: 18px;
                font-family: Microsoft JhengHei;
                color: #5f5f5f;
            }

            li {
                list-style-type: none;
                p {
                    font-size: 15px;
                    font-family: Microsoft JhengHei;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }

            .btn-allservice {
                font-size: 20px;
                font-weight: bold;
                text-decoration: none;
            }
        }
    }
    .security-posture {
        padding: 2% 6% 3% 6%;
        margin: 3% 0%;
        text-align: center;
        align-items: center;
        .img-security-posture {
            padding: 5% 0% 4% 0%;
        }
        .text-security-posture {
            padding-left: 4%;
            .text-title {
                margin-left: 5%;
                font-size: 17px;
                font-weight: bold;
                text-align: left;
            }

            .text-desc {
                margin-left: 5%;
                font-size: 17px;
                text-align: left;
                font-family: 'Microsoft JhengHei', sans-serif;
                color: #323232;
            }

            li {
                list-style-type: none;
                margin-left: 5%;
                p {
                    font-weight: bold;
                    text-align: left;
                    font-size: 16px;
                    font-family: 'Microsoft JhengHei', sans-serif;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .backup-support {
        padding: 5% 5%;
        .text-support {
            text-align: center;

            b {
                display: inline-block;
                text-align: center;
            }
        }

        .card-title {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 12px;
            font-family: 'Source Sans Pro', sans-serif;
            letter-spacing: 0px;
            color: #213308;
            opacity: 1;
        }
        .card-text {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            font-family: 'Microsoft JhengHei', sans-serif;
            letter-spacing: 0px;
            color: #5f5f5f;
            opacity: 1;
        }

        .text-service {
            padding-left: 7%;

            .text-title {
                margin-bottom: 10px;
            }

            .text-desc {
                font-size: 18px;
                font-family: Microsoft JhengHei;
                color: #323232;
            }

            li {
                list-style-type: none;
                p {
                    font-size: 15px;
                    font-family: Microsoft JhengHei;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }

            .btn-allservice {
                font-size: 20px;
                font-weight: bold;
                text-decoration: none;
            }
        }
    }

    .subscr-plan {
        background: #f5f9fc;
        padding-bottom: 10%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;

        .text-title {
            margin-top: 3%;
            margin-bottom: 3%;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
        }
    }
}
