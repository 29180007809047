.LinkSelect{
    padding-top:3% !important;

    .modal-content{
        width: 110% !important;
        height: 80% !important;
        padding-left: -5%;
        .modal-header{
            .modal-title{
            font-size: 20px;
            color: #323232 ;
            font-weight: bold;
            }
        }
    }
    .modal-body{
        padding:2% 4% 0% 4%;
        .form{
                padding-left: 2.5%;
                .form-title{
                    padding-left: 0%;
                    padding-bottom:1%;
                    font-size: 15px;
                    color: #323232;
                    font-weight: bold;
                }
                .form-select{
                    width:50%;
                    font-size: 15px;
                    color: #323232;
                    border-radius: 10%;
                }
                

            }
        .submenu{
            height: 300px;
            padding:5% 3% 5% 1%;
            .sub-title{
                .list-group{
                    padding-top:7%;
                    font-size: 12px;
                        color: #323232;
                        font-weight: bold;
                        width: 108% !important;
                    .list-group-item{
                        border-radius: 0%;
                        width: 100% !important;
                            border: 0px !important;
                    }
                    .list-group-item[aria-selected="true"],
                    .list-group-item:hover {
                        background-color: #DEF0C4 !important;
                        color: #323232 !important;
                        font-weight: bold !important;
                    }
                    
                }
            }
            .sub-option{
                background:#F9F9F9;
                justify-content: left!important;
                .form-check{
                    margin-top:5%;
                    margin-left:5%;
                    font-size: 12px;
                        color: #323232;
                        font-weight: bold;
                    .form-check-input{
                        font-size:20px;
                        border:#323232 10px;
                    }
                    .form-check-label{
                        padding-top:10%;
                    }
                    .form-check-input:checked[type=checkbox]{
                        background-color: white;
                        --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%237FBE25%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e")!important;
                    }
                    .form-check-input[type=checkbox] {
                        border: 2px solid #E2E2E2;
                        border-radius: 3px;
                    }

                    
                }
            }
        }
        .option-selected{
            
            .badge{
                font-size: 12px;
                color:#323232!important;
                background: #E9E9E9!important;
                font-size: 12px;
                border-radius: 100%;
                width:max-content;
                height: fit-content;
                margin-left: 3%;
                margin-bottom: 2%;
                padding: 1% 2% 1% 2% !important;
                align-items: center!important;
                .btn-close{
                    margin-left:5%;
                    // width:2%!important;
                    // height: 2%!important;
                }
            }
        }
    }
    .modal-footer{
        padding: 3% 4% 5% 4%;
            .btn{
                width: 100px !important;
                height:40px !important;
                text-align: center!important;
                font-size:14px;
                border-radius: 10%;
            }
            .btn-secondary{
                margin-right: 5%;
            }

    }
}