.CompanyOrgManagement {
  width: 100%;

  .style-row {
    display: flex;
    padding: 30px 22px;
    justify-content: flex-end; 
    align-items: center; 
  }

  .btn-group {
    width: 160px;
    border: 1px solid #bdbdbd;
    border-radius: 4px;
    .btn {
      width: 80px;
      height: 28px;
      font-size: 12px;
      color: #323232;
    }
    
    .btn-add-member {
      @extend .btn;
    }
  }

  .link-area {
    padding: 0 0 20px 0;
    .text-link {
      color: #1d1d1d;
      font-size: 20px;
      font-weight: bold;
      font-family: 'Source Sans Pro', sans-serif;
      text-decoration: none;
    }
  }
}

.CompanyOrgManagement.AddModal {
  .modal-header {
    .modal-title {
      font-size: 14px;
      color: #323232;
      font-weight: bold;
    }
  }

  .form {
    padding: 0 0 0 0;
    .spin {
      .modal-body {
        padding: 0 20px 20px 20px;
        margin-bottom: 30%;
        .form-control.custom-select {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'%3E%3Cpath fill='%236c757d' d='M5 6L0 0h10L5 6z'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right 0.75rem center;
          background-size: 0.65rem 0.65rem;
          padding-right: 1.5rem;
        }

        .text-danger.input-field {
          font-size: 12px !important;
          padding: 0;
          margin: 0;
        }

        .form-select {
          width: 50%;
          font-size: 10px;
          color: #323232;
          border-radius: 10%;
        }
      }
    }

    .modal-footer {
      padding: 2% 4% 3% 4%;
      .btn {
        width: 90px !important;
        height: 36px !important;
        text-align: center !important;
        font-size: 13px;
      }
      .btn-secondary {
        margin-right: 10px;
      }
    }
  }
}

.CompanyOrgManagement.UpdateOrgDrawer {
  .offcanvas-header {
    padding: 10px 20px;
    .text-title {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .offcanvas-body {
    padding: 0 0 0 0;
    .tab-1 {
      padding: 20px 10px 20px 15px;
      display: flex;
      align-items: center;

      .btn-org-member {
        width: 105px;
        height: 30px;
        font-size: 13px;
        color: #323232;
        background: none;
        border: 1.5px solid #bdbdbd;
        border-radius: 4px;
        text-align: center;
        opacity: 1;

        &:hover {
          background: #5f5f5f 0% 0% no-repeat padding-box;
          border: 1.5px solid #ffffff;
          border-radius: 4px;
          color: #ffffff;
        }

        &:active {
          background: none;
          border: 1.5px solid #323232;
          border-radius: 4px;
          color: #5f5f5f;
        }
      }
    }
    .tab-2 {
      padding: 0px 24px;
      display: flex;
      align-items: center;

      .form {
        height: 100%;

        .form-control.custom-select {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'%3E%3Cpath fill='%236c757d' d='M5 6L0 0h10L5 6z'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right 0.75rem center;
          background-size: 0.65rem 0.65rem;
          padding-right: 1.5rem;
        }

        .text-danger.input-field {
          font-size: 12px !important;
          padding: 0;
          margin: 0;
        }

        .form-select {
          width: 50%;
          font-size: 10px;
          color: #323232;
          border-radius: 10%;
        }
      }
    }
  }
}


.CompanyOrgManagement.AddMemberToOrgModal {
  max-width: 70%;
  .header{
    padding: 15px 10px 5px 15px;
    .text-title{
      font-size: 14px;
      font-weight: bold;
    }
  }
  .body{
    max-height: 70vh; 
    .row{
      padding: 0px;
    }
  }
  

}
  
