.HealthAlertModal {
    padding-top: 3% !important;
    width: 60%;
    min-height: 500px;
    height: auto;

    .modal-content {
        width: 110% !important;
        height: 80% !important;
        padding-left: -5%;

        .modal-header {
            .modal-title {
                font-size: 16px;
                color: #323232;
                font-weight: bold;
            }
        }
    }
    .modal-body {
        .form {
            padding: 0 2.5%;
            .form-title {
                font-size: 14px;
                color: #323232;
                font-weight: bold;
                margin-top: 15px;
                margin-right: 10px;
            }
            .input-field {
                font-weight: normal !important;
            }
            .text-danger.input-field {
                font-size: 12px !important;
                padding: 0;
                margin: 0;
            }

            .form-select {
                width: 50%;
                font-size: 10px;
                color: #323232;
                border-radius: 10%;
            }
        }
    }
    .modal-footer {
        padding: 3% 4% 5% 4%;
        .btn {
            width: 100px !important;
            height: 40px !important;
            text-align: center !important;
            font-size: 14px;
        }
        .btn-secondary {
            margin-right: 5%;
        }
    }
}

.custom-select {
    border-radius: 4px !important;
    border-color: #e2e2e2 !important; /* Default border color */
}
.custom-select:hover {
    box-shadow: none !important;
    border-width: 1px !important; /* sets the width of the border */
    border-style: solid !important; /* sets the style of the border to solid */
    border-color: #7fbe25 !important; /* sets the color of the border using a hex code */
}
.custom-select:focus {
    box-shadow: none !important;
    border-width: 1px !important; /* sets the width of the border */
    border-style: solid !important; /* sets the style of the border to solid */
    border-color: #6aa318 !important; /* sets the color of the border using a hex code */
}
