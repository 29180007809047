@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  body,
  html,
  #root {
    @apply tw-text-main-text tw-h-full;
  }

  ::-webkit-scrollbar {
    @apply tw-h-4 tw-w-2;
  }

  ::-webkit-scrollbar:horizontal {
    @apply tw-h-4 tw-w-2;
  }

  ::-webkit-scrollbar-track {
    @apply tw-bg-transparent tw-rounded;
  }

  ::-webkit-scrollbar-thumb {
    @apply tw-bg-primary-blue/50 tw-border tw-border-white tw-rounded;
  }
}

@layer components {
  .spinner-child {
    @apply tw-absolute -tw-top-1 tw-left-0 tw-h-full tw-w-full tw-content-[''] tw-rounded-full tw-bg-primary-orange tw-opacity-0;
  }

  .markdown-container p {
    @apply tw-mb-5;
  }

  .markdown-container h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply tw-font-bold tw-mb-5;
  }

  .markdown-container li {
    @apply tw-mb-3;
  }

  .markdown-container li:last-child {
    @apply tw-mb-0;
  }

  .markdown-container ol {
    @apply tw-list-decimal tw-mx-0 tw-mt-0 tw-mb-5 tw-pl-8;
  }

  .markdown-container ul {
    @apply tw-list-disc tw-mx-0 tw-mt-0 tw-mb-5 tw-pl-8;
  }

  .markdown-container> :last-child {
    @apply tw-mb-0;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
