@import 'bootstrap/scss/bootstrap';
@import 'appHeader';
@import 'appLoginHeader';
@import 'appFooter';
@import 'appMenu';
@import 'appLoginMenu';
@import 'appLoginSidebar';

@import 'azureperformance';
@import 'layerDrawer';
@import 'portal';
@import 'login';
@import 'forgetpwd';
@import 'simplePriceComparison';
@import 'latencyMap';
@import 'latencyTesting';
@import 'resourceassetconfiguration';
@import 'allservices';
@import 'autoProcess';
@import 'console';
@import 'employeeschedule';
@import 'dailycost';
@import 'classification';
@import 'pageintroTemplate';
@import 'flowza';
@import 'cloudgoda';
@import 'smartmanagement';
@import 'cloudbackup';
@import 'errorPage';
@import 'cloudsecurity';
@import 'godamall';
@import 'subscription';
@import 'skycloud';
@import 'pentiumnetwork';
@import 'operationexcellent';
@import 'securityposture';
@import 'securityscore';
@import 'designanddeploy';
@import 'recommendationsDetailDrawer';
@import 'regulatoryCompliance';
@import 'reportSubscribe';
@import 'activeserviceissue';
@import 'healthalert';
@import 'cloudgodaQA';
@import 'termsofservice';
@import 'contactUs';
@import 'companyBaseInfo';
@import 'companyMemberManagement';
@import 'companyOrgManagement';
@import 'processManagement';
@import 'teamsAppKey';
@import 'companyWorkingDay';
@import 'vmSpecDetails';
@import 'companyManagement';
@import 'companyWizard';
@import 'component/linkselect';
@import 'component/healthalertmodal';
@import 'component/linkicon';
@import 'component/addlinkicon';
@import 'component/subscriptionplan';
@import 'component/godabenefit';
@import 'component/cspmmainfunction';
@import 'component/languageSelector';
@import 'component/companycard';

@import 'component/appMenuDropdown';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&family=Source+Sans+3:wght@400;700;800&display=swap');

@font-face {
  font-family: 'Noto Sans';
  src: url('../../public/assets/fonts/Noto-Sans-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

$color: #ffffff;
$max-width: 899px;
$min-width: 900px;

body {
  background-color: $color;
  font-family: 'Noto Sans', sans-serif !important;

  .custom-datetime input {
    font-size: 14px;
  }

  .custom-datetime .rdtPicker td {
    font-size: 14px;
  }

  .btn-primary {
    background: #7fbe25 0% 0% no-repeat padding-box;
    border: 1px solid #7fbe25;
    text-align: center;
    font-weight: bold;
    color: #323232;

    &:hover {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 2px solid #7fbe25;
      color: #323232;
    }

    &:active {
      background: #6aa318 0% 0% no-repeat padding-box !important;
      border: 1px solid #6aa318 !important;
      outline: 0 !important;
      opacity: 1 !important;
    }

    &:disabled {
      background: #def1c3 0% 0% no-repeat padding-box;
      border: 1px solid #def1c3;
      color: #d2d2d2;
      opacity: 1;
    }
  }

  .btn-secondary {
    background: none;
    border: 2px solid #5f5f5f;
    text-align: center;
    font-weight: bold;
    color: #323232;
    opacity: 1;

    &:hover {
      background: #5f5f5f 0% 0% no-repeat padding-box;
      border: 2px solid #ffffff;
      border-radius: 8px;
      color: #ffffff;
    }

    &:active {
      background: none !important;
      border: 2px solid #323232 !important;
      border-radius: 8px !important;
      color: #5f5f5f !important;
    }

    &:disabled {
      background: none;
      border: 2px solid #d2d2d2;
      border-radius: 8px;
      color: #d2d2d2;
    }

  }

  .btn-secondary-circle {
    background: none;
    border: 1px solid #5f5f5f;
    text-align: center;
    font-weight: bold;
    color: #323232;
    opacity: 1;

    &:hover {
      background: #5f5f5f 0% 0% no-repeat padding-box;
      border: 1px solid #ffffff;
      border-radius: 13px;
      color: #ffffff;
    }

    &:active {
      background: none !important;
      border: 1px solid #323232 !important;
      border-radius: 13px !important;
      color: #5f5f5f !important;
    }

    &:disabled {
      background: none;
      border: 1px solid #d2d2d2;
      border-radius: 13px;
      color: #d2d2d2;
    }

  }

  .btn-other {
    background: none;
    border: 2px solid #2b80ff;
    text-align: center;
    font-weight: bold;
    color: #2b80ff;
    opacity: 1;
    &:hover {
      background: #2b80ff 0% 0% no-repeat padding-box;
      border: 2px solid #ffffff;
      border-radius: 8px;
      color: #ffffff;
    }
    &:active {
      background: none !important;
      border: 2px solid #2b80ff !important;
      border-radius: 8px !important;
      color: #2b80ff !important;
    }
    &:disabled {
      background: none;
      border: 2px solid #d2d2d2;
      border-radius: 8px;
      color: #d2d2d2;
    }
  }

  .btn-xs {
    width: 60px;
    height: 34px;
    border-radius: 4px;
    font-size: 16px;
    letter-spacing: 0px;
    opacity: 1;
  }

  .btn-sm {
    /*for app*/
    width: 90px;
    height: 38px;
    border-radius: 3px;
    font-size: 14px;
    letter-spacing: 0px;
    opacity: 1;
  }

  .btn-lg {
    /* Layout Properties */
    width: 150px;
    height: 60px;
    border-radius: 8px;
    /* UI Properties */
    font-size: 20px;
    letter-spacing: 0px;
    opacity: 1;
  }

  .btn-component-sm {
    width: 90px;
    height: 35px;
    border-radius: 3px;
    font-size: 13px;
    border: 1px solid inherit;
    letter-spacing: 0px;
    opacity: 1;
  }

  .list-item-header {
    .item-header {
      height: 25px;
      font-size: 14px;
      font-weight: bold;
      font-family: 'Noto Sans';
      display: flex;
      align-items: center;
    }
  }
  .list-item {
    cursor: default;
    .item {
      height: 35px;
      font-size: 13px;
      font-family: 'Noto Sans';
      display: flex;
      align-items: center;
    }
    .hover-image {
      cursor: pointer;
      display: none;
      z-index: 1;
    }
    &:hover .hover-image {
      display: block;
    }
  }

  .list-item-with-bg {
    cursor: default;
    .item {
      font-size: 13px;
      font-family: 'Noto Sans';
      display: flex;
      align-items: center;
    }
    .hover-image {
      cursor: pointer;
      display: none;
      z-index: 1;
    }
    &:hover .item {
      background-color: #0000001a;
    }
    &:hover .hover-image {
      display: block;
    }
  }

  .list-item-with-green-bg {
    font-family: 'Noto Sans', sans-serif;
    border: 1px solid #757474;
    font-size: 14px;

    .item {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        font-weight: bold;
        background-color: #DEF0C4; 
      }
    }
  }

  .add-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e9e9f0;
    padding: 6px;
    margin-top: 5px;
    margin-bottom: 10px;

    .btn-add {
      display: inline-block;
      width: 15%;
      margin-left: 10px;
      border: 1px solid #bdbdbd;
      background-color: #ffffff;
      border-radius: 4px;
      font-size: 13px;
      opacity: 1;
      color: #323232;
    }
  }

  .modal-width-55 {
    max-width: 55%;
  }

  .primary-checkBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    color: #323232;

    .form-check-input {
      align-items: center;
      width: 25px;
      height: 25px;
      border: 2px solid #e2e2e2;
      margin-right: 8px;
    }

    .form-check-label {
      align-items: center;
    }
    .form-check-input[type='checkbox'] {
      box-shadow: none;
    }
    .form-check-input:checked[type='checkbox'] {
      box-shadow: none;
      background-color: white;
      --bs-form-check-bg-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%237FBE25%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e') !important;
    }
    .form-check-input[type='checkbox']:hover {
      border: 2px solid #7fbe25;
    }
    .form-check-input:checked[type='checkbox']:hover {
      border: 2px solid #7fbe25;
    }
  }

  .form-control {
    &:focus {
    background-color: #ffffff !important;  
    border-color: #7FBE25 !important;     
    box-shadow: 0 0 0px rgba(0, 123, 255, 0.5) !important; 
    outline: none;
    }
  }

  .MuiRadio-root {
    .Mui-checked {
      color: #6aa318;
    }
  }


  .drawer-second-layer-backdrop {
    z-index: 2050 !important;
  }

  .drawer-third-layer-backdrop {
    z-index: 2052 !important;
  }

  .fix-button-area {
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: end;
    border: #E2E2E2;
    border-style: solid;
    border-bottom: none;
    border-left: none;
    border-right: none;
    background-color: #ffffff;
    text-align: center;
    padding: 1.5%;
  }


  .image-add {
    display: block;
    transition: opacity 0.3s ease;
  }
  
  .image-add:hover {
    content: url('../../public/assets/images/btn_add_signoff_p.svg');
  }
}

@media screen and (min-width: $min-width) {
  .web-content {
    display: block;
  }

  .mobile-content {
    display: none;
  }
}

@media screen and (max-width: $max-width) {
  .web-content {
    display: none;
  }
  .mobile-content {
    display: block;
  }

  .btn-primary {
    /* UI Properties */
    background: #7fbe25 0% 0% no-repeat padding-box;
    border: 1px solid #7fbe25;
    // border-radius: 8px;
    text-align: center;
    font-weight: 500;
    color: #323232;

    &:hover {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 2px solid #7fbe25;
      color: #323232;
    }

    &:active {
      background: #6aa318 0% 0% no-repeat padding-box;
      border: 1px solid #6aa318;
      outline: 0;
      opacity: 1;
    }

    &:disabled {
      background: #def1c3 0% 0% no-repeat padding-box;
      border: 1px solid #def1c3;
      color: #d2d2d2;
      opacity: 1;
    }
  }

  .btn-secondary {
    /* UI Properties */
    background: none;
    border: 1px solid #5f5f5f;
    border-radius: 4px;
    text-align: center;
    font-weight: 500;
    color: #5f5f5f;
    opacity: 1;

    &:hover {
      background: #5f5f5f 0% 0% no-repeat padding-box;
      border: 2px solid #ffffff;
      border-radius: 6px;
      color: #ffffff;
    }

    &:active {
      background: none;
      border: 2px solid #323232;
      border-radius: 6px;
      color: #5f5f5f;
    }

    &:disabled {
      background: none;
      border: 2px solid #d2d2d2;
      border-radius: 6px;
      color: #d2d2d2;
    }
  }

  .btn-other {
    background: none;
    border: 2px solid #2b80ff;
    text-align: center;
    font-weight: bold;
    color: #2b80ff;
    opacity: 1;
    &:hover {
      background: #2b80ff 0% 0% no-repeat padding-box;
      border: 2px solid #ffffff;
      border-radius: 8px;
      color: #ffffff;
    }
    &:active {
      background: none !important;
      border: 2px solid #2b80ff !important;
      border-radius: 8px !important;
      color: #2b80ff !important;
    }
    &:disabled {
      background: none;
      border: 2px solid #d2d2d2;
      border-radius: 8px;
      color: #d2d2d2;
    }
  }

  .btn-xs {
    width: 60px;
    height: 34px;
    border-radius: 4px;
    font-size: 15px;
    letter-spacing: 0px;
    opacity: 1;
  }

  .btn-sm {
    /*for app*/
    width: 80px;
    height: 36px;
    border-radius: 5px;
    font-size: 13px;
    letter-spacing: 0px;
    opacity: 1;
  }

  .btn-lg {
    border-radius: 4px;
    width: 90px;
    height: 38px;
    font-size: 14px;
    letter-spacing: 0px;
    opacity: 1;
  }
}
