$max-width: 899px;
$min-width: 900px;

.ContactUs {
    font-family: Noto Sans;
    margin-bottom: 100px;

    .banner {
        width: 100%;
        height: 260px;
        opacity: 90%;
        border: 1px solid #f0f0f0;
        text-align: left;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0px;
        display: flex;
        align-items: center; /*垂直置中*/
        padding: 10% 2% 9% 7%;
        opacity: 1;
    }

    .contact-us-form {
        background-color: #f5f9fc;
        padding-left: 150px;
        padding-right: 150px;
        padding-top: 116px;
        padding-bottom: 138px;

        .form-row {
            margin-bottom: 48px;
        }

        .form-group {
            margin-bottom: 15px;
            .required-asterisk {
                color: red;
            }

            .form-label {
                font-weight: bold;
                color: #333;
            }

            .form-control {
                background-color: #f8f8f8;
                border: 1px solid #ccc;
                padding: 10px;

                &:focus {
                    border-color: #7fbe25;
                    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); /* Change RGBA to match your green color */
                }
            }

            .form-check {
                margin-top: 10px;

                .form-check-label {
                    font-size: 14px;
                }
                .form-check-input {
                    cursor: pointer;
                    &:checked {
                        border: #7fbe25;
                        background-color: #7fbe25;
                    }
                }
                .form-check-input:hover {
                    border-width: 2px;
                    border-style: solid;
                    border-color: #7fbe25;
                }
            }
        }
        .issue-type-selector {
            select {
                padding: 5px 10px;
                opacity: 1;
                width: 232px;
                cursor: pointer;
                background: #ffffff no-repeat padding-box;
                border-radius: 4px;
                letter-spacing: 0px;
                color: #323232;

                &:hover {
                    border-color: #7fbe25;
                }

                &:focus {
                    box-shadow: #7fbe25;
                    border-color: #7fbe25;
                }
                &:active {
                    box-shadow: #7fbe25;
                    border-color: #7fbe25;
                }

                option {
                    // color: #7fbe25;
                    height: 100px;
                    padding: 5px 10px;
                    &:hover {
                        background-color: #7fbe25;
                    }
                }
                option:hover {
                    background-color: #7fbe25;
                }
            }
        }

        textarea.form-control {
            height: auto; // Overrides the fixed height for textareas
            padding: 15px;
        }

        .submit-button {
            background-color: #007bff;
            color: white;
            padding: 10px 20px;
            border-radius: 5px;
            border: 1px solid #007bff;

            &:hover {
                background-color: #0056b3;
                border-color: #0056b3;
            }
        }
    }
}


