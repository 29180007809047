.AzurePerformance {
.holder {
        width: 100%;
        height: 100%;
        position: relative;
            .grafana {
                    width: 97%;
                    height: 700px;
                    border: none;
                    padding: 0;
                    margin-top: 0;
                    padding-left: 30px;
                    transform: scale(1.1);
                    background-color: white;
                }
            
                .bar {
                    margin-top: -50px;
                    margin-left: -50px;
                    position: absolute;
                    top: 0;
                    width: 105%;
                    height: 100px;
                    /* Adjust height as needed */
                    background-color: white;
                }
            
                .selectgroup {
                    padding-top: 2%;
                    width: 30%;
                    position: absolute;
                    top: 0;
                    margin-top: -1.5%;
                    margin-left: 70.5%;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 5s ease-in-out, visibility 0s 5s;
                        
                        .col{
                            margin-right: 10px;
                        }
                }
    }


}