$max-width: 390px;
$min-width: 900px;

$right-accordion-button-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;
$left-accordion-button-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
$accordion-icon-transform1:   rotate(-180deg) !default;


@media screen and (min-width:$min-width){
    .AppHeader {
        .nav-area {
            display: flex;
            align-items: center;
            height: 70px;
            margin: 0 0 0 0;
            padding: 0 0 0 0;
        }
    }
    
}

@media screen and (max-width:$max-width){
    // .AppHeader {
    //     height: 56px;

    //     .login {
    //         display: none;
    //     }

    //     .navbar-toggler {
    //         border: none;
    //         font-weight: bold;
    //         padding-left: 0; padding-right: 0;
    //     }

    //     .accordion-link{
    //         text-decoration: none;
    //         font-weight:normal ;
    //     }

    //     .accordion{
    //         /* Layout Properties */
    //         width: 390px;
    //         height: 658px;
    //         list-style-type:none;
    //         /* UI Properties */
    //         background: #FFFFFF 0% 0% no-repeat padding-box;
    //         box-shadow: 0px 4px 6px #00000029;
    //         position: fixed;
    //         top: 0; 
    //         left: 0; 

    //         --bs-accordion-btn-icon: #{escape-svg($right-accordion-button-icon)};
    //         --bs-accordion-btn-active-icon: #{escape-svg($left-accordion-button-icon)};
            

    //         z-index: 6;
    //         opacity: 1;

    //         .mobile-menu-header{
    //             width: 390px;
    //             height: 56px;
    //             background: #FFFFFF 0% 0% no-repeat padding-box;
    //             border: 1px solid #FFFFFF;
    
    //             display: flex;
    //             justify-content: left;
    //             align-items: center;
    //             padding-left: 19.4px;
    //             opacity: 0.9;
    
    //             button {
    //                 margin-right: 15px;
    //                 width: 16px;
    //                 height: 16px;
    //                 size: 6px;
    //                 padding-left: 0; padding-right: 0;
    //             }
    //         }

    //         .mobile-menu-item_second {
    //             display: none;
    //             --bs-accordion-btn-icon: "";
    //             --bs-accordion-btn-active-icon:"";
    //             .backmenu{
    //                 --bs-accordion-btn-icon: #{escape-svg($left-accordion-button-icon)};
    //                 --bs-accordion-btn-active-icon:ceil($number: 0) #{escape-svg($right-accordion-button-icon)};
    //             }
    //         }
    //     }



    //     .layout-logo{
    //         display: flex;
    //         justify-content: center;
    //         align-items: left;
    //     }

    //     .logo{
    //         top: 10px;
    //         left: 56px;
    //         width: 100px;
    //         height: 25px;
    //         opacity: 1;
    //     }
        
    //     .navbar-margin{
    //         margin-top: 13px;
    //         margin-right: 32px;
            
    //     } 
        
    //     .nav-link{
    //         text-align: center;
    //         align-items: center;
    //         font-size: 16px;
    //         letter-spacing: 0px;
    //         color: #5F5F5F;
    //         opacity: 1;
        
    //     }
        
    //     .text-nav-item{
    //         font-family: 'Noto Sans';
    //         font-size: 15px;
    //         font-weight: 500;
        
    //         &:hover {
    //             color: #7FBE25;
    //         }
        
    //         &:active {
    //             color: #6AA318;
    //         }
    //         &:target {
    //             color: #6AA318;
    //         }
    //     }
    // }
}






