.CspmMainFunction {
    background-color: #F9F9F9 !important;
    width: 25vw;
    min-width: 240px;
    height: 40vh;
    min-height: 340px;
    border-radius: 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-color: #F9F9F9 !important;

    .card-img {
        width: 185px;
        height: 161px;
        margin: 8% 0 5% 0;
    }

    .card-body {
        width: 100%;
        .card-title {
            font-size: 20px;
            font-weight: bold;
            font-family: 'Noto Sans', sans-serif;
            letter-spacing: 0px;
            color: #323232;
            opacity: 1;
        }

        .card-text {
            padding-top: 5%;
            color: #5F5F5F;
            font-size: 12px;
            font-family: 'Microsoft JhengHei', sans-serif;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;

        }

        .card-button {
            width: 120px;
            height: 44px;
            margin-top: 30px;
        }
    }

}