.HealthAlert{
    font-family: 'Noto Sans',sans-serif;
    .card{
        margin-top: 10px;
        padding: 20px;
        color: #323232;

        .text-title{
            font-size: 14px;
            font-weight:bold;
            margin-right: 20px;
        }
        .text-desc{
            font-size: 13px;
        }

    }

}