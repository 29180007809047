.CompanyManagement {
  width: 100%;
  .card {
    width: 100%;
    height: 58px;
    margin-bottom: 10px;

    &:hover {
      border-color: #7fbe25;
    }

    .link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;

      font-family: 'Noto Sans', sans-serif;
      font-size: 14px;
      padding: 10px 0px 10px 20px;
      text-decoration: none;
      color: #323232;

      &:hover {
        color: #7fbe25;
      }

      &:hover .material-symbols-rounded {
        color: #7fbe25;
      }
    }
  }
}
