
.SecondLayerDrawer {
  z-index: 2051 !important; 
  .text-title {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Source Sans 3', sans-serif;
    color: #1d1d1d;
  }

  .offcanvas-body {
    padding: 24px;

    .text-description {
      font-size: 13px;
      color: #5f5f5f;
      padding: 20px 0px;
    }
  }
 
  .button-area {
    display: flex;
    align-items: end;
    justify-content: end;
    padding: 18px;

    .btn-download {
      font-size: 15px;
      font-weight: bold;
    }
  }
}

.ThirdLayerDrawer {
  z-index: 2053 !important; 
  .text-title {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Source Sans 3', sans-serif;
    color: #1d1d1d;
  }

  .offcanvas-body {
    padding: 24px 24px 15% 24px;
    height: auto;

    .text-description {
      font-size: 13px;
      color: #5f5f5f;
      padding: 20px 0px;
    }
  }
 
  .button-area {
    display: flex;
    align-items: end;
    justify-content: end;
    padding: 18px;

    .btn-download {
      font-size: 15px;
      font-weight: bold;
    }
  }
}





