.GodaBenefit{
    width: 20vw;
    min-width: 180px;
    height: 43vh;
    min-height: 280px;
    padding: 10% 1% 5% 1%;
    border-radius: 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-color: #D2D2D2;
    box-shadow: 2px 4px 10px  #D2D2D2;

    .card-img{
        width: 44px;
        height: 44px;
        margin-top: 6%;
        margin-bottom: 6%;
    }

    .card-body{
        width: 100%;
        padding: 0%;
        .card-title{
            font-size: 15px; 
            font-weight: bold;
            font-family: 'Source Sans 3', sans-serif;
            padding: 1%;
            letter-spacing: 0px;
            color: #323232;
            opacity: 1;
        }

        .card-text{
            padding: 1%;
            color: #5F5F5F;
            font-size: 14px;
            font-weight: bold;
            font-family: 'Microsoft JhengHei UI', sans-serif;
            display: flex;
            justify-content: center;
            
            ul{
                padding: 1% 5%;
                width: 100%;
                li{
                    font-size: 13px;
                    padding-bottom: 5px;
                    display: 'flex';
                    text-align: left;
                    list-style-type: none;
                }
            }
        }

        .card-button{
            width: 120px;
            height: 44px;
            margin-top: 30px;
        }
    }

}