.TeamsAppKey{
  width: 100%;
  
  .style-row{
    display: flex;
    padding: 6px 5px 22px 5px;
    justify-content: flex-end; /* 靠右對齊 */
    align-items: center;    /* 垂直居中 */
    
    .btn-auth{
      width: 110px;
      height: 29px;
      font-size: 12px;
      font-weight: bold;
      color: #323232;
    }
    .btn-token{
      width: 100px;
      height: 29px;
      font-size: 12px;
      font-weight: bold;
      color: #323232;
    } 
  }

  .link-area{
    padding: 0 0 20px 0;
    .text-link{
      color: #1D1D1D;
      font-size: 20px;
      font-weight: bold;
      font-family: "Source Sans Pro", sans-serif;
      text-decoration: none;
    }
  }
}



