.pentiumnetwork {
    
    .intro {
        padding: 130px 90px 130px 90px;
        background-color: #012446;
        .text {
            padding-right: 50px;

            .text-title {
                color: #FFFFFF;
                font-size: 30px;
                font-weight: bolder;
                text-align: left;
            }

            .text-desc {
                br {
                        margin-bottom: 20px;
                        display: block;
                        content: "";
                    }
                padding-top: 30px;
                font-size: 15px;
                text-align: left;
            }
        }
    }
    .sec-row{
        padding: 50px 90px 70px 90px;
        .title{
            font-size: 30px;
            font-weight: bold;
        }.text{
            br {
                    margin-bottom: 20px;
                    display: block;
                    content: "";
                }
            padding-top: 30px;
            font-weight: 400;
            color:#5F5F5F;
            font-size: 18px;
            padding-bottom: 30px;
        }
    }
                .first-right-row {
                    padding: 30px 90px 70px 90px;
        
                    .text {
                        padding-right: 50px;
        
                        .text-title {
                            font-size: 30px;
                            font-weight: bolder;
                            text-align: left;
                        }
        
                        .text-desc {
                            color: #5F5F5F;
                            font-size: 15px;
                            text-align: left;
        
                            br {
                                margin-bottom: 20px;
                                display: block;
                                content: "";
                            }
                        }
                    }
                }
    .right-row{
        padding: 70px 90px 70px 90px;
        .text {
            padding-right: 50px;

            .text-title {
                font-size: 30px;
                font-weight: bolder;
                text-align: left;
            }

            .text-desc {
                color:#5F5F5F;
                font-size: 15px;
                text-align: left;
                br {
                        margin-bottom: 20px;
                        display: block;
                            content: "";
                    }
            }
        }
    }

        .left-row {
            padding: 70px 90px 70px 90px;
    
            .text {
                padding-left: 50px;
    
                .text-title {
                    font-size: 30px;
                    font-weight: bolder;
                    text-align: left;
                }
    
                .text-desc {
                    color: #5F5F5F;
                    font-size: 15px;
                    text-align: left;
    
                    br {
                        margin-bottom: 20px;
                        display: block;
                        content: "";
                    }
                }
            }
        }



                .service-health-check {
                    padding: 70px 90px 70px 90px;
        
                    .tab_content {
                        margin-top: 6%;
                        align-items: center;
                    }
        
                    .tab {
                        align-items: left !important;
                        padding-top: 75px;
        
                        .func-text {
                            padding-left: 7%;
                            font-size: 14px;
                            font-weight: bold;
                            color: #0067b0;
                        }
        
                        .text-title {
                            padding-left: 7%;
                            font-size: 32px;
                            font-weight: bold;
                        }
        
                        .accordion {
                            .accordion-item {
                                border-top: 0px !important;
                                border-right: 0px !important;
                                border-left: 0px !important;
                                border-bottom: 100% solid #dddddd !important;
                            }
        
                            .nav-item {
                                border: 0px !important;
                                width: 100%;
                            }
        
                            .accordion-header {
                                .accordion-button[aria-expanded='true'] {
                                    border-bottom: 0px !important;
                                    border-radius: 0% !important;
                                    border-left: 10px solid #7fbe25 !important;
                                }
        
                                .accordion-button {
                                    font-size: 18px !important;
                                    font-weight: bold !important;
                                    border-bottom: white !important;
                                }
        
                                .accordion-button:not(.collapsed) {
                                    border: 0px;
                                    background-color: #ffffff;
                                }
                            }
        
                            .accordion-body {
                                font-size: 16px;
                                font-family: Microsoft JhengHei;
                                border-left: 10px solid #7fbe25 !important;
                            }
                        }
        
                        .btn-allservice {
                            font-size: 20px;
                            font-weight: bold;
                            text-decoration: none;
                        }
                    }
                }
                .advantage{
                    padding: 30px 90px 30px 90px;
                    background-color: #F5F9FC;
                    .cards{
                        .card-body{
                            padding:40px 50px 50px 50px;
                            height: 60vh;
                            .card-title{
                                color:#045FD8;
                                justify-content: center;
                                    margin-top: 10px;
                                    margin-bottom: 20px;
                                    font-size: 20px;
                                display: flex;
                            }
                            .card-text{
                                padding-bottom: 5px;
                            }
                        }
                    }
                }
}