.GodaMall {
    height: auto;

    .main-intro {
        height: auto;
        padding-top: 8%;
        padding-bottom: 4%;
        background: transparent linear-gradient(to right, #e0edf5, #ffffff, #e4f2f2);

        .text-intro {
            padding-left: 7%;
            align-self: center;

            b {
                font-size: 2.2rem;
            }

            p {
                font-size: 15px;
                font-family: 'Microsoft JhengHei', sans-serif;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .btn-secondary {
                width: 120px;
                height: 44px;
            }
        }
    }

    .partner-title-container {
        margin-top: 100px;
        justify-content: center;
        align-items: center;
        .partner-title {
            justify-content: center;
            align-items: center;
            text-align: center;
            font-weight: bold;
            font-style: normal;
            font-size: 32px;
            line-height: 42px;
            font-family: 'Noto Sans', sans-serif;
            letter-spacing: 0px;
            color: #323232;
            opacity: 1;
        }
    }

    .goda-mall {
        padding: 6% 6% 2% 6%;
        // margin-bottom: 10%;
        .img {
            align-self: left;
        }
        .text-mall {
            margin-top: 5%;
            padding-left: 5%;
            .text-title {
                font-size: 24px;
                font-weight: bold;
            }

            .text-desc {
                font-size: 16px;
                font-family: Microsoft JhengHei;
                color: #5f5f5f;
                margin-bottom: 30px;
            }

            li {
                list-style-type: none;

                p {
                    font-size: 16px;
                    font-family: Microsoft JhengHei;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }

            .btn-allservice {
                font-size: 20px;
                font-weight: bold;
                text-decoration: none;
            }
        }
    }
}
