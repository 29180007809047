
$max-width: 390px;
$min-width: 900px;
@media screen and (min-width:$min-width){
    .AppFooter {
        background: #323232;
        color: #FFFFFF;
        font-size: 20px;
        height: 546px;
        padding:86px 163px 227px 96px ;
        z-index: 5;
        position: relative;
        display: flex;
        justify-content: center;
        text-align: left;
        align-items: center; 

        .footer {
            font-size: 20px;
            font-family: Microsoft JhengHei;
            margin-top: 90.25px;
            p {
                white-space: nowrap;
                margin-bottom: 48px;
            }

            .horizontal-list{
                display: flex;
                width: 1021px; 
                height: 36px;
                flex-direction: row;
                list-style-type:none;
                margin-bottom: 87.75px;
                padding-left: 0px;

                span {
                    margin-left:17px; margin-right: 17px; 
                }
            }
        }

        .nav-link{
            display: inline-block;
            text-decoration: none;
            margin-bottom: 94.75px;
        }
    }

    .AppFooter.login {
        display: none;
    }
}

@media screen and (max-width:$max-width){
    .AppFooter {
        background: #323232;
        color: #FFFFFF;
        font-size: 20px;
        height: 500px;
        padding:37px 0 56px 0;
        z-index: 5;
        position: relative;;

        .footer {
            font-size: 12px;
            font-family: Microsoft JhengHei;

            p {
                white-space: normal;
                margin-bottom: 19px;
                display: flex; // 使用 Flexbox 布局
                align-items: center; // 垂直居中对齐文本
                text-align: center;
                justify-content: center; /* 水平居中 */
            }

            .horizontal-list{
                display: flex;
                flex-direction:column;
                align-items: center;
                list-style-type:none;
                padding-left:0px;

                li {
                    height: 36px;
                    display: flex; // 使用 Flexbox 布局
                    align-items: center; // 垂直居中对齐文本
                }
                span {
                    display: none;
                }
            }
        }
        .nav-link{
            text-decoration: none;
        }

    }
}

