.Cloudgoda {
    height: auto;

    .main-intro {
        height: 260px;

        .text-intro {
            padding-left: 5%;
            padding-top: 10%;

            b {
                text-align: left;
                letter-spacing: 0px;
                color: #ffffff;
                opacity: 1;
                font-style: normal;
                font-weight: bold;
                font-size: 32px;
                line-height: 42px;
                font-family: 'Noto Sans', sans-serif;
            }

            p {
                font-size: 15px;
                font-family: 'Microsoft JhengHei', sans-serif;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .btn-secondary {
                width: 120px;
                height: 44px;
            }
        }
    }
    .cloudgoda-service {
        margin-bottom: 2%;
        padding-top: 4%;

        .text-service {
            padding-left: 7%;
            padding-top: 3%;

            .text-title {
                margin-top: 8vh;
                margin-bottom: 3vh;
            }

            .text-desc {
                font-size: 19px;
                font-family: Microsoft JhengHei;
                color: #5f5f5f;
            }

            li {
                list-style-type: none;
                p {
                    font-size: 16px;
                    font-family: Microsoft JhengHei;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }

            .btn-allservice {
                font-size: 20px;
                font-weight: bold;
                text-decoration: none;
            }
        }
    }

    .cloudgoda-cloud {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 6% 4%;

        .text-title {
            margin-top: 5vh;
            margin-bottom: 5vh;
        }
    }

    .cloudgoda-benefit {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-bottom: 8vh;

        .col-swiper {
            background: url('../../public/assets/images/img_sky.png') no-repeat left center #012446;
            background-position: 'left center';
            background-size: auto 100%;

            padding: 5% 7%;
            .swiper {
                height: 350px;
            }
            .swiper::part(bullet) {
                width: 20px;
                height: 5px;
                border-radius: 0%;
                background-color: #e9e9e9;
            }

            .swiper::part(bullet-active) {
                width: 20px;
                height: 5px;
                border-radius: 0%;
                background-color: #7fbe25;
                padding-bottom: 0%;
            }
        }
        .text-title {
            margin-top: 5vh;
            margin-bottom: 5vh;
        }
    }

    .subscr-plan {
        background: #f5f9fc;
        padding-bottom: 10%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;

        .text-title {
            margin-top: 3%;
            margin-bottom: 3%;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
        }
    }
}

.Cloudgoda{
    .login {
        letter-spacing: 0px;
        color: #323232;
        background: none;
        padding: 4% 3% 6% 5%;
        .text-title {
            font-size: 22px;
            font-weight: bold;
            font-family: 'Noto Sans';
        }
        .text-desc {
            font-size: 14px;
            font-family: 'Microsoft JhengHei';

            ul {
                margin: 30px 0px;
            }
        }
    }
}

.banner {
    width: 100vw;
    height: 260px;
    opacity: 90%;
    // padding-left: 92px !important;

    display: flex;
    align-items: center; /*垂直置中*/
    opacity: 1;

    .banner-subtitle {
        display: flex;
        color: #95e8ff;
        padding: 10% 2% 0% 7%;
    }
    .banner-title {
        display: flex;
        font-size: 30px !important;
        text-align: left;
        font-weight: bold;
        // padding: 0% 2% 9% 7%;
        padding-left: 92px !important;
        padding-top: 108px !important;
        padding-bottom: 108px !important;

        letter-spacing: 0px;
        white-space: nowrap;
    }
}

.banner-1 {
    width: 100vw;
    height: 360px;
    opacity: 90%;
    display: flex;
    flex-direction: column;
    align-items: left; /*垂直置中*/
    opacity: 1;

    .banner-content-container {
        padding-left: 92px !important;
        padding-top: 105px !important;

        .banner-path-1 {
            margin-bottom: 24px;
            text-align: left;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            font-family: Microsoft JhengHei;
            letter-spacing: 0px;
            color: #f2f2f2;
            opacity: 1;

            .breadcrumb-separator {
                margin: 0 4px; /*adjust the 8px to increase or decrease the spacing */
            }
        }
        .banner-title-1 {
            display: flex;
            font-size: 30px !important;
            text-align: left;
            font-weight: bold;
            // padding-bottom: 108px !important;

            letter-spacing: 0px;
            white-space: nowrap;
        }
        .banner-description-1 {
            text-align: left;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            font-family: Microsoft JhengHei;
            letter-spacing: 0px;
            color: #f2f2f2;
            opacity: 1;
        }
    }
}
