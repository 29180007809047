.AppLoginSidebar{
    .Header{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;


        .title{
            font-size: 20px;
            font-weight: bold;
            font-family: 'Noto Sans';
            color: #323232;
        }
    }

        .text-header{
            width: 100%;
            font-size: 15px;
            font-weight: bold;
            color: #323232;
            margin-top: 15px;  margin-bottom: 0px; 
        }


        .menu-item{
            width: 200px;
            padding: 0px;
            margin-left:17px;
            white-space: nowrap;

           
            .text-btn{
                justify-content: left;
                align-content: left;
                font-size: 14px;
                font-family: 'Microsoft JhengHei', sans-serif;
                color: #323232;

            }
        }
        .menu-item.selected{
            font-weight: bold;
            width: 200px;
            padding: 0px;
            margin-left:13px;
            background: #E2E2E226;
            border: solid #7FBE25 4px;
            border-top: none; border-bottom:none; border-right: none;

            .menu-link{
                text-decoration: none;

                .icon{
                    //parameter change svg color
                    fill: #7FBE25;
                    //parameter change svg color
                    
                }
            }
        }

        .text-btn{
            font-size: 14px;
            font-family: 'Microsoft JhengHei', sans-serif;
            color: #323232;
            &:hover {
                color:  #757474;
            }

            &:hover .material-symbols-rounded {
                color: #7FBE25;
            }
            
        }

        .material-symbols-rounded{
            color: #323232;
        }
        .material-symbols-rounded.selected {
            color: #6AA318;
        }

        .menu-classification{
            width: 300px;
                padding: 0px;
                padding-left: 23px;
                padding-bottom: 5px;
                margin-bottom: 15px;
                font-weight:bold;
                border-bottom: 1px solid #dee2e6;
                .menu-link {
                    text-decoration: none;
                    padding-left: 0px;
            
                    .text-btn {
                        font-size: 14px;
                        font-family: 'Noto Sans';
                        color: #323232;
                    }
                }
        }
}