.PriceCompare {
    // background: transparent linear-gradient(to right,  #E0EDF5, #FFFFFF, #E4F2F2);
    // height: auto;

    .price-search {
        // height: 60vh;

        .img-main {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .search-main {
            display: flex;
            justify-content: start;
            align-items: start;
            text-align: left;
            padding: 2% 8% 5% 1%;
            color: #323232;

            .col-2 {
                margin-top: 30px;
            }
            .col-3 {
                margin-top: 10px;
            }
        }
    }
    .text-subtitle {
        margin-bottom: 6px;
        font-size: 15px;
        font-weight: bold;
    }
}

.PriceCompareResult {
    background: #ffffff;
    min-height: 70vh;
    height: auto;

    .price-search-result {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.disabled-link {
    pointer-events: none;
    color: #ccc;
}
