.ReportSubscribe{
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .link-area {
    padding: 0 0 20px 0;
    .text-link {
      color: #1d1d1d;
      font-size: 20px;
      font-weight: bold;
      font-family: 'Source Sans Pro', sans-serif;
      text-decoration: none;
    }
  }
  .content {
    flex: 1;
    padding: 10px;
    width: 100%;
  }

  .add-area {
    display: flex;
    align-items: center;
    background-color: #e9e9f0;
    padding: 6px;
    margin-top: 5px;
    margin-bottom: 10px;

    .btn-add {
      display: inline-block;
      width: 68px;
      height: 31px;
      border: 1px solid #bdbdbd;
      background-color: #ffffff;
      border-radius: 4px;
      font-size: 12px;
      opacity: 1;
      color: #323232;
    }
  }

  .sticky-footer {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    border: #E2E2E2;
    border-style: solid;
    border-bottom: none;
    border-left: none;
    border-right: none;
    background-color: #ffffff;
    text-align: center;
    padding: 10px 0;
    z-index: 1000; 
  }
}