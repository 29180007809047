.ForgetPwd{
    height: auto;
    background: #FAFAFA;
    border: 1px solid #E2E2E2;
    padding: 3% 0% 10% 0%;
    display: flex;
    justify-content: center;

    .btn{
        width: 100%;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        text-align: center;
        font-size: 16px;
        margin-top: 5vh;
      }

    .Card{
        width: 440px;
        height: auto;
        padding: 1% 3% 4% 4%;
        display: flex;
        justify-content: center;
        border-radius: 0%;
        box-shadow: 0px 3px 6px #00000029;
        
        .text-title{
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            letter-spacing: 0px;
            background: none;
            color: #323232;
           
        }

        .text-desc{
            font-size: 14px;
            text-align: left;
            letter-spacing: 0px;
            color: #5F5F5F;
        }
        

    }


}