.DesignAndDeploy{
    .main-intro{
        font-family: Noto Sans;
            height: auto;
        
            .banner {
                margin:0px;
                color: #F0F0F0;
                width: 100%;
                height: 200px;
                background-image:url('../../public/assets/images/bg_design_and_deploy.png');
                background-size: cover;
                text-align: left;
                font-size: 30px;
                font-weight: bold;
                letter-spacing: 0px;
                display: flex;
                align-items: center;
                padding: 10% 2% 9% 7%;
            }
    }
                .content {
                    padding: 10% 3% 8% 7%;
                    .content-text {
                        padding-left: 3%;
                        .text-title {
                            padding-bottom: 3%;
                        }
                                                li {
                                                    width:85%;
                                                    box-sizing: border-box;
                                                        height: calc(40% + 10px);
                                                    display: flex;
                                                    align-items: flex-start;
                                                    list-style: none;
                        
                                                    .li-text {
                                                       
                        
                                                        b {
                                                            margin-bottom: 20%;
                                                        }
                                                    }
                                                }
                    }
                }
}

/*---------------
 * BlockCanvas
 ----------------*/
#DesignAndDeploy #BlockCanvas .component {
    display: inline-block;
    cursor: pointer;
    padding: 10px;
    padding-bottom: 13px;
    max-width: 75px;
    text-align: center;
}
#DesignAndDeploy #BlockCanvas .component.in-canvas {
    position: absolute;
}
#DesignAndDeploy #BlockCanvas .component.in-canvas img {
    width: 55px;
}

/*---------------
 * BlockNodeDetail
 ----------------*/
#DesignAndDeploy #BlockNodeDetail .contentBlock {
    background: #323232;
    color: #ffffff;
    border-radius: 4px;
}
#DesignAndDeploy #BlockNodeDetail .contentBlock * {
    font-size: 14px;
}

.text-subtitle-detail {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0 !important;
}
