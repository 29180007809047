$max-width: 899px;
$min-width: 900px;

// @media screen and (min-width:$min-width){
.AllService {
    font-family: Noto Sans;
    min-height: 2000px;
    margin-bottom: 10%;
    height: auto;

    .banner {
        width: 100%;
        height: 260px;
        opacity: 90%;
        border: 1px solid #f0f0f0;
        text-align: left;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0px;
        display: flex;
        align-items: center; /*垂直置中*/
        padding: 10% 2% 9% 7%;
        opacity: 1;
    }

    .sidebar {
        width: 198px;
        height: 488px;
        // position: fixed;
        // top: 448px;
        // left: 30px;
        margin-top: 30px;
        margin-left: 30px;
        padding-top: 24px;
        background: #f0f0f0 0% 0% no-repeat padding-box;
        z-index: 0;
        opacity: 1;

        .title-sidebar {
            display: none;
        }
        .text-sidebar:hover {
            color: #7fbe25;
        }
    }

    .text-sidebar {
        font-size: 18px;
        font-weight: 500;
        text-decoration: none;
        letter-spacing: 0px;
        color: #323232;
        opacity: 1;
    }
    .text-sidebar:hover {
        color: #7fbe25;
    }

    .layout-sidebar {
        width: 198px;
        height: 50px;
        padding-left: 27px;
        padding-top: 13px;
        text-align: left;
        opacity: 1;
    }

    .text-title {
        height: 94px;
        display: flex;
        align-items: center; /*垂直置中*/
        text-align: left;
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #1d1d1d;
        opacity: 1;
    }

    .desc-layout {
        height: auto;
        opacity: 1;
    }

    .text-subtitle {
        text-decoration: underline;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #7fbe25;
        opacity: 1;
    }
    .col-item {
        height: 136px;
        padding-top: 39px;
    }

    .text-desc {
        font-size: 14px;
        letter-spacing: 0px;
        margin-top: 12px;
        color: #5f5f5f;
        opacity: 1;
    }
}

.AllService.login {
    font-family: Noto Sans;
    height: 2200px;

    .banner {
        display: none;
    }

    .sidebar {
        height: 100%;
        margin-top: 8px;
        margin-left: 0px;
        border: 2px solid #d2d2d2;
        border-bottom: none;
        border-left: none;
        border-top: none;
        background: #ffffff 0% 0% no-repeat padding-box;
        padding-top: 0px;
        z-index: 0;
        opacity: 1;

        .title-sidebar {
            display: flex;
            width: 100%;
            height: 66px;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #323232;
            opacity: 1;
        }

        .text-sidebar {
            font-size: 16px;
            font-weight: 500;
            text-decoration: none;
            letter-spacing: 0px;
            color: #323232;
            opacity: 1;
        }

        .layout-sidebar {
            width: 224px;
            height: 40px;
            padding-left: 27px;
            padding-top: 13px;
            text-align: left;
            opacity: 1;
        }
    }

    .text-title {
        height: 94px;
        display: flex;
        align-items: center; /*垂直置中*/
        text-align: left;
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #1d1d1d;
        opacity: 1;
    }

    .desc-layout {
        height: auto;
        opacity: 1;
    }

    .text-subtitle {
        text-decoration: underline;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #7fbe25;
        opacity: 1;
    }
    .col-item {
        height: 136px;
        padding-top: 39px;
    }

    .text-desc {
        font-size: 14px;
        letter-spacing: 0px;
        margin-top: 12px;
        color: #5f5f5f;
        opacity: 1;
    }
}
// }

// @media screen and (max-width:$max-width){
//     .AllService {
//         font-family: Noto Sans;
//         height: auto;

//         padding: 0;

//         .banner{
//             width: 100%;
//             height: 137px;
//             background: #FAFAFA 0% 0% no-repeat padding-box;
//             border: 1px solid #F0F0F0;
//             text-align: left;
//             font-size: 18px;
//             font-weight: bold;
//             letter-spacing: 0px;
//             display: flex;
//             align-items: center; /*垂直置中*/
//             padding-left: 20px;
//             color: #323232;
//             opacity: 1;
//         }

//         .select-service{
//             height: 66px;
//             padding:18px 20px 0px 20px;
//             margin-bottom: -18px;

//             .form-select{
//                 height: 50px;
//                 font-size: 14px;
//                 font-family: Arial;

//             }
//             .form-select-sm {
//                 @include border-radius($form-select-border-radius-sm);
//             }
//         }

//         .text-title{
//             height: 42px;
//             margin-top: 20px;
//             display: flex;
//             align-items: center; /*垂直置中*/
//             text-align: left;
//             font-size: 14px;
//             font-weight: bold;
//             letter-spacing: 0px;
//             color: #1D1D1D;
//             opacity: 1;
//         }
//         .list-layout{
//             padding: 0px 20px;
//             margin-left: -20px;

//             .desc-layout{
//                 margin-bottom: 20px;
//                 opacity: 1;
//             }

//             .text-subtitle{
//                 text-decoration: underline;
//                 font-size: 14px;
//                 font-weight: bold;
//                 letter-spacing: 0px;
//                 color: #7FBE25;
//                 opacity: 1;
//             }

//             .col-item{
//                 height: auto;
//                 padding-top: 20px;  padding-bottom: 20px;

//             }

//             .text-desc{
//                 font-size:12px;
//                 letter-spacing: 0px;
//                 margin-top: 4px;
//                 color: #323232;
//                 opacity: 1;

//             }
//         }
//     }
// }
