.Flowza {
    height: auto;
    .banner-sec {
        width: 100vw;
        height: 260px;
        opacity: 90%;

        display: flex;
        align-items: center; /*垂直置中*/
        opacity: 1;
        .banner-row-sec {
            padding-left: 100px;
            padding-top: 100px;
            display: flex;
            flex-direction: column;
            .banner-subtitle-sec {
                display: flex;
                color: #95e8ff;
                // padding: 10% 2% 0% 7%;
            }
            .banner-title-sec {
                display: flex;
                font-size: 30px;
                text-align: left;
                font-weight: bold;
                // padding-left: 92px !important;
                // padding-top: 108px !important;
                padding-bottom: 108px !important;

                letter-spacing: 0px;
                white-space: nowrap;
            }
        }
    }

    .main-intro {
        height: auto;
        padding-top: 8%;
        padding-bottom: 4%;
        // background:   linear-gradient(to right,  #E0EDF5, #FFFFFF, #E4F2F2);

        .text-intro {
            padding-left: 6%;
            padding-top: 1%;
            b {
                font-size: 2.2rem;
            }

            p {
                font-size: 15px;
                font-family: 'Microsoft JhengHei', sans-serif;
                margin-top: 10px;
                margin-bottom: 10px;
            }
            li {
                list-style-type: none;
                p {
                    font-size: 15px;
                    font-family: Microsoft JhengHei;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
            .btn-secondary {
                width: 120px;
                height: 44px;
            }
        }
    }
    .sys-intro {
        margin-bottom: 2%;
        padding: 0% 2%;
        .text-title {
            margin-top: 10vh;
            margin-bottom: 6vh;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
        }

        .text-tab {
            font-size: 18px;
            font-family: Microsoft JhengHei;
            letter-spacing: 0px;
            color: #323232;

            &:hover {
                font-weight: bold;
            }
        }
        .active {
            font-weight: bold;
        }
        .tab {
            padding: 3% 5%;

            ul {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                li {
                    list-style-type: none;
                    margin-top: 16px;
                    b {
                        font-size: 15px;
                        font-family: Microsoft JhengHei;
                    }
                }
            }
        }
    }

    .subscr-plan {
        background: #f5f9fc;
        padding-bottom: 10%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;

        .text-title {
            margin-top: 3%;
            margin-bottom: 3%;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
        }
    }
}
