.Classification{
    .title{

    }
    .selectList{
        .row>*{
    padding-right: 0% !important;
        .list-group{
            margin-bottom: 4%;
            .list-group-item{
                padding: 7% 3% 7% 3%;
                font-size: 14px;
            }
                        .list-group-item.active {
                            background-color:inherit;
                            color: inherit;
                            border:inherit;
                        }
        }
        }
    }
}