.RecommendationsDetailDrawer{
    .text-title{
        font-size: 18px;
        font-weight: bold;
        font-family: 'Source Sans 3', sans-serif;
        color: #1D1D1D;
    }

    .offcanvas-body{
        padding: 24px;
        .statics-content{
            width: 100%;
            min-height: 106px;
            height: auto;
            display: flex;
            margin-bottom: 26px;
            flex-direction: column;
            justify-content: center;

            .row{
                display: flex;
                text-align: center;
                align-items: center;
            }

            .text-statics{
                font-size: 13px;
                font-weight: 500;
                color: #5F5F5F;
                display: flex;
                justify-content: center;
                text-align: center;
                align-items: center;
            }
        
            .text-statics-number{
                font-size: 20px;
                font-weight: bold;
                display: flex;
                justify-content: center;
                text-align: center;
                align-items: center;
                color: #323232;
            }

        }
    
        .text-description{
            font-size: 13px;
            color: #5F5F5F;
            padding: 20px 0px;
        }

    }
    .offcanvas-body .download{
        padding: 0 18px;
        .text-desc {
            font-size: 16px;
            color: #323232;
        }

        .text-title {
            font-size: 14px;
            font-weight: bold;
            color: #323232;
            margin-top: 20px;
            margin-bottom: 5px;
        }
    }

    .button-area{
        display: flex;
        align-items: end;
        justify-content: end;
        padding: 18px;

        .btn-download{
            font-size: 15px;
            font-weight: bold;
        }
    }
    


}