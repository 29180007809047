

@media screen and (min-width:$min-width){

    .linkicon-slot{
        position: relative;
        width: 150px;
        height: 180px;
        background-color: #FFFFFF;
        border: 2px solid #E2E2E2;
        border-radius: 2px;   
    
        .delete-button {
            position: absolute;
            top: 0;
            right: 0;
            display: none;
        }
    
        &:hover {
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
            .delete-button {
                display: block;
            }
        }

        &:active {
            border: 2px solid #7FBE25 ;
            border-radius: 2px;
            box-shadow: none;
            
            .delete-button {
                display: block;
            }
        }

        .link-title{
            width: 150px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            color:#323232;
            font-size: 16px;
            font-weight: bold;
            padding: 13px 10px;
            opacity: 1;

            .text-title{
                width: fit-content;
                height: 43px;
                align-content: center;
                text-align: center;
                padding-right: 3px;
            }
        
        }

        .tile-icon{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 150px;
            height: 110px;
            opacity: 1;

            .img-link{
                width: 70px;
                height: 70px;
            }
        }
    }

}

@media screen and (max-width:$max-width){
    .linkicon-slot{
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 167px;
        height: 70px;
        background-color: #FFFFFF;
        border: 1px solid #E2E2E2;
        border-radius: 3px;   
    
        .delete-button {
            position: absolute;
            top: 0;
            right: 0;
            display: none;
        }
    
        &:hover {
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
            .delete-button {
                display: block;
            }
        }

        &:active {
            border: 1px solid #7FBE25 ;
            border-radius: 3px;
            box-shadow: none;
            
            .delete-button {
                display: block;
            }
        }
        .tile-icon{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 119px;
            height: 70px;
            opacity: 1;

            .img-link{
                width: 24.3px;
                height: 24.3px;
            }
        }

        .link-title{
            width: 150px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            color:#323232;
            padding: 13px 10px;
            opacity: 1;

            .text-title{
                width: 98px;
                font-size: 14px;
                font-weight: bold;
                text-align: left;
                align-content: center;
            }
        
        }
    }
}