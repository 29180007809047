
.AutoProcess {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  
  .content {
    flex: 1;
    padding: 1% 1% 3% 1%;
    width: 100%;


    .link-area {
      padding: 0 0 20px 0;
      .text-link {
        color: #1d1d1d;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Source Sans Pro', sans-serif;
        text-decoration: none;
      }
    }
  }

  .sticky-footer {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: end;
    border: #E2E2E2;
    border-style: solid;
    border-bottom: none;
    border-left: none;
    border-right: none;
    background-color: #ffffff;
    text-align: center;
    padding: 1.5%;
    z-index: 1000; 
  }
}
