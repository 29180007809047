.ActiveServiceIssue{

    .text-title{
        font-size: 18px;
        font-weight: 600;
        font-family: 'Source Sans 3', sans-serif;
        color: #6AA318;
        margin-top: 30px;
    }
    
}
    


