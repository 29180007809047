$max-width: 390px;
$min-width: 900px;

.Portal {
    .main-portal {
        width: 100vw;
        height: 898px;
        opacity: 1;

        .main-title {
            font-size: 56px;
            padding-top: 12vw;
            text-align: center;
            margin-bottom: 27px;
            font-family: Noto Sans;
            font-weight: bold;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
        }

        .main-desc {
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            padding-left: 15vw;
            padding-right: 15vw;
            letter-spacing: 0px;
            color: #f2f2f2;
            opacity: 0.8;
            font-style: normal;
            font-weight: normal; /* equivalent to font-weight: 400; */
            font-variant: normal;
            font-size: 20px;
            line-height: 32px;
            font-family: Microsoft JhengHei, sans-serif;
        }

        .btn-layout {
            margin-top: 83px;
            opacity: 1;

            .bold {
                font-weight: bold;
            }
        }

        .layout-btn-free {
            display: flex;
            justify-content: right;
            text-align: right;
            opacity: 1;
        }

        .layout-btn-knowmore {
            display: flex;
            justify-content: left;
            text-align: left;
            opacity: 1;
        }

        .layout-img-main {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 84px;
        }

        .img-main {
            width: 1080px;
            height: 600px;
            // transform: scale(1.4, 1.6);
        }
    }

    .text-subtitle {
        text-align: left;
        font-size: 38px;
        font-weight: bold;
        margin-top: 70px;
        letter-spacing: 0px;
        color: #323232;
        opacity: 1;
    }
    .text-subtitle-noto {
        text-align: left;
        margin-top: 70px;
        letter-spacing: 0px;
        color: #323232;
        opacity: 1;
        font-weight: bold;
        font-style: normal;
        font-variant: normal;
        font-size: 38px;
        line-height: 52px;
        font-family: 'Noto Sans', sans-serif;
    }

    .text-subtext {
        text-align: left;
        letter-spacing: 0px;
        color: #5f5f5f;
        line-height: 32px;
        opacity: 1;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        font-family: 'Microsoft JhengHei';
    }

    .title.padding {
        padding-top: 260px;
        padding-bottom: 62px;
    }

    .title.padding2 {
        padding-top: 115px;
        padding-bottom: 62px;
    }

    .pros {
        background: #fcfafa 0% 0% no-repeat padding-box;
        border: 1px solid #fcfafa;
        margin-bottom: 87px;
        opacity: 1;

        .list {
            height: 320px;
            display: flex;
            justify-content: center; /*水平置中*/
            align-items: center; /*垂直置中*/
        }

        .list-pros {
            width: 218px;
            height: 156px;
            margin-bottom: 7.3px;
            display: flex;
            justify-content: center; /*水平置中*/
            align-items: center; /*垂直置中*/
        }

        .text-pros {
            width: 218px;
            height: 37px;
            text-align: center;
            font-size: 28px;
            font-weight: bold;
            font-family: Noto Sans;
            letter-spacing: 0px;
            color: #757474;
            opacity: 1;
        }

        .img-pros1 {
            width: 90px;
            height: 81px;
        }

        .img-pros2 {
            width: 96px;
            height: 81px;
        }

        .img-pros3 {
            width: 93px;
            height: 81px;
        }

        .img-pros4 {
            width: 100px;
            height: 81px;
        }

        .img-pros5 {
            width: 93px;
            height: 81px;
        }
    }

    .adv {
        display: flex;
        justify-content: center;
        text-align: left;
        align-items: center; /*垂直置中*/

        .layout-adv {
            height: 620px;
            margin-left: 20px;
            margin-right: 20px;
        }

        .layout-adv2 {
            height: 620px;
            margin-left: 20px;
            margin-right: 20px;
        }

        .img-adv1 {
            width: 574px;
            height: 431px;
            display: flex;
            justify-content: center;
            text-align: center;
        }

        .img-adv2 {
            width: 610px;
            height: 455px;
            display: flex;
            justify-content: center;
            text-align: center;
        }

        .img-adv3 {
            width: 610px;
            height: 562px;
            display: flex;
            justify-content: center;
            text-align: center;
        }
    }

    .end-portal-container {
        width: 100vw;
        height: 332px;
        justify-content: center;
        align-items: center;
        text-align: center;
        .end-portal-title {
            justify-content: center;
            align-items: center;
            text-align: center;
            font-style: normal;
            font-weight: medium; /* or font-weight: 500; */
            font-size: 60px;
            line-height: 82px;
            font-family: 'Noto Sans', sans-serif;
            letter-spacing: 0px;
            color: #428fc7;
            opacity: 1;
        }
        .end-portal-subtext {
            justify-content: center;
            align-items: center;
            text-align: center;
            letter-spacing: 0px;
            color: #f8f8f8;
            opacity: 1;
            font-style: normal;
            font-weight: normal; /* or font-weight: 400; */
            font-variant: normal;
            font-size: 16px;
            line-height: 20px;
            font-family: 'Microsoft JhengHei', sans-serif;
        }
    }

    .qa {
        .btn-qa {
            margin-right: 10px;
            height: 40px;
            background: #e9e9e9 0% 0% no-repeat padding-box;
            border: 4px solid #e9e9e9;
            border-radius: 52px;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #5f5f5f;
            opacity: 1;

            &:hover {
                background: #def1c3 0% 0% no-repeat padding-box;
                border: 4px solid #def1c3;
                border-radius: 52px;
                color: #5f5f5f;
                opacity: 1;
            }
        }
        .btn-qa.activate {
            background: #7fbe25 0% 0% no-repeat padding-box;
            border: 4px solid #7fbe25;
            border-radius: 52px;
            font-weight: bold;
            color: #fcfafa;
            opacity: 1;
        }
    }

    .question-and-answer {
        .accordion {
            .accordion-item {
                border-top: 0px !important;
                border-right: 0px !important;
                border-left: 0px !important;
                border-bottom: 100% solid #dddddd !important;
            }
            .nav-item {
                border: 0px !important;
                width: 100%;
            }
            .accordion-header {
                .accordion-button[aria-expanded='true'] {
                    border-bottom: 0px !important;
                    border-radius: 0% !important;
                    border-left: 10px solid #7fbe25 !important;
                }
                .accordion-button {
                    font-size: 18px !important;
                    font-weight: bold !important;
                    border-bottom: white !important;
                }
            }
            .accordion-body {
                font-size: 16px;
                font-family: Microsoft JhengHei;
                border-left: 10px solid #7fbe25 !important;
            }
        }
    }
    .QA {
        text-align: left;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        font-family: 'Noto Sans', sans-serif;
        letter-spacing: 0px;
        color: #323232;
        opacity: 1;
    }

    .btn-backtop {
        width: 72px;
        height: 72px;
        position: fixed;
        bottom: 80px;
        right: 14px;
        display: none;
        border: none;
        background: #f0f7e7 0% 0% no-repeat padding-box;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        border-radius: 50px;
        font-size: 45px;
        font-weight: bolder;
        color: #7fbe25;
        z-index: 5;

        &:hover {
            background: #7fbe25 0% 0% no-repeat padding-box;
            font-size: 50px;
            font-weight: bolder;
            color: #ffffff;
        }

        &:active {
            background: #6aa318 0% 0% no-repeat padding-box;
            font-size: 50px;
            font-weight: bolder;
            color: #ffffff;
        }

        &:disabled {
            background: #def1c3 0% 0% no-repeat padding-box;
            font-size: 50px;
            font-weight: bolder;
            color: #ffffff;
        }
    }

    .btn-logo {
        width: 72px;
        height: 72px;
        position: fixed;
        bottom: 80px;
        left: 46px;
        display: block;
        border: none;
        background: #7fbe25 0% 0% no-repeat padding-box;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        border-radius: 50px;
        font-size: 11px;
        font-family: Arial;
        white-space: nowrap;
        color: #f3f3f3;
        z-index: 5;
    }
}

/*zoom hover effect for spotlight*/
.background-image-col::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../public/assets/images/spotlight_bg/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform 1s ease-in-out;
    z-index: -1;
    overflow: 'hidden';
}

.zoom-effect::after {
    transform: scale(1.3);
    // transition: transform 1s ease-in-out;
}

/* responsive 1*/

@media (max-width: 1117px) {
    .firstRow-1 {
        flex-direction: column-reverse !important;
        justify-content: center !important;
        align-items: center !important;
        .left-column {
            padding: 0px !important;
            width: 100vw !important;
            .spotlight-info-grid {
                padding-top: 0px !important;
            }
        }
        .text-subtitle {
            padding-top: 0px !important;
            margin-top: 0px !important;
            font-size: 25px !important;
        }
        .text-subtext {
            margin-top: 0px !important;
            font-size: 14px !important;
        }

        .firstRow-1 .background-image-col {
            width: 100vw !important;
        }
    }
    .left-column-1 {
        padding-left: 0px !important;
        width: 100% !important;
        .spotlight-info-grid {
            padding-top: 0px !important;
        }
    }
}

@media (max-width: 600px) {
    .firstRow-1 {
        flex-direction: column-reverse !important;
        justify-content: center !important;
        align-items: center !important;

        .text-subtitle {
            padding-top: 0px !important;
            margin-top: 0px !important;
            font-size: 15px !important;
        }
        .text-subtext {
            margin-top: 0px !important;
            font-size: 10px !important;
        }

        .firstRow-1 .background-image-col {
            width: 100vw !important;
        }
    }
    .left-column-1 {
        padding-left: 0px !important;
        width: 100% !important;
    }
}
