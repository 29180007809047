

.logo {
    text-decoration: none;
    font-size: 25px;
    color: inherit;
    margin-right: 5px;
    padding: 0 0 0 20px;
}

.desktop-nav{
    display: flex;
    justify-content: space-between;
}

/* menu on desktop */
.desktop-nav .menus {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    padding: 1px 2px;
}
 
.desktop-nav .menu-items {
    position: relative;
    font-size: 16px;

    a {
        display: block;
        font-size: inherit;
        color: inherit;
        text-decoration: none;
    }

    button {
        display: flex;
        align-items: center;
        color: inherit;
        font-size: inherit;
        border: none;
        background-color: transparent;
        cursor: pointer;
        width: 100%;

        span {
            margin-left: 3px;
        }
    }

    .underline{
        font-weight: bold;
        border: solid #7FBE25 3px;
        border-top: none; border-left:none; border-right: none;
        padding: 35px 10px 15px 12px;
    
    }

}

.desktop-nav .menu-items.login{
   
    color: #FFFFFF;
    font-size: 14px;
}


.desktop-nav .menu-items button {
    text-align: left;
    padding: 32px 10px 15px 12px;
}

.desktop-nav .menu-items button:hover {
    background-color: #E1E1E133;
}

.desktop-nav .arrow::after {
    content: "";
    display: inline-block;
}

.desktop-nav .dropdown {
    position: absolute;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
        0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #fff;
    border-radius: 0.5rem;
    display: none;
}

.desktop-nav .dropdown.show {
    display: block;
}

.desktop-nav .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
}

.mobile-nav {
    display: none;
}

/* menu on mobile */
@media screen and (max-width: 960px) {
    .nav-area {
        justify-content: space-between;
    }

    .desktop-nav {
        text-align: right;
    }

    .mobile-nav {
        display: block;
    }

    .mobile-nav .mobile-nav__menu-button {
        color: inherit;
        font-size: inherit;
        border: none;
        background-color: transparent;
        cursor: pointer;
        position: relative;
    }

    .mobile-nav .menus {
        list-style: none;
        position: absolute;
        top: 50px;
        right: 20px;
        box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
            0 4px 6px -2px rgba(71, 63, 79, 0.16);
        z-index: 999;
        min-width: 12rem;
        padding: 0.5rem 0;
        background-color: #fff;
        border-radius: 0.5rem;
    }

    .mobile-nav .menu-items a {
        display: block;
        font-size: inherit;
        color: inherit;
        text-decoration: none;
    }

    .mobile-nav .menu-items button {
        display: flex;
        align-items: center;
        color: inherit;
        font-size: inherit;
        border: none;
        background-color: transparent;
        cursor: pointer;
        width: 100%;
    }

    .mobile-nav .menu-items>a,
    .mobile-nav .menu-items button {
        text-align: left;
        padding: 0.7rem 1rem;
    }

    .mobile-nav .menu-items a:hover,
    .mobile-nav .menu-items button:hover {
        background-color: #fff;
    }

    .mobile-nav .arrow::after {
        content: "";
        display: inline-block;
        margin-left: 1.2em;
        vertical-align: 0.09em;
        border-top: 0.42em solid;
        border-right: 0.32em solid transparent;
        border-left: 0.32em solid transparent;
    }

    .mobile-nav .arrow-close::after {
        content: "";
        display: inline-block;
        margin-left: 1.2em;
        vertical-align: 0.09em;
        border-bottom: 0.42em solid;
        border-right: 0.32em solid transparent;
        border-left: 0.32em solid transparent;
    }

    .mobile-nav .dropdown {
        margin-left: 1.2em;
        font-size: 0.9rem;
        padding: 0.5rem 0;
        list-style: none;
        display: none;
    }

    .mobile-nav .dropdown.show {
        display: block;
    }
}