

.CompanyBaseInfo.SelectCompanyMemberModal {
  max-width: 50%;
  .header{
    padding: 15px 10px 5px 15px;
    .text-title{
      font-size: 14px;
      font-weight: bold;
    }
  }
  .body{
    max-height: 70vh; 
    .row{
      padding: 0px;
    }
  }
  

}