.app-footer {
    padding: 40px 200px 40px 200px;
    background: #323232 0% 0% no-repeat padding-box;
    border: 1px solid #323232;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    font-family: 'Microsoft JhengHei', sans-serif;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}



.app-footer-list .MuiListItem-root a {
  transition: color 0.3s ease; /* Smooth transition for color change */
    color: #fff;
    text-decoration: none;
}

.app-footer-list .MuiListItem-root a:hover {
  color: #7FBE25; /* Change color on hover */
  text-decoration: none;
}

/* mobile */
@media screen and (max-width: 960px) {
    .app-footer {
        padding: 40px 20px 40px 20px;
    }

    .app-footer-list {
        text-align: center;
        justify-content: center;
        align-items: center;
    }
}