.ResourceAssetConfiguration{
    .filter{
        padding-bottom:3%;

        .input-group{
            display: flex;
            align-items: center;
            width:90%;
            .input-filter{
                border: 1px solid #dee2e6;
                display: flex;
                align-items: center;
                margin-left: 2% !important;
                .form-control {
                    border:0px;
                    }
                .button{
                    padding-right:3px;
                }
            }

        }
        .side_btn{
            display: flex;
            justify-content: end;
            .btn-group{
                width:30vh;
                align-items: center;
                .btn{
                    display: flex;
                    align-items: center;
                        height: 60%;
                        margin-left: 3%;
                        border: 1px solid #BDBDBD;
                        color: #323232;
                        border-radius:4px;
                        font-size:12px;
                        font-weight: 500;
                        padding:0%;
                }
                .btn:hover{
                    color: black;
                    background-color: #dee2e600;
                }
            }
        }
    }
}