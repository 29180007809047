.custom-responsive-row {
    .custom-col {
        padding-left: 0;
        padding-right: 0;
    }

    @media (min-width: 768px) {
        .custom-col-left {
            padding-right: 16px;
            padding-left: 0; // No padding on the left
        }

        .custom-col-right {
            padding-right: 0; // No padding on the right
        }
    }

    @media (max-width: 768px) {
        .custom-col-left {
            padding-left: 0; // Adjust as needed for smaller screens
        }

        .custom-col-right {
            margin-top: 16px;
            padding-right: 0; // Adjust as needed for smaller screens
        }
    }
}
