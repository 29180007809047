#LatencyTesting {
    min-height: calc(100vh - 87px - 64px - 145px);
}
/*-------
  BlockMap
-------*/
#LatencyTesting #BlockMap .bubbles circle {
    cursor: pointer;
    stroke-width: 3 !important;
}
/*-------
  BlockFloatPing
-------*/
#LatencyTesting #BlockFloatPing {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
#LatencyTesting #BlockFloatPing:before {
    content: ' ';
    display: block;
    border-bottom: 10px solid #555;
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    margin-top: -10px;
    position: absolute;
    top: -10px;
    bottom: auto;
}
/*-------
 ******RWD*****
  For Desktop
 **************
-------*/
@media (min-width: 870px) {
    #LatencyTesting #BlockPingTable {
        display: none;
    }
}
/*-------
 ******RWD*****
  For Mobile
 **************
-------*/
@media (max-width: 870px) {
    #LatencyTesting #AreaDesktopMap {
        display: none;
    }
    #LatencyTesting #BlockPingTable table {
        min-width: 870px;
    }
}
