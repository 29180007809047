$max-width: 390px;
$min-width: 900px;

.AppLoginMenu {
    width: 100%;
    height: 50px;

    opacity: 1;

    .desktop-nav{
        display: flex;
        justify-content: space-between;
        background: #323232;
        height:50px;

        .menus {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            list-style: none;
            padding: 1px 2px;
        }

        .menu-items {
            position: relative;
            font-size: 15px;
            color: #FFFFFF;
        
            a {
                display: block;
                font-size: inherit;
                color: inherit;
                text-decoration: none;
            }
        
            button {
                display: flex;
                text-align: left;
                padding: 14px 8px 12px 8px;
                align-items: center;
                color: inherit;
                font-size: inherit;
                border: none;
                background-color: transparent;
                cursor: pointer;
                width: 100%;
                margin-left: 2%;
                //hover

                &:hover{
                    padding: 14px 8px 12px 8px;
                    background-color: #E1E1E133;
                    border-bottom: none;
                }
                span {
                    margin-left: 3px;
                }
            }
        
            .underline{
                font-weight: bold;
                border: solid #7FBE25 3px;
                border-top: none; border-left:none; border-right: none;
                padding: 15px 8px 9px 8px;
            }
        
        }
        .arrow::after {
            content: "";
            display: inline-block;
        }

        
    }
    .menu-dropdown{
        position: absolute;
        top:50px;
        left:0px;
        width: 100%;
        padding: 0% 4%;
        display: flex;
        align-items: center;
        background: #424242 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 10px #00000029;
        z-index: 100;
        opacity: 1;
        
        .menuitem-dropdown{
            padding-left: 1.5%; padding-top: 1.2%;
            z-index: 100;
            .menuitem{
                height: 40px;
                .btn-menuitem{
                    width: 100%;
                    font-size: 14px;
                    font-weight: bold;
                    text-align: left;
		            text-decoration: none;
                    color: #7FBE25;
                    border: solid #7fbe25 1px;
                    border-radius: 0%;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    padding-left: 0px;
                    margin-bottom: 16px;
                }
                .btn-submenuitem{
                    width: 100%;
                    font-size: 13px;
                    font-weight: normal;
                    text-align: left;
		            text-decoration: none;
                    &:hover {
                        color: #7FBE25;
                    }
                }
                .btn-submenuitem .selected{
                    color: #7FBE25;
                }

                .text-submenuitem{
                    width: 100%;
                    font-size: 13px;
                    font-weight: normal;
                    text-align: left;
		            text-decoration: none;
                    color: #F2F2F2;
                     &:hover {
                        color: #7FBE25;
                    }
                }
                .btn-menuitem1{
                    width: 100%;
                    font-size: 14px;
                    font-weight: normal;
                    text-align: left;
		            text-decoration: none;
                    color: #FFFFFF;
                }
                .btn-menuitem1 .selected{
                    color: #7FBE25;
                }
                .text-menuitem1{
                    width: 100%;
                    font-size: 14px;
                    font-weight: normal;
                    text-align: left;
		            text-decoration: none;
                    color: #FFFFFF;
                    &:hover {
                        color: #7FBE25;
                    }
                }
                .text-menuitem1 .selected{
                    color: #7FBE25;
                }
                
            }
        }           
        .vertical-flex {
            display: flex;
            flex-direction: column; 
            flex-wrap: wrap;
            height: 200px; 

            @media (max-width:899px) {
                flex-wrap: nowrap;
                padding-top: 7%; padding-bottom: 10%;
                height: auto; 
            }
        }
    
    }
}