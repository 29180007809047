$max-width: 390px;
$min-width: 900px;

@media screen and (min-width: $min-width) {
    .AppMenu {
        width: 100%;
        opacity: 1;

        .desktop-nav {
            display: flex;
            justify-content: space-between;

            .menus {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                list-style: none;
                padding: 1px 2px;
            }

            .menu-items {
                position: relative;
                font-size: 16px;

                a {
                    display: block;
                    font-size: inherit;
                    color: inherit;
                    text-decoration: none;
                }

                button {
                    display: flex;
                    text-align: left;
                    padding: 32px 10px 15px 12px;
                    align-items: center;
                    color: inherit;
                    font-size: inherit;
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                    width: 100%;

                    &:hover {
                        background-color: #e1e1e133;
                    }

                    span {
                        margin-left: 3px;
                    }
                }

                .underline {
                    font-weight: bold;
                    border: solid #7fbe25 3px;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    padding: 35px 10px 15px 12px;
                }
            }
            .arrow::after {
                content: '';
                display: inline-block;
            }
        }

        .menu-dropdown {
            position: absolute;
            top: 70px;
            left: 0px;
            width: 100%;
            height: auto;
            padding-left: 13%;
            padding-top: 3%;
            padding-bottom: 1%;
            padding-right: 8%;
            background: #f9f9f9 0% 0% no-repeat padding-box;
            box-shadow: 0px 4px 10px #00000029;
            z-index: 99;
            opacity: 1;

            .menuitem-dropdown {
                .menuitem-text {
                    width: 91px;
                    height: 19px;
                    font-size: 16px;
                    text-decoration: none;
                    color: #323232;
                    p {
                        margin-bottom: 16px;
                    }
                }
            }
            .vertical-flex {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;

                @media (max-width: 899px) {
                    flex-wrap: nowrap;
                    padding-top: 7%;
                    padding-bottom: 10%;
                    height: auto;
                }
            }
        }
        .mobile-nav {
            display: none;
        }
    }
}
@media screen and (max-width: 899px) {
    .AppMenu {
        display: none;
    }
}
