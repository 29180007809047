@media screen and (min-width:$min-width){
    .intro-temp{
        width:100%; 
        padding-top:128px;padding-bottom: 240px;
        display: flex;
        justify-content: left;
        align-items: center;

        .intro-layout{
            // width: 556px;
            height: 221px;
            padding-top: 90px; padding-left: 96px;

            .intro-subject{
                text-align: left;
                font-size: 22px; font-weight: bold;
                letter-spacing: 0px;
                margin-bottom: 18px;
                color: #323232;
                opacity: 1;
            }
    
            .intro-content{
                text-align: left;
                font-size: 14px; font-family:  Microsoft JhengHei;
                letter-spacing: 0px;
                color: #323232;
                opacity: 1;
            }
        }

        .intro-img-layout{
            display: flex;
            justify-content: center;
            align-items: center;

        }
    }
}

@media screen and (max-width:$max-width){
    .intro-temp{
        margin-bottom: 240px;

        .intro-layout{
            padding: 0px 40px;

            .intro-subject{
                text-align: left;
                font-size: 20px; font-weight: bold;
                margin-bottom: 20px;
                letter-spacing: 0px;
                color: #323232;
                opacity: 1;
            }

            .intro-content{
                text-align: left;
                font-size: 14px; font-family:  Microsoft JhengHei;
                letter-spacing: 0px;
                color: #323232;
                opacity: 1;
            }
        }

        .intro-img-layout{
            padding: 50px 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            .img-intro{
                width: 100%; 
                height: 100%; 
            }

        }
    }
}