

@media screen and (min-width:$min-width){
    .console {
        padding: 1% 4%; 
        height: auto;
        background-color: #FDFDFD;

        .layout-title{
            font-size: 22px; font-weight: bold;
            letter-spacing: 0px;
            color: #323232;
            margin-bottom: 12px;
            opacity: 1;
        }
        .text-desc{
            font-size:16px;
            font-family: Noto Sans;
        }

        .row {
            margin-bottom: 30px;
        }

        .list-group-item {
            display: flex;
            align-items: center;
            border-top: none; border-left: none; border-right: none;
            height: 60px;

            &:active {
                .text-desc{
                    color: #7FBE25;
                }
            }

            .link_icon{
                width: 50px;
                height: 50px;
            }
        }

        .link-service{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0px;
            text-decoration: none;
            color: #2875E8;
            opacity: 1;
        }
    }

}

@media screen and (max-width:$max-width){
    .console {
        // padding-left: 142px; padding-right: 142px;padding-top: 34px; 
        height: 950px;
        background-color: #FDFDFD;

        .layout-title{
            font-size: 22px; font-weight: bold;
            letter-spacing: 0px;
            color: #323232;
            margin-bottom: 12px;
            opacity: 1;
        }
        .text-desc{
            font-size:16px;
            font-family: Noto Sans;
        }

        .row {
            margin-bottom: 30px;
        }

        .list-group-item {
            display: flex;
            align-items: center;
            border-top: none; border-left: none; border-right: none;
            height: 60px;

            &:active {
                .text-desc{
                    color: #7FBE25;
                }
            }

            .link_icon{
                width: 24px;
                height: 21px;
            }
        }

        .link-service{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0px;
            text-decoration: none;
            color: #2875E8;
            opacity: 1;
        }
    }
}