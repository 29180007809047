$title-text: 18px;
$subTitle-text: 18px;
$desc-text: 18px;

.Subscription {
    .subscription-title-container {
        margin-top: 100px;
        justify-content: center;
        align-items: center;
        .subscription-title {
            justify-content: center;
            align-items: center;
            text-align: center;
            font-weight: bold;
            font-style: normal;
            font-size: 32px;
            line-height: 42px;
            font-family: 'Noto Sans', sans-serif;
            letter-spacing: 0px;
            color: #323232;
            opacity: 1;
        }
    }
}
