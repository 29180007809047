
.AppMenuDropdown {
    .layout-title{
        margin-bottom: 7px;
        opacity: 1;
    }

    .text-title{
        text-align: left;
        font-weight: bold;
        letter-spacing:  0px;
        text-decoration: none;
        color: #323232;
        opacity: 1;
    }
    .text-desc{
        text-align: left;
        margin-bottom: 41px;
        letter-spacing: 0px;
        color: #5F5F5F;
        opacity: 1;

    }
}

