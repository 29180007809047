.ProcessManagement {
  width: 100%;
  
  .style-row {
    display: flex;
    padding: 25px 15px;
    justify-content: flex-end; /* 靠右對齊 */
    align-items: center; /* 垂直居中 */
  }

  .link-area {
    padding: 0 0 20px 0;
    .text-link {
      color: #1d1d1d;
      font-size: 20px;
      font-weight: bold;
      font-family: 'Source Sans Pro', sans-serif;
      text-decoration: none;
    }
  }
}

.ProcessManagement.Modal {
  .modal-header {
    .modal-title {
      font-size: 14px;
      color: #323232;
      font-weight: bold;
    }
  }

  .form {
    padding: 0 0 0 0;
    .spin {
      .modal-body {
        padding: 0 20px 20px 20px;
        margin-bottom: 5%;
        .form-control.custom-select {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'%3E%3Cpath fill='%236c757d' d='M5 6L0 0h10L5 6z'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right 0.75rem center;
          background-size: 0.65rem 0.65rem;
          padding-right: 1.5rem;
        }

        .text-danger.input-field {
          font-size: 12px !important;
          padding: 0;
          margin: 0;
        }

        .form-select {
          width: 50%;
          font-size: 10px;
          color: #323232;
          border-radius: 10%;
        }

        .form-control {
          font-size: 13px;
        }
        .text-danger{
          font-size: 13px;
        }
      }
    }

    .modal-footer {
      padding: 2% 4% 3% 4%;
      .btn {
        width: 90px !important;
        height: 36px !important;
        text-align: center !important;
        font-size: 13px;
      }
      .btn-secondary {
        margin-right: 10px;
      }
    }
  }
}