.SubscriptionPlan{
    width: 290px;
    height: 480px;

    border-color: #0FC0C0;
    border-radius: 10px;
    margin-bottom: 20px;

    .card-header{
        color: #0FC0C0;
        background: none;
        font-size: 14px;
        font-family: 'Source Sans 3', sans-serif;
        font-weight: bold;
        border: none;
        background-color: none;
        height: 60px;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .card-body{
        .card-title{
            height: 70px;
            font-size: 30px; 
            font-weight: bold;
            font-family: 'Source Sans 3', sans-serif;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            color: #323232;
            opacity: 1;
        }

        .card-text{
            padding: 5% 7%;
            color: #5F5F5F;
            font-size: 14px;
            font-weight: bold;
            font-family: 'Microsoft JhengHei';
            text-align: left;

            .text-row{
                height: 28px;
            }

            .disable{
                color: #D2D2D2;
            }
        }

        .card-button{
            width: 120px;
            height: 44px;
            margin-top: 10px;
        }
    }

}