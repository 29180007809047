$title-text: 18px;
$subTitle-text: 18px;
$desc-text: 18px;

.CloudSecurity {
    height: auto;

    .banner-sec {
        width: 100vw;
        height: 260px;
        opacity: 90%;

        display: flex;
        align-items: center; /*垂直置中*/
        opacity: 1;
        .banner-row-sec {
            padding-left:100px;
            padding-top:100px;
            display: flex;
            flex-direction: column;
            .banner-subtitle-sec {
                display: flex;
                color: #95e8ff;
                // padding: 10% 2% 0% 7%;
            }
            .banner-title-sec {
                display: flex;
                font-size: 30px;
                text-align: left;
                font-weight: bold;
                // padding-left: 92px !important;
                // padding-top: 108px !important;
                padding-bottom: 108px !important;

                letter-spacing: 0px;
                white-space: nowrap;
            }
        }
    }

    .main-intro {
        height: auto;
        padding-top: 8%;
        padding-bottom: 3%;
        background: transparent linear-gradient(to right, #e0edf5, #ffffff, #e4f2f2);
        font-family: 'Microsoft JhengHei', sans-serif;

        .text-intro {
            padding-left: 6%;
            padding-top: 1%;

            b {
                font-size: 2.2rem;
            }

            p {
                font-size: 15px;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .btn-secondary {
                width: 120px;
                height: 44px;
            }
        }
    }

    .security-posture {
        padding: 2% 6% 3% 6%;
        margin: 3% 0%;
        text-align: center;
        align-items: center;
        .img-security-posture {
            padding: 5% 0% 4% 0%;
        }
        .text-security-posture {
            padding-left: 4%;
            .text-title {
                margin-left: 5%;
                font-size: 17px;
                font-weight: bold;
                text-align: left;
            }

            .text-desc {
                margin-left: 5%;
                font-size: 16px;
                text-align: left;
                font-family: 'Microsoft JhengHei', sans-serif;
                color: #323232;
            }

            li {
                list-style-type: none;
                margin-left: 5%;
                p {
                    font-weight: bold;
                    text-align: left;
                    font-size: 16px;
                    font-family: 'Microsoft JhengHei', sans-serif;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .cspm_main_function {
        display: flex;

        align-items: center;
        justify-content: center;
        margin-top: 3%;
        margin-bottom: 4vh;
        .text-title {
            text-align: center;
            margin-top: 5vh;
            margin-bottom: 5vh;
        }
        .col-swiper {
            padding: 1% 7%;
            .swiper-container {
                padding-bottom: 3%;
                ::part(pagination) {
                    display: None !important;
                }
            }
        }
        .nav-pag {
            .btn-primary {
                background-color: rgba(255, 255, 255, 0) !important;
                border-color: rgba(255, 255, 255, 0) !important;
            }

            .btn-primary:hover {
                background-color: rgba(255, 255, 255, 0) !important;
                border-color: rgba(255, 255, 255, 0) !important;
            }
            display: flex;
            justify-content: center;
            .button_next {
                width: fit-content;
                height: fit-content;
                .button-next {
                    background-image: url('../../public/assets/images/btn_next_g_carousels_n.svg');
                    background-size: 100%;
                    background-repeat: no-repeat;
                    width: 30px;
                    height: 40px;
                }

                .button-next:disabled {
                    background-image: url('../../public/assets/images/btn_next_w_carousels_n.svg');
                }
            }
            .button_prev {
                width: fit-content;
                height: fit-content;
                .button-prev {
                    background-image: url('../../public/assets/images/btn_back_g_carousels_n.svg');
                    background-size: 100%;
                    background-repeat: no-repeat;
                    width: 30px;
                    height: 40px;
                }
                .button-prev:disabled {
                    background-image: url('../../public/assets/images/btn_back_w_carousels_n.svg');
                }
            }
            .pags {
                padding-top: 1%;
                width: fit-content;
                height: fit-content;
                .pagination {
                    width: fit-content !important;
                    height: 5px !important;
                    .page-item {
                        width: 20px !important;
                        height: 5px !important;
                    }
                    .page-link {
                        width: 20px !important;
                        height: 5px !important;
                        border-radius: 0% !important;
                        border: None !important;
                        padding-bottom: 0% !important;
                        background-color: #e9e9e9 !important;
                    }
                    .page-link.active,
                    .active > .page-link {
                        background-color: #7fbe25 !important;
                        /* Your styles for the active page-link inside an element with the class 'active' */
                    }
                }
            }
        }
    }

    .regulatory-compliance {
        text-align: center;
        padding: 2% 6% 3% 6%;
        align-items: center;
        .text-regulatory-compliance {
            .text-desc {
                margin-right: 0%;
                width: 486px;
                font-size: 16px;
                text-align: left;
                font-family: Microsoft JhengHei;
                color: #323232;
            }

            li {
                list-style-type: none;
                margin-right: 0%;

                p {
                    text-align: left;
                    font-size: 14px;
                    font-family: 'Microsoft JhengHei', sans-serif;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }

            .btn-allservice {
                font-size: 20px;
                font-weight: bold;
                text-decoration: none;
            }
        }
    }

    .security-recommendation {
        padding: 2% 6% 5% 6%;
        background-color: #f5f9fc;
        text-align: center;
        align-items: center;
        .text-security-recommendation {
            .text-title {
                margin-left: 5%;
                font-size: 16px;
                font-weight: bold;
                text-align: left;
            }

            .text-desc {
                margin-left: 5%;
                font-size: 16px;
                text-align: left;
                font-family: Microsoft JhengHei;
                color: #323232;
            }
        }
    }

    .cloud-soc-rule {
        padding: 2% 6% 3% 6%;
        text-align: center;
        align-items: center;
        margin: 2% 0%;
        .text-cloud-soc-rule {
            .text-desc {
                margin-left: 5%;
                font-size: 16px;
                text-align: left;
                font-family: Microsoft JhengHei;
                color: #323232;
            }

            .btn-allservice {
                font-size: 20px;
                font-weight: bold;
                text-decoration: none;
            }
        }
    }

    .subscr-plan {
        background: #f5f9fc;
        padding-bottom: 10%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;

        .text-title {
            margin-top: 3%;
            margin-bottom: 3%;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
        }
    }
}
