$max-width: 390px;
$min-width: 900px;

$right-accordion-button-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;
$left-accordion-button-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
$accordion-icon-transform1:   rotate(-180deg) !default;



.AppLoginHeader {
    background: #323232;
    height: 50px;
    min-height: 10px;
    .nav-area {
        display: flex;
        align-items: center;
        width: 100vw;
        height: 50px;
    }
    .setting-area {
        display: flex; 
        justify-content: flex-end; 
        align-items: center;
        width: 20vw;
        height: 50px;

        .icon-setting {
            &:hover {
                color: #7FBE25;
                cursor: pointer;
            }
            &:active {
                color: #6AA318; 
            }
        }
        .icon-acct{
            &:hover {
                cursor: pointer;
            }
        }
    }
    
}









