.SecurityScore{

    .py-2{
        height: 160px;

        .text-title{
            display: flex;
            justify-content: center;
            font-size: 15px;
            font-weight: bold;
            color: #5F5F5F;
            margin-bottom: 15px;
        }
        
    }
    



}