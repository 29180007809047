
.Login {
  height: auto;
  background: #FAFAFA;
  border: 1px solid #E2E2E2;
  padding: 3% 0% 10% 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Noto Sans', sans-serif;
  opacity: 1;

  .Card {
    width: 440px;
    padding: 0 2%;
    border-radius: 0%;
    box-shadow: 0px 3px 6px #00000029;

    .text-tab{
      font-size: 18px;
      width: 10vw;
      
      &.active{
        font-weight: bold;
        color: #323232;
      }
    }

    .btn{
      width: 100%;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      text-align: center;
      font-size: 16px;
      margin-top: 7vh;
    }

    .input-field {
      font-size: 14px;
      ::placeholder{
        color: #323232;
        font-weight: bold;
      }
    }

    .checkbox,
    .forget-password{
      font-size: 12px;
      color: #707070;
    }


    .spin{
      width: 100%;
    
    }

    .form-login {
      background-color: #FFFFFF;
      z-index: 55; 
      margin-top: 36px;margin-bottom: 10vh;
      width: 100%;
      height: auto;

        .other-login {
          border: 2px solid #5178E7;
          color: #5178E7;
          background: none;
          margin-top: 2vh;
        }
            
    }
      

    .form-register {
      background-color: #FFFFFF;
      z-index: 55; //讓adv-bg在z軸的最上方
      margin-top: 36px;
      margin-bottom: 10vh;
      width: 100%;
      height: auto;
    }
  }
}
