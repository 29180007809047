.language-selector {
    select {
        background: #323232;
        color: #f9f9f9;
        padding: 5px 10px;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        opacity: 1;
        width: 232px;
        cursor: pointer;

        &:hover {
            border-color: #7fbe25;
        }

        &:focus {
            border-color: #7fbe25;
        }

        option {
            padding: 5px 10px;
        }
    }
}
