.DailyCost {
.input{
    .pick_date .react-datepicker-wrapper {
            display: inline-block;
            position: relative;
            width: 100%;
        }
    
        .pick_date .react-datepicker__input-container {
            position: relative;
        }
    
        .pick_date .react-datepicker__input-container input {
            width: 100%;
            /* Adjust the width to accommodate the button */
            height: 37px;
            padding: 0.375rem 2.25rem 0.375rem 0.75rem;
            border-radius: 0.375rem;
            border: 1.5px solid #dee2e6;
            font-size: 1rem;
            /* Adjust font size if needed */
            line-height: 1.5;
            /* Adjust line height if needed */
            color: #495057;
            /* Adjust text color if needed */
            background-color: #fff;
            /* Adjust background color if needed */
            background-clip: padding-box;
            /* Bootstrap default transition */
        }
    .form-select{
            width: 100%;
                /* Adjust the width to accommodate the button */
                height: 37px;
                padding: 0.375rem 2.25rem 0.375rem 0.75rem;
                border-radius: 0.375rem;
                border: 1.5px solid #dee2e6;
                font-size: 1rem;
                /* Adjust font size if needed */
                line-height: 1.5;
                /* Adjust line height if needed */
                color: #495057;
                /* Adjust text color if needed */
                background-color: #fff;
                /* Adjust background color if needed */
                background-clip: padding-box;
    }
}
}