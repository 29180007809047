.EmployeeSchedule{
    .buttons{
            .btn{
                background-color: #FFFFFF !important;
                border:1px solid #BDBDBD;
                font-size:12px;
                height: 30px;
                border-radius: 0%;
            }
    }
    .TimeLine{
        .react-calendar-timeline{
            .rct-header-root{
                background: #7fbe25 !important;
                border:2px #E2E2E2 solid;
                border-bottom: 0px;
            }
            .rct-outer{
border: 2px #E2E2E2 solid;
    border-top: 0px;
            }
        }
    }
}