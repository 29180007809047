.Smartmanagement {
    height: auto;

    .main-intro {
        height: auto;
        padding-top: 8%;
        padding-bottom: 4%;
        background: transparent linear-gradient(to right, #e0edf5, #ffffff, #e4f2f2);

        .text-intro {
            padding-left: 6%;
            padding-top: 1%;
            b {
                font-size: 2.2rem;
            }

            p {
                font-size: 15px;
                font-family: 'Microsoft JhengHei', sans-serif;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .btn-secondary {
                width: 120px;
                height: 44px;
            }
        }
    }
    .func-text {
        font-size: 13px;
        font-weight: bold;
        color: #003999;
    }

    .cost-analysis {
        padding: 4% 2% 6% 2%;

        .text-cost {
            margin-top: 5%;
            .text-title {
                font-size: 24px;
                font-weight: bold;
            }

            .text-desc {
                font-size: 16px;
                font-family: Microsoft JhengHei;
                color: #5f5f5f;
            }

            li {
                list-style-type: none;
                p {
                    font-size: 16px;
                    font-family: Microsoft JhengHei;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .smart-recommendation {
        padding: 4% 5% 8% 5%;

        .text-cost {
            margin-top: 2%;
            .text-title {
                font-size: 24px;
                font-weight: bold;
            }

            .text-desc {
                margin-top: 15px;
                font-size: 16px;
                font-family: Microsoft JhengHei;
                color: #5f5f5f;
                padding-right: 6%;
            }
        }
    }

    .subscr-plan {
        background: #f5f9fc;
        padding-bottom: 10%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;

        .text-title {
            margin-top: 3%;
            margin-bottom: 3%;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
        }
    }
}
