$max-width: 899px;
$min-width: 900px;

.CloudGodaQA {
    font-family: Noto Sans;
    min-height: 2000px;
    margin-bottom: 10%;
    height: auto;

    .banner {
        width: 100%;
        height: 260px;
        opacity: 90%;
        border: 1px solid #f0f0f0;
        text-align: left;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0px;
        display: flex;
        align-items: center; /*垂直置中*/
        padding: 10% 2% 9% 7%;
        opacity: 1;
    }

    .sidebar {
        width: 198px;
        height: 488px;
        // position: fixed;
        // top: 448px;
        // left: 30px;
        margin-top: 30px;
        margin-left: 30px;
        padding-top: 24px;
        background: #f0f0f0 0% 0% no-repeat padding-box;
        z-index: 0;
        opacity: 1;

        .title-sidebar {
            display: none;
        }
        .text-sidebar:hover {
            color: #7fbe25;
        }
    }

    .text-sidebar {
        font-size: 18px;
        font-weight: 500;
        text-decoration: none;
        letter-spacing: 0px;
        color: #323232;
        opacity: 1;
    }
    .text-sidebar:hover {
        color: #7fbe25;
    }

    .layout-sidebar {
        width: 198px;
        height: 50px;
        padding-left: 27px;
        padding-top: 13px;
        text-align: left;
        opacity: 1;
    }

    .text-title {
        height: 94px;
        display: flex;
        align-items: center; /*垂直置中*/
        text-align: left;
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #1d1d1d;
        opacity: 1;
    }
    .text-title-qa {
        height: 94px;
        display: flex;
        align-items: center;

        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 40px;
        font-family: 'Microsoft JhengHei UI', sans-serif;
        letter-spacing: 0px;
        color: #7fbe25;
        opacity: 1;
    }

    .desc-layout {
        height: auto;
        opacity: 1;
    }

    .accordion {
        .accordion-item {
            border-top: 0px !important;
            border-right: 0px !important;
            border-left: 0px !important;
            border-bottom: 100% solid #dddddd !important;
        }
        .nav-item {
            border: 0px !important;
            width: 100%;
        }
        .accordion-header {
            .accordion-button[aria-expanded='true'] {
                border-bottom: 0px !important;
                border-radius: 0% !important;
                border-left: 10px solid #7fbe25 !important;
                font-weight: bold !important;
            }
            .accordion-button {
                font-size: 18px !important;
                // font-weight: bold !important;
                border-bottom: white !important;
            }
        }
        .accordion-body {
            font-size: 16px;
            font-family: Microsoft JhengHei;
            border-left: 10px solid #7fbe25 !important;
        }
    }

    .text-subtitle {
        text-decoration: underline;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #7fbe25;
        opacity: 1;
    }
    .col-item {
        height: 136px;
        padding-top: 39px;
    }

    .text-desc {
        font-size: 14px;
        letter-spacing: 0px;
        margin-top: 12px;
        color: #5f5f5f;
        opacity: 1;
    }
}

.AllService.login {
    font-family: Noto Sans;
    height: 2200px;

    .banner {
        display: none;
    }

    .sidebar {
        height: 100%;
        margin-top: 8px;
        margin-left: 0px;
        border: 2px solid #d2d2d2;
        border-bottom: none;
        border-left: none;
        border-top: none;
        background: #ffffff 0% 0% no-repeat padding-box;
        padding-top: 0px;
        z-index: 0;
        opacity: 1;

        .title-sidebar {
            display: flex;
            width: 100%;
            height: 66px;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #323232;
            opacity: 1;
        }

        .text-sidebar {
            font-size: 16px;
            font-weight: 500;
            text-decoration: none;
            letter-spacing: 0px;
            color: #323232;
            opacity: 1;
        }

        .layout-sidebar {
            width: 224px;
            height: 40px;
            padding-left: 27px;
            padding-top: 13px;
            text-align: left;
            opacity: 1;
        }
    }

    .text-title {
        height: 94px;
        display: flex;
        align-items: center; /*垂直置中*/
        text-align: left;
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #1d1d1d;
        opacity: 1;
    }

    .desc-layout {
        height: auto;
        opacity: 1;
    }

    .text-subtitle {
        text-decoration: underline;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #7fbe25;
        opacity: 1;
    }
    .col-item {
        height: 136px;
        padding-top: 39px;
    }

    .text-desc {
        font-size: 14px;
        letter-spacing: 0px;
        margin-top: 12px;
        color: #5f5f5f;
        opacity: 1;
    }
}
