.CompanyWorkingDay.AddModal{
    .modal-header {
      .modal-title {
        font-size: 14px;
        color: #323232;
        font-weight: bold;
      }
    }
    .modal-body {
      padding: 0px 24px 10% 24px;
      font-size: 13px;

      .form-control {
        font-size: 13px;
      }

      .MuiFormControlLabel-label{
        font-size: 13px;
      }


    }
    .modal-footer {
      padding: 2% 4% 3% 4%;
      .btn {
        width: 90px !important;
        height: 36px !important;
        text-align: center !important;
        font-size: 13px;
      }
      .btn-secondary {
        margin-right: 10px;
      }
    }
  

}